import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/AddEditModel.scss";
import "../../../styles/CommonStyle.scss";
import { baseURL } from "../../BaseUrl";
import Multiselect from "multiselect-react-dropdown";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import * as swal from "../../Consturl/SwalAlert";
import { FloatingLabel } from "react-bootstrap";
import { travelizeAuth } from "../../HeaderAuthentication";
import { useSelector, useDispatch } from "react-redux";
import {
  loadUserDropdown,
  loadAllUserDetails,
  loadDepartmentDrop,
} from "../../../redux/action";
import { Label } from "recharts";

export default function AddReportingTo(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const subscriptionID = loginDetails.subscriptionId;

  let dispatch = useDispatch();
  const { allUserDetails } = useSelector((state) => state.data);
  const { departmentDrop } = useSelector((state) => state.data);

  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [designationsSingleSelect, setDesignationsSingleSelect] = useState([]);
  const [designation, setDesignation] = useState("");
  const [userDesignations, setUserDesignations] = useState([]);
  const [userDesignationIDs, setUserDesignationIDs] = useState(null);
  const [departMentID, setDepartMentID] = useState(null);
  const [userNames, setUserNames] = useState([]);
  const [userIDs, setUserIDs] = useState([]);
  const [userDesigLevel, setUserDesigLevel] = useState("");
  const [userListByDesigLevel, setUserListByDesigLevel] = useState([]);
  const [multipleUserList, setMultipleUserList] = useState([]);

  const [designationLevels, setDesignationLevels] = useState([
    {
      level: "L1",
      desigName: "",
      desigID: "",
      name: "appL2UserID",
    },
  ]);

  const [postData, setPostData] = useState({
    claimAprHierarchyID: 0,
    subscriptionID: subscriptionID,
    status: 1,
    appL1UserID: "USR001",
    appL2UserID: "",
    appL3UserID: "",
    appL4UserID: "",
    appL5UserID: "",
    appL6UserID: "",
    usersIds: [],
  });

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  useLayoutEffect(() => {
    let multiSelect = true;
    dispatch(loadUserDropdown(multiSelect));
    dispatch(loadAllUserDetails());
    dispatch(loadDepartmentDrop());
    fetchDropdownsData();
  }, []);

  useEffect(() => {
    if (userDesignationIDs) {
      let usersByDesignation = allUserDetails.filter(
        (data) =>
          data.desingation.designationID == userDesignationIDs.toString()
      );

      let userDesigArray = [];
      userDesigArray.push(
        usersByDesignation.map((data) => {
          return {
            label: data?.fullName,
            value: data.userId,
            departmentName: data.desingation.departmentName,
            departmentID: data.desingation.departmentID,
            designationName: data.desingation.designationName,
            designationID: data.desingation.designationID,
            designationLevel: data.desingation.designationLevel,
          };
        })
      );
      setMultipleUserList(userDesigArray.flat());
    }
  }, [userDesignationIDs]);

  useEffect(() => {
    if (multipleUserList.length !== 0) {
      handleMultiSelect({
        label: "Select All",
        value: -1,
      });
    }
  }, [userDesignationIDs, multipleUserList]);

  useEffect(() => {
    filterByDesignationLevel();
  }, [userDesigLevel]);

  const filterByDesignationLevel = () => {
    const designationByUserLevel = allUserDetails.filter(
      (data) => data.desingation.designationLevel < userDesigLevel
    );

    setDesignationsSingleSelect(
      designationByUserLevel.map((data) => {
        return {
          value: data.desingation.designationID,
          label: data.desingation.designationName,
        };
      })
    );

    setUserListByDesigLevel(
      designationByUserLevel.map((data) => {
        return {
          value: data.userId,
          label: data.fullName,
          designID: data.desingation.designationID,
        };
      })
    );
  };

  const fetchDropdownsData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(`${baseURL}/api/Designation/BindDesignationDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDesignationList(
            res.result.map((data) => {
              return { value: data.designationID, label: data.designationName };
            })
          );
        } else {
          setDesignationList([]);
        }
      });
  };

  const handleMultiSelect = (selectedList) => {
    // Check if "Select All" is selected
    if (selectedList && selectedList.value == -1) {
      setUserNames(multipleUserList);
      setUserIDs(multipleUserList.map((d) => d.value));
      setPostData({
        ...postData,
        ["usersIds"]: multipleUserList.map((d) => d.value),
      });
      setUserDesigLevel(
        [...new Set(multipleUserList.map((d) => d.designationLevel))].toString()
      );
    } else {
      setUserNames(selectedList);
      setUserIDs(selectedList.map((d) => d.value));
      setPostData({
        ...postData,
        ["usersIds"]: selectedList.map((d) => d.value),
      });
      setUserDesigLevel(
        [...new Set(selectedList.map((d) => d.designationLevel))].toString()
      );
    }
  };

  // console.log("UserIDs:", userIDs);

  // ADD LELVEL DESIGNATION AND SET VALUE
  const addNextLevelsDesignations = (e) => {
    e.preventDefault();
    let selectCount = designationLevels.length + 1;
    setDesignationLevels([
      ...designationLevels,
      {
        level: `L${selectCount}`,
        desigName: "",
        desigID: "",
        name: `appL${selectCount + 1}UserID`,
      },
    ]);
  };

  const setReportingDesignations = (value, name, desigLevel) => {
    let fiterObj = designationLevels.filter((item) => item.level == desigLevel);
    fiterObj[0].desigID = value;
    fiterObj[0].desigName = name;
    fiterObj[0].level = desigLevel;
    setDesignationLevels([...designationLevels]);
  };

  // console.log("DesignationLevels:", designationLevels);

  const removeLevelsDesignations = (index) => {
    let finalDesignationLevels = designationLevels.filter(
      (data, i) => i != index
    );
    setDesignationLevels(finalDesignationLevels);
  };

  const setReportingUserName = (value, name) => {
    setPostData({ ...postData, [name]: value });

    // console.log("REPORT NAME:", name, value);
  };

  console.log("POST-DATA::", postData);

  const addReportingTo = (e) => {
    e.preventDefault();
    if (postData.subscriptionID !== "" && postData.appL2UserID) {
      console.log("POSTING DATA JSON:", postData);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(postData),
      };
      fetch(
        `${baseURL}/api/ClaimAprHierarchy/BulkAddClaimAprHierarchy`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchDesignations();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            console.log("ERROR:", res.errorMessage);
            // swal.showAlert("Fail", `${res.errorMessage}`, "warning");
          }
        })
        .catch((error) => {
          console.log("ERROR:", error);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required!",
      });
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-model reporting-hierarchy-modal"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">
            {props.claimApprHierarchyID
              ? "EDIT APPROVAL LEVELS"
              : "ADD APPROVAL LEVELS"}
          </h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form onSubmit={addReportingTo}>
          <div className="container add-edit-reporting">
            <div className="product-inputs">
              <div className="mb-3">
                <FloatingLabel
                  label="Select Department"
                  className={`${
                    departMentID !== null
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    options={departmentDrop}
                    placeholder="Select Department"
                    value={departmentDrop.value}
                    onInputChange={departmentDrop.label}
                    onChange={(data) => setDepartMentID(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
                {fieldReq && departMentID == null ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {alertMessage.message}
                  </small>
                ) : (
                  ""
                )}
              </div>
              <div className="mb-3">
                <FloatingLabel
                  label="Select Designations"
                  className={`${
                    userDesignationIDs !== null
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    options={designationList}
                    placeholder="Select Designations"
                    value={designationList.value}
                    onInputChange={designationList.label}
                    onChange={(data) => setUserDesignationIDs(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
                {fieldReq && userDesignationIDs == null ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {alertMessage.message}
                  </small>
                ) : (
                  ""
                )}
              </div>
              <div className="mb-3">
                <FloatingLabel
                  label="Users"
                  className={`${
                    userNames.length !== 0
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <MultiSelect
                    className="select-add-user-model"
                    options={multipleUserList}
                    value={userNames}
                    onChange={handleMultiSelect}
                    labelledBy={"Select"}
                  />
                </FloatingLabel>
                {fieldReq && userNames.length <= 0 ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {alertMessage.message}
                  </small>
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3">
                <div className="hv-center">
                  <div className="p-2">
                    <b>Default Approver : </b>
                  </div>
                  <div className="primary-color p-2">
                    <b>Reporting Manager</b>
                  </div>
                </div>
              </div>

              <div className="add-approval-levels-part">
                {designationLevels.map((desig, i) => (
                  <div className="dflex-j-between mb-3" key={i}>
                    <FloatingLabel
                      label={`${desig.level} - Approval`}
                      className={`${
                        desig.desigID !== ""
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                      style={{ width: "100%", paddingRight: "10px" }}
                    >
                      <Select
                        id={desig.level}
                        name={desig.name}
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        options={designationsSingleSelect}
                        placeholder="Approval Designation"
                        value={designationsSingleSelect.value}
                        onInputChange={designationsSingleSelect.label}
                        onChange={(data) =>
                          setReportingDesignations(
                            data.value,
                            data.label,
                            desig.level
                          )
                        }
                        isSearchable={true}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      label={`${desig.level} - Approval User Name`}
                      className={`${
                        desig.desigName !== ""
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                      style={{ width: "100%", paddingRight: "10px" }}
                    >
                      <Select
                        id={desig.level}
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        options={userListByDesigLevel.filter(
                          (item) => item.designID == desig.desigID
                        )}
                        placeholder="User Name"
                        value={userListByDesigLevel.value}
                        onInputChange={userListByDesigLevel.label}
                        onChange={(data) =>
                          setReportingUserName(data.value, desig.name)
                        }
                        isSearchable={true}
                      />
                    </FloatingLabel>
                    {designationLevels.length == i + 1 ? (
                      <div
                        className="remove-icon"
                        onClick={() => removeLevelsDesignations(i)}
                      >
                        <i className="fa fa-times" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
              {designationLevels.length < 5 ? (
                <div className="mt-1">
                  <button
                    className="btn add-select-dropdown"
                    onClick={(e) => addNextLevelsDesignations(e)}
                  >
                    <i className="fa fa-plus" /> Add
                  </button>
                </div>
              ) : (
                ""
              )}
              {/* <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => setEnableStatus(1)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => setEnableStatus(0)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div> */}
            </div>
          </div>
          <div className="btns-save-cancel">
            <SBSaveUpdateBtn
              // onClickEffect={addReportingTo}
              type="submit"
              btnName="Save"
            />
            <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
          </div>
        </form>
      </Modal>
    </div>
  );
}
