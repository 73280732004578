import React, { useEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as HeaderData from "../../HeaderAuthentication";
import { postClientStage } from "../../../redux/action";

function AddClientStage(props) {
  let dispatch = useDispatch();
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [stageData, setStageData] = useState({
    subscriptionID: "",
    name: "",
    description: "",
    addedByID: "",
    addedOn: "",
    addedByUserFullName: "",
    status: 1,
  });

  const {
    subscriptionID,
    name,
    description,
    addedByID,
    addedOn,
    addedByUserFullName,
    status,
  } = stageData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStageData({ ...stageData, [name]: value });
  };

  useEffect(() => {
    if (resPassData) {
      setStageData({
        subscriptionID: resPassData.subscriptionId,
        name: "",
        description: "",
        addedByID: resPassData.userId,
        addedOn: "",
        addedByUserFullName: resPassData.fullName,
        status: 1,
      });
    }
  }, []);

  const cancelModale = () => {
    props.categoryModuleClose();
  };

  const [statusValue, setStatusValue] = useState(true);
  const statusFlag = (statusValue) => {
    setStatusValue(!statusValue);
    if (!statusValue === true) {
      setStageData({ ...stageData, status: 1 });
    } else if (!statusValue === false) {
      setStageData({ ...stageData, status: 0 });
    }
  };

  const [fieldReq, setFieldReq] = useState(false);
  const saveStage = (e) => {
    e.preventDefault();
    if (!name || !description) {
      setFieldReq(true);
    } else {
      e.preventDefault();
      var postRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(stageData),
      };
      dispatch(postClientStage(postRequest, cancelModale));
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        // onHide={props.categoryModuleClose}
        className="main_model add-edit-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">Add Stage</h6>
          <i className="fa fa-times" onClick={props.categoryModuleClose} />
        </div>

        <form>
          <div className="container add-edit">
            <div className="product-inputs mt-2">
              {/* <div className='form-field'>
                            <Form.Group className="mb-3_health" controlId="exampleForm.Health">
                                <Form.Label className="require">Subscription Id</Form.Label>
                                <Form.Control type="text" pattern="/^[0-9]{10}$/"
                                    value={subscriptionID || ""}
                                    name="subscriptionID"
                                    onChange={handleInputChange}
                                    placeholder="Select Subscription"
                                    autoComplete="off"
                                    readOnly />
                            </Form.Group>
                        </div> */}
              <div className="form-field mb-2">
                <FloatingLabel
                  label="Enter Stage Name"
                  className={`${
                    name || ""
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    type="text"
                    pattern="/^[0-9]{10}$/"
                    value={name || ""}
                    name="name"
                    onChange={handleInputChange}
                    placeholder="Enter Stage Name"
                    autoComplete="off"
                  />
                </FloatingLabel>
                {fieldReq && !name && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter Stage Name
                  </small>
                )}
              </div>
              <div className="form-field mb-2">
                <Form.Group
                  className="mb-3_health"
                  controlId="exampleForm.Health"
                >
                  <FloatingLabel
                    label="Description"
                    className={`${
                      description || ""
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                  >
                    <Form.Control
                      as="textarea"
                      type="text"
                      pattern="/^[0-9]{10}$/"
                      value={description || ""}
                      name="description"
                      onChange={handleInputChange}
                      placeholder="Description"
                      className="form-control form form-textarea add-spare-input"
                      autoComplete="off"
                    />
                  </FloatingLabel>
                  {fieldReq && !description && (
                    <small id={`Error`} className="form-text text-muted ">
                      Please Enter description
                    </small>
                  )}
                </Form.Group>
              </div>
              <div className="mb-3">
                <div className="form-field form-status-section">
                  <div>
                    <Form.Label className="require">Status</Form.Label>
                  </div>
                  <div className="btn-status">
                    <Form.Group className="facility-details">
                      <div
                        className={`checkbox ${statusValue && "checkbox--on"}`}
                        name="status"
                        onClick={(e) => statusFlag(!statusValue)}
                      >
                        <div className="checkbox__ball">
                          <span
                            className={
                              statusValue ? "enable-text" : "disable-text"
                            }
                          >
                            {statusValue ? "Enable" : "Disable"}
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={saveStage} btnName="Save" />
          <SbCancelBtn btnName="Cancel" onClickEffect={cancelModale} />
        </div>
      </Modal>
    </div>
  );
}

export default AddClientStage;
