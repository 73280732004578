import React, { useState } from "react";
import "../../src/styles/SignIn.scss";
import travelizeSalesBeeLogo from "../images/MatricLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import travelize from "../images/metric-favicon.svg";
import { Form, Button, Col, Image, Row, Carousel } from "react-bootstrap";
import { baseURL } from "./BaseUrl";
import * as swal from "../Components/Consturl/SwalAlert";
import { toast } from "react-toastify";
import clientLogo from "../images/Client-Logo.svg";
import SignInGifImag from "../SignInGifImag";

const UpdatedForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  let themeColor = localStorage.getItem("theme");

  let clientLoginUrl = window.location.hash;

  let navigate = useNavigate();

  const forgotpassword = (e) => {
    const usernameDta = {
      UserName: email,
    };

    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(usernameDta),
    };
    fetch(`${baseURL}/api/Authentication/ForgotPassword`, requestoption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          //   setResetPasswordId(res.result);
          swal.showAlert("Success", `${res.message}`, "success");
          const forgotPassData = { email: email, id: res.result };
          sessionStorage.setItem(
            "forgotPassData",
            JSON.stringify(forgotPassData)
          );
          setTimeout(() => navigate("/"), 1000);
        } else {
          toast.error(res.errorMessage);
        }
      });
    e.preventDefault();
  };
  return (
    <div className="container-fluid">
      <div className="sigin-page-body">
        {/* <Row> */}
        <div className="dflex-flow-row">
          <Col lg={5} md={5} sm={12} className="signIn-container-left-div">
            <SignInGifImag />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <div className="devider">
              <div className="v1"></div>
            </div>
          </Col>
          <Col lg={5} md={5} sm={12} className="signIn-container-right-div">
            <div className="metricinfo-logo">
              <Image
                src={travelize}
                alt="MetricInfo Logo"
                className="sb-trv-logo"
              />
            </div>
            <div className="signin-header forgotpass-part">
              <div className="forgotpass-header-part">
                {clientLoginUrl === "#/barixforgotpassword" && (
                  <div className="whitle-labeling-logo">
                    <Image
                      src={clientLogo}
                      alt="MetricInfo"
                      className="sb-login-logo"
                      style={{
                        width: "180px",
                        height: "70px",
                        marginBottom: "1rem",
                      }}
                    />
                  </div>
                )}
                <h2 className="mb-1">Forgot Password</h2>
                <p>
                  Enter your email and we'll send you a link to reset your
                  password.
                </p>
              </div>
              <Form onSubmit={forgotpassword} className="forgotpass-form-data">
                <div className="form-group">
                  <div className="icon-with-input before">
                    <i className="fa fa-envelope" />
                    <input
                      type="email"
                      className="form-control signIn-form-control"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="signin-button dflex-j-between mt5">
                  <div className="back-to-signin">
                    <Link
                      to={
                        clientLoginUrl === "#/barixforgotpassword"
                          ? "/barrix"
                          : "/"
                      }
                      className="color-yellow"
                    >
                      Back to Sign In
                    </Link>
                  </div>
                  <Button type="submit" className="btn btn-signin-button">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </div>
        {/* </Row> */}
      </div>
    </div>
  );
};

export default UpdatedForgotPasswordPage;
