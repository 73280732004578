import React, { useRef, useState, useEffect } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  InfoWindow,
  InfoBox,
  LoadScript,
  Marker,
  Polyline,
  // DirectionsService,
  DirectionsRenderer,
  DistanceMatrixService,
  MarkerClusterer,
} from "@react-google-maps/api";
import { React_Google_API_Key } from "../GoogleApiKey";
import markerGreen from "../images/marker-green.svg";
import markerYellow from "../images/marker-yellow.svg";
import { baseURL } from "../Components/BaseUrl";
import CustomMarker from "./Dashboard/CustomMarker";
import moment from "moment";
import mapStyles from "../Components/Dashboard/mapStyles.json";
import carGpsMarker from "../images/car_withMarker.png";
import LoadingAnimation from "./LoadingAnimation";

function GoogleMapComp(props) {
  const refMap = useRef(null);
  const [response, setResponse] = useState(null);
  const [directionsState, setDirectionsState] = useState([]);
  const [infoWindoisOpen, setInfoWindoisOpen] = useState(false);
  const [showClientInfoWindow, setShowClientInfoWindow] = useState(null);
  const [trackClientInfoWindoisOpen, setTrackClientInfoWindoisOpen] =
    useState(null);

  const handleZoomChanged = () => {
    //   // const newZoom = refMap.current.getZoom(); //get map Zoom
    //   // setZoom(newZoom);
    //   // var zoom = map?.getZoom();
    //   // console.log(newZoom, "newZoom");
  };

  const options = {
    closeBoxURL: "",
    enableEventPropagation: true,
    closeBoxMargin: "10px",
  };

  const infoStyle = {
    textAlign: "center",
    backgroundColor: "green",
    opacity: 1,
    padding: 3,
    borderRadius: 5,
  };

  // const libraries = ["directions"];

  const { isLoaded, loadError } = useJsApiLoader({
    id: "script-loader",
    googleMapsApiKey: React_Google_API_Key,
    // libraries,
  });

  const containerStyleTrackByUser = {
    position: "relative",
    width: "100%",
    height: "calc(100vh - 80px)",
  };

  const containerStyleTrackClient = {
    position: "relative",
    width: "100%",
    height: "calc(100vh - 130px)",
  };

  const containerStyleCheckInDetails = {
    position: "relative",
    width: "100%",
    height: "calc(100vh - 60px)",
  };

  const containerStyleMeetings = {
    position: "relative",
    width: "100%",
    height: "calc(100vh - 190px)",
  };

  useEffect(() => {
    const DirectionsService = new window.google.maps.DirectionsService();
    const service = new window.google.maps.DistanceMatrixService();

    DirectionsService.route(
      {
        origin: props.startLatLan,
        destination: props.endLatLan,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setResponse(result);
        } else {
          console.error(`error fetching directions ${result}`);
          setResponse(null);
        }
      }
    );

    // service.getDistanceMatrix(
    //   {
    //     origins: [{ lat: 15.364721811267291, lng: 75.14901957954557 }],
    //     destinations: [
    //       { lat: 15.350909089850205, lng: 75.13970743864775 },
    //       { lat: 15.349401, lng: 75.1425692 },
    //     ],
    //     travelMode: window.google.maps.TravelMode.DRIVING,
    //     avoidHighways: false,
    //     avoidTolls: false,
    //     unitSystem: window.google.maps.UnitSystem.IMPERIAL,
    //   },
    //   (result, status) => {
    //     if (status === window.google.maps.DistanceMatrixStatus.OK) {
    //       setDirectionsState(result);
    //       // console.log("DISTANCE::", result);
    //     } else {
    //       console.error(`error fetching directions ${result}`);
    //       setDirectionsState(null);
    //     }
    //   }
    // );
  }, []);

  const [usersTrackByDay, setUsersTrackByDay] = useState(null);
  const [movingMarkerPosition, setMovingMarkerPosition] = useState(null);
  const [meetingData, setMeetingData] = useState(null);
  const [movingIndex, setMovingIndex] = useState(0);

  // useEffect(() => {
  //   if (props.pageType === "TrackMeetings") {
  //     let polyLineLatsLng = [];
  //     for (let i = 0; i < props.trackMeetingDetails.length; i++) {
  //       polyLineLatsLng.push({
  //         lat: +props.trackMeetingDetails[i].cordLoc.latitude,
  //         lng: +props.trackMeetingDetails[i].cordLoc.longitude,
  //       });
  //     }
  //     setUsersTrackByDay(polyLineLatsLng);
  //   }
  // }, [props.trackMeetingDetails]);

  // useEffect(() => {
  //   {
  //     props.showRouteMoving && movingMarker();
  //   }
  // }, [movingIndex, props.showRouteMoving]);

  const [opnInfoWindow, setOpnInfoWindow] = useState(false);

  // const movingMarker = () => {
  //   let polyCords = props.trackMeetingDetails;
  //   let nextIndex = movingIndex + 1;
  //   if (movingIndex == polyCords.length - 1) {
  //     props.setShowRouteMoving(false);
  //     setMovingIndex(0);
  //   } else {
  //     props.setShowRouteMoving(true);
  //   }

  //   if (nextIndex < polyCords.length) {
  //     for (let clientMarker of props.markerPositions) {
  //       if (
  //         clientMarker.lat == polyCords[nextIndex].cordLoc.latitude &&
  //         clientMarker.lng == polyCords[nextIndex].cordLoc.longitude
  //       ) {
  //         setMovingMarkerPosition(polyCords[nextIndex]);
  //         setMeetingData({
  //           clientName: polyCords[nextIndex].clientName,
  //           location: polyCords[nextIndex].cordLoc.location,
  //           meetTime: polyCords[nextIndex].onlyTime,
  //         });
  //         setOpnInfoWindow(true);
  //         props.setShowRouteMoving(false);
  //         setTimeout(() => {
  //           setMovingIndex(movingIndex + 1);
  //           props.setShowRouteMoving(true);
  //           setOpnInfoWindow(false);
  //         }, 5000);
  //       } else {
  //         setMovingMarkerPosition({
  //           lat: +polyCords[nextIndex].cordLoc.latitude,
  //           lng: +polyCords[nextIndex].cordLoc.longitude,
  //         });
  //         setTimeout(() => {
  //           setMovingIndex(movingIndex + 1);
  //         }, 600);
  //       }
  //     }
  //   }
  //   return;
  // };

  // console.log("MarkerPositions:", props.markerPositions);

  const showInfoWindow = (e) => {
    setShowClientInfoWindow(e);
  };

  const showTrackClientInfoWindow = (e) => {
    setTrackClientInfoWindoisOpen(e);
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "loading maps";

  let loc_img = "../images/dashboard/map-icon.png";

  const defaultMapOptions = {
    styles: mapStyles,
  };

  const optionsCluster = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  };

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          ref={refMap}
          // defaultOptions={defaultMapOptions}
          mapContainerStyle={
            props.pageType === "TrackuserRoute" ||
            props.pageType === "TrackMeetings" ||
            props.pageType === "RouteMap"
              ? containerStyleMeetings
              : props.pageType === "TrackClients"
              ? containerStyleTrackClient
              : props.pageType === "CheckInByMap"
              ? containerStyleCheckInDetails
              : props.pageType === "Tracker"
              ? containerStyleTrackByUser
              : containerStyleTrackClient
          }
          center={
            props.pageType === "TrackuserRoute"
              ? props.routelatLan
              : props.pageType === "TrackMeetings"
              ? props.trackClientCenLatLng
              : props.pageType === "CheckInByMap"
              ? props.centerLatLan
              : props.pageType === "TrackClients"
              ? props.baseLocation
              : props.pageType === "RouteMap"
              ? props.centerLatLng
              : props.pageType === "AllRoutesInMap"
              ? props.centerLatLng
              : props.pageType === "AllClientsInMap"
              ? props.centerLatLng
              : props.latLan
          }
          zoom={
            props.pageType === "Dashboard"
              ? 2
              : props.pageType === "TrackuserRoute" ||
                props.pageType === "RouteMap"
              ? 10
              : props.pageType === "TrackMeetings"
              ? 8
              : props.pageType === "TrackClients"
              ? 6
              : props.pageType === "Tracker"
              ? 12
              : props.pageType === "CheckInByMap" &&
                props.trackUserDetails.length == 1
              ? 12
              : props.pageType === "CheckInByMap" &&
                props.trackUserDetails.length > 1
              ? 6
              : props.pageType === "AllRoutesInMap"
              ? 8
              : props.pageType === "AllClientsInMap"
              ? 5
              : 10
          }
          zoomControl={true}
          // onZoomChanged={handleZoomChanged}
          clickableIcons={true}
        >
          {/* ------ Dashboard PAGE START ------ */}
          {props.pageType === "Dashboard" && (
            <>
              {props.activityData &&
                props.activityData.map((activity, i) => (
                  <Marker
                    key={i}
                    className="dash-mark"
                    position={{
                      lat: parseFloat(activity?.cordLoc?.latitude),
                      lng: parseFloat(activity?.cordLoc?.longitude),
                    }}
                    clickable={true}
                    draggable={false}
                    style={{
                      background: "#000",
                      padding: "10px",
                      borderRadius: "50%",
                    }}
                    onClick={() => setInfoWindoisOpen(true)}
                    icon={{
                      // url: `${activity?.BaseURL}${activity?.profileIcon}`,
                      url: loc_img,
                      scale: 5,
                      scaledSize: new window.google.maps.Size(10, 10),
                      style: {
                        borderRadius: "50%",
                      },
                    }}
                    options={{
                      // optimized: false,
                      // zIndex: 2,
                      // animation: window.google.maps.Animation.BOUNCE,
                      style: {
                        borderRadius: "50%",
                      },
                    }}
                  >
                    {/* <CustomMarker userImage={`${activity?.BaseURL}${activity?.profileIcon}`} /> */}
                    {infoWindoisOpen && (
                      <InfoWindow
                        onCloseClick={() => setInfoWindoisOpen(false)}
                      >
                        <div style={{ textAlign: "center", width: "200px" }}>
                          {activity?.fullName}
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                ))}
            </>
          )}
          {/* ------ Dashboard PAGE START ------ */}

          {/* ------ TRACKER PAGE START ------ */}

          {props.pageType === "Tracker" && (
            <>
              <Marker
                position={props.latLan}
                clickable={true}
                draggable={false}
                onClick={() => setInfoWindoisOpen(true)}
                // icon={{
                //   url: image,
                //   scaledSize: new window.google.maps.Size(32, 32),
                //   labelOrigin: new window.google.maps.Point(16, 16),
                //   anchor: new window.google.maps.Point(16, 16),
                //   // Set the custom marker icon style
                //   // Use the CSS class for the round marker icon
                //   label: { color: 'white', text: 'Marker' },
                //   options: { labelClass: 'marker-label' },
                // }}
                options={{ className: "custom-marker" }}
              >
                {infoWindoisOpen && (
                  <InfoWindow onCloseClick={() => setInfoWindoisOpen(false)}>
                    <div style={{ textAlign: "center", width: "200px" }}>
                      {props.trackUserDetails?.cordLoc?.location}
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            </>
          )}

          {/* ------ TRACKER PAGE END ------ */}

          {/* ------ TRACK MEETINGS PAGE START ------ */}

          {props.pageType === "TrackMeetings" && (
            // <>
            //   <Marker
            //     title={`Start-Time : ${props.checkInLocation.time}`}
            //     // label={{ text: `CI`, color: "#ffffff" }}
            //     position={{
            //       lat: props.checkInLocation.lat,
            //       lng: props.checkInLocation.lng,
            //     }}
            //     clickable={true}
            //     draggable={false}
            //     icon={{
            //       url: markerGreen,
            //       scale: 5,
            //     }}
            //     onClick={() => setInfoWindoisOpen(true)}
            //   >
            //     {infoWindoisOpen && (
            //       <InfoWindow onCloseClick={() => setInfoWindoisOpen(false)}>
            //         <div style={{ textAlign: "center", width: "200px" }}>
            //           <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
            //             <b>Start Address :</b>
            //           </h6>
            //           <div>{props.checkInLocation.location}</div>
            //         </div>
            //       </InfoWindow>
            //     )}
            //   </Marker>

            //   <>
            //     <Marker
            //       title={`Check-Out-Time : ${props.checkOutLocation.time}`}
            //       // label={{ text: `CI`, color: "#ffffff" }}
            //       position={{
            //         lat: props.checkOutLocation.lat,
            //         lng: props.checkOutLocation.lng,
            //       }}
            //       clickable={true}
            //       draggable={false}
            //       icon={{
            //         url: markerGreen,
            //         scale: 5,
            //       }}
            //       onClick={() => setInfoWindoisOpen(true)}
            //     >
            //       {infoWindoisOpen && (
            //         <InfoWindow onCloseClick={() => setInfoWindoisOpen(false)}>
            //           <div style={{ textAlign: "center", width: "200px" }}>
            //             <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
            //               <b>End Address :</b>
            //             </h6>
            //             <div>{props.checkOutLocation.location}</div>
            //           </div>
            //         </InfoWindow>
            //       )}
            //     </Marker>
            //   </>

            <>
              {props.markerPositions.map((data, i) => (
                <>
                  <Marker
                    title={data.clientName}
                    key={i}
                    position={{ lat: data.lat, lng: data.lng }}
                    clickable={true}
                    draggable={false}
                    label={{ text: `${i + 1}`, color: "#ffffff" }}
                    onClick={() => showInfoWindow(i)}
                  >
                    {showClientInfoWindow == i && (
                      <InfoWindow onCloseClick={() => showInfoWindow()}>
                        <div style={{ textAlign: "center", maxWidth: "200px" }}>
                          <div>
                            <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                              Company Name : {data.clientName}
                            </h6>
                          </div>
                          <div>Location : {data.location}</div>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                  {/* <Polyline
                    defaultPosition={props.trackClientCenLatLng}
                    path={usersTrackByDay}
                    // geodesic={true}
                    options={{
                      geodesic: true,
                      strokeOpacity: 0,
                      strokeWeight: 2,
                      strokeColor: "#5842bc",
                      icons: [
                        {
                          icon: {
                            path: "M 0,-1 0,1",
                            strokeOpacity: 1,
                            strokeWeight: 2,
                            scale: 4,
                          },
                          offset: "0",
                          repeat: "15px",
                        },
                      ],
                    }}
                  /> 
                  <Marker
                    title={`TRAVEL-ICON`}
                    // label={{ text: `CI`, color: "#ffffff" }}
                    position={movingMarkerPosition}
                    clickable={false}
                    draggable={false}
                    icon={{
                      url: carGpsMarker,
                      // scale: 0.02,
                      scaledSize: new window.google.maps.Size(45, 45),
                    }}
                  >
                    {opnInfoWindow && (
                      <InfoWindow onCloseClick={() => setOpnInfoWindow(false)}>
                        <div style={{ textAlign: "center", width: "200px" }}>
                          <div>
                            <div className="color-green">
                              <b>
                                Client Name : &nbsp;
                                {meetingData?.clientName ?? "MY VISIT"}
                              </b>
                            </div>
                            <div className="color-yellow">
                              <b>
                                Time : &nbsp;{" "}
                                {moment(
                                  meetingData?.meetTime,
                                  "hh:mm:ss:SSS A"
                                ).format("hh:mm A")}
                              </b>
                            </div>
                            <div>Location : {meetingData?.location ?? ""}</div>
                          </div>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker> */}
                </>
              ))}
            </>
          )}

          {/* ------ TRACK MEETINGS PAGE END ------ */}

          {/* ------ TRACK USERS CHECKIN PAGE START ------ */}

          {props.pageType == "CheckInByMap" && (
            <>
              {props.trackUserDetails.map((data, i) => (
                <Marker
                  title={`Last Seen : ${data.lastSeen}`}
                  key={i}
                  position={{
                    lat: data.lat,
                    lng: data.lng,
                  }}
                  clickable={true}
                  draggable={false}
                  label={{ text: `${i + 1}`, color: "#ffffff" }}
                  onClick={() => showInfoWindow(i)}
                >
                  {showClientInfoWindow == i && (
                    <InfoWindow onCloseClick={() => showInfoWindow()}>
                      <div style={{ textAlign: "center", width: "200px" }}>
                        <div>
                          <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                            Name : {data.fullName}
                          </h6>
                        </div>
                        <div>
                          Location : {data.location}
                          <br />
                          <span>Last Seen : {data.lastSeen}</span>
                        </div>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </>
          )}

          {/* ------ TRACK USERS CHECKIN PAGE END ------ */}

          {/* ------ TRACK CLIENT PAGE START ------ */}

          {props.pageType === "TrackClients" && (
            <>
              <Marker
                title={`Base-Location `}
                // label={{ text: `CI`, color: "#ffffff" }}
                position={{
                  lat: props.baseLocation.lat,
                  lng: props.baseLocation.lng,
                }}
                clickable={true}
                draggable={false}
                icon={{
                  url: markerGreen,
                  scale: 5,
                }}
                onClick={() => setInfoWindoisOpen(true)}
              >
                {infoWindoisOpen && (
                  <InfoWindow onCloseClick={() => setInfoWindoisOpen(false)}>
                    <div style={{ textAlign: "center", width: "150px" }}>
                      <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                        Base-Location :
                      </h6>
                      <div>{props.baseLocation.location}</div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>

              {props.clientCordinates.map((data, i) => (
                <Marker
                  title={data.clientName}
                  key={i}
                  position={{ lat: data.lat, lng: data.lng }}
                  clickable={true}
                  draggable={false}
                  label={{ text: `${i + 1}`, color: "#ffffff" }}
                  onClick={() => showTrackClientInfoWindow(i)}
                >
                  {trackClientInfoWindoisOpen == i && (
                    <InfoWindow
                      onCloseClick={() => showTrackClientInfoWindow()}
                    >
                      <div style={{ textAlign: "center", width: "200px" }}>
                        <div>
                          <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                            Client Name : {data.clientName}
                          </h6>
                        </div>
                        <div>Location : {data.location}</div>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </>
          )}

          {/* ------ TRACK CLIENT PAGE END ------ */}

          {/* ------ TRACK USER ROUTE PAGE START ------ */}

          {props.pageType === "TrackuserRoute" && (
            <>
              {response !== null && (
                <>
                  <DirectionsRenderer directions={response} />
                </>
              )}
            </>
          )}
          {/* ------ TRACK USER ROUTE PAGE END ------ */}

          {/* ------ TRACK USERS CHECKIN PAGE START ------ */}

          {props.pageType == "AllRoutesInMap" && (
            <>
              {props.clientsLocations.map((data, i) => (
                <Marker
                  title={`Distance : ${data?.distance}`}
                  key={i}
                  position={{
                    lat: data.lat,
                    lng: data.lng,
                  }}
                  clickable={true}
                  draggable={false}
                  label={{ text: `${i + 1}`, color: "#ffffff" }}
                  onClick={() => showInfoWindow(i)}
                >
                  {showClientInfoWindow == i && (
                    <InfoWindow onCloseClick={() => showInfoWindow()}>
                      <div style={{ textAlign: "center", width: "200px" }}>
                        <div>
                          <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                            {data.clientName
                              ? `CLIENT : ${data?.clientName}`
                              : `USER : ${data?.userName}`}
                          </h6>
                        </div>
                        <div>
                          Location : {data.location}
                          <br />
                          <span>Distance : {data.distance}</span>
                        </div>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
              <Polyline
                defaultPosition={props.centerLatLng}
                path={props.clientsLocations}
                // path={(props.endLatLan, props.startLatLan)}
                geodesic={true}
                strokeColor={"#04A96D"}
                strokeOpacity={1.0}
                strokeWeight={8}
              />{" "}
              {directionsState !== null && (
                <DirectionsRenderer directions={directionsState} />
              )}
            </>
          )}

          {props.pageType === "AllClientsInMap" && (
            <>
              <MarkerClusterer
                options={optionsCluster}
                gridSize={10}
                minimumClusterSize={5}
              >
                {(clusterer) =>
                  props.clientsLocations.map((data, i) => (
                    <Marker
                      title={`Client : ${data?.clientName}`}
                      key={i}
                      position={{
                        lat: +data.clientLoc.latitude,
                        lng: +data.clientLoc.longitude,
                      }}
                      clickable={true}
                      animation={window.google.maps.Animation.DROP}
                      draggable={false}
                      label={{
                        text: `${i + 1}`,
                        color: "#ffffff",
                        fontSize: "8px",
                      }}
                      // icon={{
                      //   url: markerYellow,
                      //   scale: 5,
                      // }}
                      clusterer={clusterer}
                      onClick={() => showInfoWindow(i)}
                    >
                      {showClientInfoWindow == i && (
                        <InfoWindow onCloseClick={() => showInfoWindow()}>
                          <div style={{ textAlign: "center", width: "200px" }}>
                            <div>
                              <h6
                                style={{ fontSize: "12px", fontWeight: "600" }}
                              >
                                {data.clientName
                                  ? `CLIENT : ${data?.clientName}`
                                  : `Location : ${data?.clientLoc?.location}`}
                              </h6>
                            </div>
                            <div>
                              Location : {data.clientLoc?.location}
                              <br />
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  ))
                }
              </MarkerClusterer>
            </>
          )}

          {/* ------ TRACK USERS CHECKIN PAGE END ------ */}

          {/* ------ ROUTE MAP PAGE START ------ */}
          {props.pageType === "RouteMap" && (
            <>
              {response !== null && (
                <>
                  <DirectionsRenderer directions={response} />
                </>
              )}
            </>
          )}

          {/* ------ ROUTE MAP PAGE END ------ */}
        </GoogleMap>
      ) : (
        <div className="map-is-loading">
          <LoadingAnimation />
          Map is loading....
        </div>
      )}
    </div>
  );
}

export default GoogleMapComp;
