import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/Leaves.scss";
import "../../styles/CommonStyle.scss";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import * as swal from "../Consturl/SwalAlert";
import { FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { travelizeAuth } from "../HeaderAuthentication";
import { toast } from "react-toastify";

export default function ReAssignModel(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const [userList, setUserList] = useState(props.userDropdown);
  const [userId, setUserId] = useState("");
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  useEffect(() => {
    toast.info(
      `You have selected ${props.reAssignData.length} out of ${props.totalCounts}`,
      {
        autoClose: 10000,
      }
    );
    let clientIDs = props.reAssignData.map((data) => data.clientID.toString());
    // console.log("Re-Assign-Data::", clientIDs);
  }, [props.reAssignData]);

  const [fieldReq, setFieldReq] = useState(false);

  const updateReAssignUser = (e) => {
    e.preventDefault();
    let clientIDs = props.reAssignData.map((data) => data.clientID.toString());
    if (!userId) {
      setFieldReq(true);
    } else {
      const reassignData = {
        // ClientID: 0,
        client: {
          addedByUserID: userId,
          customerIds: clientIDs,
        },
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(reassignData),
      };
      fetch(`${baseURL}/api/Client/ReAssignClient`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          // console.log("UPDATE-MANAGER-RES::", res);
          if (res.success) {
            toast.success(`${res.message}`, {
              autoClose: 10000,
            });
            props.openAssignToUserModal();
            props.fetchClientData();
          } else {
            toast.error(`${res.errorMessage}`, {
              autoClose: 10000,
            });
          }
        });
    }
  };

  return (
    <div>
      <Modal show={props.openReassignModal} className="main_model">
        <div className="update-leavestatus-heading">
          <h6 className="page-header-text">ASSIGN TO</h6>
          <i className="fa fa-times" onClick={props.openAssignToUserModal} />
        </div>
        <div className="container model-update-leavestatus-body">
          <form>
            <div className="row model-add-user-body">
              <div className="mb-1">
                <FloatingLabel
                  label="Assign To User"
                  className={`${
                    userId
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    options={userList}
                    placeholder="Select User"
                    value={userList.value}
                    onInputChange={userList.label}
                    onChange={(data) => setUserId(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
                {fieldReq && !userId && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select User
                  </small>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={updateReAssignUser} btnName="Save" />
          <SbCancelBtn
            btnName="Cancel"
            onClickEffect={props.openAssignToUserModal}
          />
        </div>
      </Modal>
      <></>
    </div>
  );
}
