import React from "react";
import Modal from "react-bootstrap/Modal";

export default function MappedUsers(props) {
  return (
    <div>
      {" "}
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          <h6 className="add-edit-basefare-head">Mapped Users</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <div className="m-2 p-2">
          <ol>
            {props.users.split(",").map((data, i) => (
              <li key={i}>{data}</li>
            ))}
          </ol>
        </div>

        <div className="btns-save-cancel"></div>
      </Modal>
    </div>
  );
}
