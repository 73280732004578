import React, { useState, useRef, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Profile.scss";
import { Col, Form, Row } from "react-bootstrap";
import profileimg from "../../../images/profile-1.png";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../BaseUrl";
import * as swal from "../../Consturl/SwalAlert";
import { loggedInRole } from "../../LoginRole";
import { travelizeAuth, salesbeeImgAuth } from "../../HeaderAuthentication";
import { NumberValidation } from "../../ValidationFormat";
import AccessDenied from "../../../AccessDenied";
import { useDispatch } from "react-redux";
import { loadPageBreadCome } from "../../../redux/action";
import { SbBackBtn } from "../../SbButtons/SbCancelBtn";
import Footer from "../../Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Profile() {
  let resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let dispatch = useDispatch();

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [contactNum, setContactNum] = useState(resPassData?.contact);
  const [companyName, setCompanyName] = useState(resPassData?.fullName);
  const [email, setEmail] = useState(resPassData?.email);
  const [imgFile, setImgFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [oldPassword, setOldPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [localImage, setLocalImage] = useState(null);

  const [alertMessageInner, setAlertMessageInner] = useState([
    {
      oldPassAlert: "",
      confirmPassAlert: "",
      successAlert: "",
    },
  ]);
  const [profileUpdateMessage, setProfileUpdateMessage] = useState("");

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: "",
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    let breadCome = { mainpage: "Profile" };
    dispatch(loadPageBreadCome(breadCome));
  }, []);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };
  let navigate = useNavigate();

  const hiddenFileInput = useRef(null);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setFileName(event.target.files[0].name);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };
  // console.log("IMAGE-FILE:", imgFile);

  //  ADDING PROFILE IMAGE BY USER START

  const updateProfileWithPhoto = (e) => {
    e.preventDefault();
    if (imgFile !== null && imgFile !== "") {
      const formData = new FormData();
      formData.append("image", imgFile);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(
        `${baseURL}/api/Upload/UploadProfile?directory=Profile`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          let imgUrl;
          if (res.success) {
            imgUrl = res.result[0];
            swal.showAlert("Success", `Profil ${res.message}`, "success");
          } else {
            toast.error(res.errorMessage, { autoClose: 6000 });
          }
          UpdateProfileDetail(e, imgUrl);
        });
    } else {
      UpdateProfileDetail(e);
    }
    setFileName("");
  };
  //  ADDING PROFILE IMAGE BY USER END

  const UpdateProfileDetail = (e, imgUrl) => {
    e.preventDefault();
    if (
      contactNum.toString().length == 10 &&
      companyName !== "" &&
      email !== ""
    ) {
      const profileData = {
        FullName: companyName,
        Email: email,
        Contact: JSON.parse(contactNum),
        ProfilePic: imgUrl,
      };
      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(profileData),
      };
      fetch(`${baseURL}/api/User/UpdateSelfProfile`, requestoption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toast.success(res.message, { autoClose: 5000 });
            getLoginUserDetails();
            setImgFile("");
          } else {
            toast.error(res.errorMessage, { autoClose: 6000 });
            setImgFile("");
          }
        })
        .catch((error) => console.error(error));
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required",
      });
    }
  };

  const updatePassword = () => {
    if (oldPassword !== "" && password !== null && confirmPassword !== "") {
      if (password === confirmPassword) {
        const resetPassData = {
          oldCred: oldPassword,
          newCred: password,
        };
        let requestoption = {
          method: "POST",
          mode: "cors",
          headers: travelizeAuth,
          body: JSON.stringify(resetPassData),
        };
        fetch(`${baseURL}/api/User/UpdateSelfPassword`, requestoption)
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              toast.success(res.message, { autoClose: 5000 });
              setTimeout(() => navigate("/"), 2000);
            } else {
              setAlertMessageInner({
                oldPassAlert: `${res.errorMessage}`,
                confirmPassAlert: "",
                successAlert: "",
              });
            }
          });
      } else {
        setAlertMessageInner({
          oldPassAlert: "",
          confirmPassAlert: "New Password & Confirm Password must be same",
          successAlert: "",
        });
      }
    } else {
      setAlertMessageInner({
        oldPassAlert: "",
        confirmPassAlert: "Please fill the fields",
        successAlert: "",
      });
    }
  };

  const getLoginUserDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/DashBoard/GetLoginUserDetails`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        const userLoginDetails = res.result;
        sessionStorage.setItem("usrlgndtls", JSON.stringify(userLoginDetails));
      });
    navigate("/settings/profile");
  };

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">PROFILE</h6>
                      </div>
                    </Col>
                    <Col md={9} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      ></div>
                    </Col>
                  </Row>
                </div>
                {/* LEADS TABLE START */}
                <div className="profile-table">
                  <div className="profile-edit-info-row">
                    <div className="row">
                      {/* PROFILE LEFT PART START */}
                      <div className="col-md-7 profile-left-part">
                        <div className="edit-info-password-part">
                          <div className="edit-info-head">
                            <h6>
                              <b>Edit Info</b>
                            </h6>
                          </div>
                          <div className="row edit-info">
                            <div className="col-md-4">
                              <div className="profile-image-section">
                                {localImage ? (
                                  <img
                                    src={localImage}
                                    alt=""
                                    className="profile-page profile-img"
                                  />
                                ) : (
                                  <img
                                    className="profile-page profile-img"
                                    src={
                                      resPassData?.profilePic !== "" &&
                                      resPassData?.profilePic !== null &&
                                      resPassData?.profilePic !== "no_img.png"
                                        ? `${baseURL}/${resPassData?.profilePic}`
                                        : profileimg
                                    }
                                    alt=""
                                  />
                                )}
                                <button
                                  type="button"
                                  className="btn btn-add-user-img-profile"
                                  onClick={uploadClick}
                                >
                                  <span className="change-profile-img hv-center">
                                    <i className="bi bi-pencil-fill"></i>
                                    <p>Change Picture</p>
                                  </span>
                                </button>
                                <input
                                  id="getFile"
                                  type="file"
                                  name="profileImage"
                                  accept="image/*"
                                  className="btn-primary upload"
                                  ref={hiddenFileInput}
                                  onChange={handleChange}
                                  style={{ display: "none" }}
                                />
                              </div>
                              <small className="file-name-display">
                                {fileName}
                              </small>
                            </div>
                            <div className="col-md-8">
                              <form onSubmit={updateProfileWithPhoto}>
                                <div className="input-group mb-3">
                                  <input
                                    type="text"
                                    className="form-control edit-info-inputs"
                                    value={companyName}
                                    onChange={(e) =>
                                      setCompanyName(e.target.value)
                                    }
                                  />
                                  <span
                                    className="input-group-text"
                                    id="user-icon"
                                  >
                                    <i className="bi bi-person-fill"></i>
                                  </span>
                                </div>
                                <div className="input-group mb-3">
                                  <input
                                    type="email"
                                    className="form-control edit-info-inputs"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                  />
                                  <span
                                    className="input-group-text"
                                    id="email-icon"
                                  >
                                    <i className="bi bi-envelope-fill"></i>
                                  </span>
                                </div>
                                <div className="input-group mb-3">
                                  <input
                                    type="tel"
                                    className="form-control edit-info-inputs"
                                    maxLength={10}
                                    value={contactNum}
                                    onChange={(e) =>
                                      setContactNum(e.target.value)
                                    }
                                    onKeyDown={NumberValidation}
                                    required
                                  />
                                  <span
                                    className="input-group-text"
                                    id="phone-icon"
                                  >
                                    <i className="bi bi-telephone-fill"></i>
                                  </span>
                                </div>
                                <div className="form-group"></div>
                                <div className="input-group mb-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success update"
                                    // onClick={updateProfileWithPhoto}
                                  >
                                    Update
                                  </button>
                                  {profileUpdateMessage !== "" ? (
                                    <small
                                      id="kilometerMsgSuccess"
                                      className="form-text text-muted"
                                    >
                                      {profileUpdateMessage}
                                    </small>
                                  ) : fieldReq && companyName == "" ? (
                                    <small
                                      id={`Error${alertMessage.alertType}`}
                                      className="form-text text-muted "
                                    >
                                      {`${alertMessage.message} Company Name`}
                                    </small>
                                  ) : fieldReq &&
                                    contactNum.toString().length < 10 ? (
                                    <small
                                      id={`Error${alertMessage.alertType}`}
                                      className="form-text text-muted "
                                    >
                                      {`${alertMessage.message} 10 Numbers`}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* PROFILE LEFT PART END */}

                      {/* PROFILE RIGHT PART START */}
                      <div className="col-md-5 profile-right-part">
                        <div className="change-pass-part">
                          <div className="change-password-head">
                            <h6>
                              <b>Change Password</b>
                            </h6>
                          </div>
                          <div className="row change-password">
                            <div style={{ position: "relative" }}>
                              <div className="input-group mb-3">
                                <input
                                  type="password"
                                  className="form-control edit-info-inputs"
                                  value={oldPassword}
                                  placeholder="Old Password"
                                  onChange={(e) =>
                                    setOldPassword(e.target.value)
                                  }
                                  required
                                />
                                <span
                                  className="input-group-text"
                                  id="user-icon"
                                >
                                  <i className="bi bi-key-fill"></i>
                                </span>
                              </div>

                              {alertMessageInner.oldPassAlert && (
                                <small
                                  id="inputFieldErrorMsg"
                                  className="form-text text-muted "
                                >
                                  {oldPassword != ""
                                    ? alertMessageInner.oldPassAlert
                                    : "Required !"}
                                </small>
                              )}
                            </div>
                            <div style={{ position: "relative" }}>
                              <div className="input-group mb-3">
                                <input
                                  type="password"
                                  className="form-control edit-info-inputs"
                                  placeholder="New Password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                                />
                                <span
                                  className="input-group-text"
                                  id="email-icon"
                                >
                                  <i className="bi bi-key-fill"></i>
                                </span>
                              </div>
                              {(alertMessageInner.oldPassAlert ||
                                alertMessageInner.confirmPassAlert) &&
                              password == "" ? (
                                <small
                                  id="inputFieldErrorMsg"
                                  className="form-text text-muted"
                                >
                                  Required !
                                </small>
                              ) : (
                                ""
                              )}
                            </div>

                            <div style={{ position: "relative" }}>
                              <div className="input-group mb-3">
                                <input
                                  type="password"
                                  className="form-control edit-info-inputs"
                                  placeholder="Confirm Password"
                                  value={confirmPassword}
                                  onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                  }
                                  required
                                />
                                <span
                                  className="input-group-text"
                                  id="phone-icon"
                                >
                                  <i className="bi bi-key-fill"></i>
                                </span>
                              </div>
                              {alertMessageInner.confirmPassAlert && (
                                <small
                                  id="inputFieldErrorMsg"
                                  className="form-text text-muted"
                                >
                                  {confirmPassword != ""
                                    ? alertMessageInner.confirmPassAlert
                                    : "Required !"}
                                </small>
                              )}
                            </div>

                            <div className="input-group mb-3">
                              <button
                                className="btn btn-success update"
                                onClick={(e) => updatePassword(e)}
                              >
                                Update Password
                              </button>
                              {alertMessageInner.successAlert && (
                                <small
                                  id="inputFieldErrorMsgSuccess"
                                  className="form-text text-muted"
                                >
                                  {alertMessageInner.successAlert}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* PROFILE RIGHT PART END */}
                    </div>
                  </div>
                </div>
                {/* LEADS TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Profile;
