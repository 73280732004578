import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { Col, Row } from "react-bootstrap";
import "../../styles/Forms.scss";
import { useNavigate } from "react-router-dom";
import AccessDenied from "../../AccessDenied";
import CreatedForms from "./CreatedForms";
import FormsDataByUser from "./FormsDataByUser";
import Footer from "../Footer";

export default function Forms() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const [showFormData, setShowFormData] = useState(false);
  const [formName, setFormName] = useState("");
  const [customPageID, setCustomPageID] = useState(0);

  // Open Filter div
  const [filter, setFilter] = useState(true);
  // Open Filter div

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openFormDataByuser = () => {
    setShowFormData(true);
  };

  const backToFormPage = () => {
    setShowFormData(false);
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // Page navigation

  return (
    <div>
      {" "}
      <div id="main-page">
        {userDetails !== null && userDetails !== "" ? (
          <>
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
              toggleMenu={toggleMenu}
              menuCollapse={menuCollapse}
              toggleshift={toggleshift}
            />
            {/* TOPBAR END */}

            {/* MAIN BODY START */}
            <div className="page-wrapper">
              <div className={`main-body ${toggleshift.style}`}>
                {/* PAGE CONTENT START */}
                <div className="page-content">
                  <div className="customforms-from-api">
                    <Row className="search-row">
                      <Col md={3} className="cat-col">
                        <div className="page-header-text-div">
                          <div className="back-btn" onClick={goToBackPage}>
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6 className="page-header-text">FORMS</h6>
                        </div>
                      </Col>
                      <Col md={5} className="cat-col"></Col>
                      <Col md={4} className="cat-col">
                        {/* <div
                          className="search-bar-header"
                          style={{ float: "right" }}
                        >
                          <div className="page-top-bar-dropdowns page-top-Filters">
                            <div
                              className="header-filter-btn"
                              onClick={() => setFilter(!filter)}
                            >
                              <p className="card-head">
                                <i className="bi bi-sliders" />
                                &nbsp;&nbsp;Filters
                              </p>
                            </div>
                          </div>
                        </div> */}
                      </Col>
                    </Row>

                    <div className="forms-n-data-table">
                      {!showFormData ? (
                        <CreatedForms
                          openFormDataByuser={openFormDataByuser}
                          setCustomPageID={setCustomPageID}
                          setFormName={setFormName}
                        />
                      ) : (
                        <FormsDataByUser
                          customPageID={customPageID}
                          backToFormPage={backToFormPage}
                          filter={filter}
                          formName={formName}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* PAGE CONTENT END*/}
              </div>
              {/* MAIN BODY END */}
            </div>
            <Footer />
          </>
        ) : (
          <AccessDenied />
        )}
      </div>
    </div>
  );
}
