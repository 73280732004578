import React, { useState, useEffect, useRef } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Routes.scss";
import { Col, Row } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import AccessDenied from "../../AccessDenied";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../Skeleton Loading/SkeletonStdTable";
import RoutePlanDetails from "./RoutePlanDetails";
import * as swal from "../Consturl/SwalAlert";
import { loadUserDropdown } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SbCancelBtn, { MiClearButton } from "../SbButtons/SbCancelBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import SbAddBtn, { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import AddRoute from "./AddRoute";
import Footer from "../Footer";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import AssignToRouteModel from "./AssignToRouteModel";
import RouteDeviationReport from "./RouteDeviationReport";
import DownloadLoading from "../ReUseComponents/DownloadLoading";
import { travelizeAuth } from "../HeaderAuthentication";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function RoutePlan() {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const AdminAccess = sessionStorage.getItem("AdminAccess");
  const ManagerAccess = sessionStorage.getItem("ManagerAccess");

  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [routePlanData, setRoutePlanData] = useState([]);
  const [activeStatus, setActiveStatus] = useState(2);
  const [viewRouteID, setViewRouteID] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const [fromDate, setFromDate] = useState(firstDayOfMonth);
  const [toDate, setToDate] = useState(lastDayOfMonth);
  const [editRouteID, setEditRouteID] = useState(null);
  const [viewDetails, setViewDetails] = useState(false);
  const [show, setShow] = useState(false);
  const [clientsMeetDate, setClientsMeetDate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterUserId, setFilterUserId] = useState("");
  const [routeID, setRouteID] = useState(0);
  const [zoneID, setZoneID] = useState(0);
  const [stateID, setStateID] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ruteFromDate, setRouteFromDate] = useState();
  const [routeToDate, setRouteToDate] = useState();
  const [assignToRouteModel, setAssignToRouteModel] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  // PAGINATION END

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadUserDropdown());
  }, []);

  const addEditRouteModel = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setAssignToRouteModel(false);
    setEditRouteID(null);
    setClientsMeetDate(false);
  };

  const backtoRoutes = () => {
    setViewDetails(!viewDetails);
  };

  const [showRouteDeviationReport, setShowRouteDeviationReport] =
    useState(false);

  const handleRouteDeviationClick = () => {
    setShowRouteDeviationReport(true);
  };

  const backtoRouteFromRouteDeviation = () => {
    setShowRouteDeviationReport(!showRouteDeviationReport);
  };

  const assignRouteTo = (routesid) => {
    setAssignToRouteModel(true);
    setViewRouteID(routesid);
  };

  // GET PAGE START
  useEffect(() => {
    fetchRoutePlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeStatus,
    fromDate,
    toDate,
    searchVal,
    currentPage,
    PageSize,
    filterUserId,
  ]);

  const fetchRoutePlans = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Route/Get?RouteID=${routeID}&Text=${searchVal}&UserID=${filterUserId}&ZoneID=${zoneID}&StateID=${stateID}&Status=${activeStatus}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRoutePlanData(res.result);
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setRoutePlanData("");
          setIsLoading(false);
          setLoading(false);
        }
      });
  };
  // GET PAGE END

  // CHANGE ROUTE STATUS START
  const changeRouteStatus = (routeId, status) => {
    if (routeId !== 0 && routeId !== null && routeId !== "") {
      let statusData = {
        subscriptionID: resPassData.subscriptionId,
        routeID: routeId,
        status: status,
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(statusData),
      };
      fetch(`${baseURL}/api/Route/UpdateRoute`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            fetchRoutePlans();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            toast.error(res.errorMessage, { autoClose: 6000 });
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
        });
    } else {
      toast.error(`Route-ID is empty`, { autoClose: 6000 });
    }
  };

  // CHANGE ROUTE STATUS END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    if (start && end) {
      setToDate(end);
    } else {
      setToDate(null);
    }
  };

  const clearSerachBySelect = () => {
    setActiveStatus(2);
    setFilterUserId("");
    setFromDate(firstDayOfMonth);
    setToDate(lastDayOfMonth);
  };

  //   const exportExcelBaseFare = () => {
  //     exportExcel(
  //       `${baseURL}/api/Export/MotBaseFareExcelExport?PageSize=100&CurrentPage=1`,
  //       "RoutePlan.xlsx", setDownloadIsLoading
  //     );
  //   };

  // Page navigation
  const navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  const componentRef = useRef();

  const pdfDownload = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div id="main-page">
      {!show && !clientsMeetDate ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {!viewDetails && !showRouteDeviationReport ? (
              <div className={`main-body ${toggleshift.style}`}>
                <div className="page-content">
                  {/* Filter and Header Section */}
                  <div className="user-table">
                    <Row className="page-filter-section">
                      <Col md={3} className="p-0">
                        <div className="page-header-text-div">
                          <div className="back-btn" onClick={goToBackPage}>
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6 className="page-header-text"> ROUTES </h6>
                        </div>
                      </Col>
                      <Col md={4} className="p-0">
                        <div className="input-div">
                          <input
                            type="search"
                            id="metricInfo_search_bar"
                            placeholder="Find a Route"
                            className="form-control setting-input"
                            value={searchVal}
                            onChange={(e) => setSearchVal(e.target.value)}
                          />
                          <i className="fa fa-search search-icon" />
                        </div>
                      </Col>

                      <Col md={5} className="p-0">
                        <div className="filter-row-input-boxes-end">
                          <div
                            className="route-deviation-link"
                            onClick={handleRouteDeviationClick}
                          >
                            <Link>
                              <i className="fa fa-location-arrow" />
                              &nbsp; Route Deviation
                            </Link>
                          </div>
                          <div
                            className="header-filter-btn"
                            onClick={() => setFilter(!filter)}
                          >
                            <div className="filter-icon-text">
                              <i className="bi bi-sliders" />
                              <span>Filters</span>
                            </div>
                          </div>
                          {(AdminAccess || ManagerAccess) && (
                            <SbAddBtn onClickEffect={addEditRouteModel} />
                          )}
                        </div>
                      </Col>
                    </Row>

                    {filter && (
                      <div className="page-filter-section">
                        <div className="filter-row-input-boxes">
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={userDropdown}
                              placeholder="Select User"
                              value={
                                filterUserId == "" ? "" : userDropdown.value
                              }
                              onInputChange={userDropdown.label}
                              onChange={(data) => setFilterUserId(data.value)}
                              isSearchable={true}
                            />
                          </div>

                          <div className="from-to-date-select">
                            <div className="input-group-text" id="from-label">
                              From - To
                            </div>
                            <div className="date-picker-with-icon">
                              <DatePicker
                                selected={fromDate}
                                className="select from-date date-filter"
                                id="date-range-picker"
                                dateFormat="dd/MM/yyyy"
                                selectsRange
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={(date) => setSelectSearchDrop(date)}
                                // maxDate={new Date()}
                              />
                              <div>
                                <i
                                  className="fa fa-calendar-o calander-icon"
                                  style={{ right: "6px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <MiClearButton
                          onClickEffect={clearSerachBySelect}
                          btnName="Clear"
                        />
                      </div>
                    )}
                  </div>
                  {/* Filter and Header Section */}

                  {/* USER TABLE START */}
                  <div className="routes-table">
                    <div
                      className="table-responsive routes-scrollbar"
                      onScroll={(e) =>
                        handleScroll(
                          e,
                          routePlanData,
                          totalData,
                          setLoading,
                          setPageSize,
                          PageSize
                        )
                      }
                    >
                      <table
                        className="table routes-table-data"
                        ref={componentRef}
                      >
                        <thead className="thead-light routes-thead">
                          {isLoading ? (
                            <>
                              <SkeletonStdTableHead />
                            </>
                          ) : (
                            <tr>
                              <th>NAME</th>
                              <th>FREQUENCY</th>
                              <th>FROM DATE</th>
                              <th>TO DATE</th>
                              <th>TYPE</th>
                              <th>ASSIGNED TO </th>
                              <th style={{ textAlign: "center" }}>ACTION</th>
                            </tr>
                          )}
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                            </>
                          ) : routePlanData !== "" ? (
                            routePlanData.map((data, i) => (
                              <tr key={i}>
                                <td>
                                  <p
                                    onClick={() => {
                                      setViewDetails(!viewDetails);
                                      setViewRouteID(data.routeID);
                                      setRouteFromDate(data.fromDate);
                                      setRouteToDate(data.toDate);
                                    }}
                                    className="btn routes-edit-link"
                                  >
                                    <div className="table-row-head-Text">
                                      {data?.name}
                                    </div>
                                    <div className="table-row-sub-text">
                                      Route No. : {data?.code}
                                    </div>
                                  </p>
                                </td>
                                <td>{data?.frequency}</td>
                                <td>{data?.fromDate}</td>
                                <td>{data?.toDate}</td>
                                <td>{data?.routeType ?? "NA"}</td>
                                <td>
                                  <p className="dflex-j-start">
                                    {data?.addedByUserFullName}
                                    <OverlayTrigger
                                      placement={"top"}
                                      delay={{ show: 250, hide: 300 }}
                                      overlay={
                                        <Tooltip id={`tooltip-dashboard`}>
                                          Re-Assign
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        className="btn client-asign-btn"
                                        onClick={() =>
                                          assignRouteTo(data.routeID)
                                        }
                                      >
                                        <span className="material-symbols-outlined">
                                          quick_phrases
                                        </span>
                                      </button>
                                    </OverlayTrigger>
                                  </p>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <div className="hv-center">
                                    {/* //  EDIT ROUTE IS COMMENTED TEMPORARILY  // */}

                                    {/* <div
                                      className="edit-route"
                                      onClick={() => {
                                        addEditRouteModel();
                                        setEditRouteID(data.routeID);
                                      }}
                                    >
                                      <p className="color-green">
                                        <i className="fa fa-edit" />
                                        &nbsp;Edit
                                      </p>
                                    </div> */}

                                    {/* //  EDIT ROUTE IS COMMENTED TEMPORARILY  // */}

                                    <div>
                                      <p className="pause-play-btn">
                                        {data?.status == 1 ? (
                                          <i
                                            className="fa fa-pause-circle"
                                            onClick={() => {
                                              changeRouteStatus(
                                                +data.routeID,
                                                +0
                                              );
                                            }}
                                            title="PAUSE"
                                          />
                                        ) : (
                                          <i
                                            className="fa fa-play-circle"
                                            onClick={() => {
                                              changeRouteStatus(
                                                +data.routeID,
                                                +1
                                              );
                                            }}
                                            title="PLAY"
                                          />
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                {" "}
                                --- NO DATA FOUND ---{" "}
                              </td>
                            </tr>
                          )}
                          {loading && <OnScrollLoading />}
                        </tbody>
                      </table>
                    </div>
                    <div className="download-and-pagination">
                      <SbDownloadBtn onClickEffect={pdfDownload} />
                    </div>
                  </div>
                  {/* USER TABLE END */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>
            ) : (
              <>
                {viewDetails ? (
                  <RoutePlanDetails
                    backtoRoutes={backtoRoutes}
                    viewRouteID={viewRouteID}
                    toggleshift={toggleshift}
                    fromDate={ruteFromDate}
                    toDate={routeToDate}
                  />
                ) : (
                  ""
                )}
                {showRouteDeviationReport && (
                  <RouteDeviationReport
                    backtoRouteFromRouteDeviation={
                      backtoRouteFromRouteDeviation
                    }
                    toggleshift={toggleshift}
                  />
                )}
              </>
            )}
          </div>
          <Footer />
          {/* {downloadIsLoading && (
            <>
              <DownloadLoading />
            </>
          )} */}
          <>
            {assignToRouteModel && (
              <AssignToRouteModel
                assignToRouteModel={assignToRouteModel}
                handleClose={handleClose}
                viewRouteID={viewRouteID}
                fetchRoutePlans={fetchRoutePlans}
              />
            )}
          </>
        </>
      ) : (
        <>
          {show && (
            <AddRoute
              editRouteID={editRouteID}
              handleClose={handleClose}
              fetchRoutePlans={fetchRoutePlans}
            />
          )}
        </>
      )}
    </div>
  );
}
