import { useState, useEffect } from "react";
import { baseURL } from "../BaseUrl";
import * as swal from "../Consturl/SwalAlert";
import BulkUploadComp from "../ReUseComponents/BulkUploadComp";

export default function LeavesBulkUpload(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const salesbeeImgAuth = { Travelize_Authentication: userDetails };
  const [bulkUploadResponse, setBulkUploadResponse] = useState({
    res: null,
    message: null,
  });

  const [uploadExcelFile, setUploadExcelFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadExcelFile(fileUploaded);
  };

  useEffect(() => {
    setUploadExcelFile("");
    setBulkUploadResponse({ res: "", message: "" });
  }, [props.bulkUploadModel, props.handleBulkUpload]);

  const addBulkLeaves = () => {
    if (uploadExcelFile != null || uploadExcelFile != "") {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("Files", uploadExcelFile);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(
        `${baseURL}/api/Import/ImportLeaveBalance?Directory=BulkLeaves`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setBulkUploadResponse({ res: res.success, message: res.message });
            props.handleClose();
            setIsLoading(false);
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            setBulkUploadResponse({
              res: res.success,
              message: res.errorMessage,
            });
            setIsLoading(false);
          }
        });
    }
  };

  const closeModel = () => {
    props.handleClose();
  };

  return (
    <div>
      {" "}
      <BulkUploadComp
        pageFrom="LeaveBalance"
        show={props.bulkUploadModel}
        closeModel={closeModel}
        heading="ADD BULK LEAVES"
        handleChange={handleChange}
        downloadTemplateUrl={"BulkLeaves/Excel_20231115123632465.xlsx"}
        uploadClick={addBulkLeaves}
        bulkUploadResponse={bulkUploadResponse}
        isLoading={isLoading}
      />
    </div>
  );
}
