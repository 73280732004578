import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SkeletonTableData(props) {
  return (
    <tr>
      <td>
        <p className="dflex-j-start">
          <Skeleton width={30} height={30} circle={true} />
          <Skeleton width={120} height={30} className="m-1" />
        </p>
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
    </tr>
  );
}

export default SkeletonTableData;
