import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import GoogleMapComp from "../GoogleMapComp";
import { baseURL } from "../BaseUrl";
import Iframe from "react-iframe";
import OpenStreenMap from "../OpenStreenMap";

export default function AllRoutesInMap(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [centerLatLng, setCenterLatLng] = useState({
    lat: null,
    lng: null,
  });

  useEffect(() => {
    let clientslength = +props.clientLatLng?.length;
    let centerLatitudeLongitude = {
      lat: props.clientLatLng[0]?.lat,
      lng: props.clientLatLng[clientslength - 1]?.lng,
    };

    setCenterLatLng(centerLatitudeLongitude);
  }, [props.clientLatLng]);

  //   console.log("BASE+CLIENT::", props.clientLatLng);
  //   console.log("CENTER-LAT-LNG::", centerLatLng);

  return (
    <div>
      {" "}
      <Modal
        show={props.routesInMap}
        // onHide={props.openGoogleMaps}
        className="main_model google-maps-route-model p-0"
      >
        <div>
          <div className="add-user-heading">
            <h6 className="add-user-head">ALL ROUTES</h6>

            <i className="fa fa-times" onClick={props.closeRouteMap} />
          </div>

          {props.clientLatLng && props.baseLocation && (
            <div className="google-map-box">
              {props.routesInMap ? (
                // <GoogleMapComp
                //   centerLatLng={centerLatLng}
                //   clientsLocations={props.clientLatLng}
                //   baseLocation={props.baseLocation}
                //   pageType="AllRoutesInMap"
                // />
                <OpenStreenMap
                  centerLatLng={centerLatLng}
                  clientsLocations={props.clientLatLng}
                  baseLocation={props.baseLocation}
                  pageType="AllRoutesInMap"
                />
              ) : (
                <div className="iframe-part"></div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
