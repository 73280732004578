import React, { useEffect, useState } from "react";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { baseURL } from "../BaseUrl";
import { getRandomColor } from "../FormattedAmount";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import userImage from "../../images/DefaultImages/Default_User.jpg";
import { loggedInRole } from "../LoginRole";

function EmployeeCardView(props) {
  const [colors, setColors] = useState([]);

  useEffect(() => {
    if (props.users.length !== 0) {
      const newColors = { ...colors };
      let isUpdated = false;

      props.users.forEach((item) => {
        if (!newColors[item.userId]) {
          newColors[item.userId] = getRandomColor();
          isUpdated = true;
        }
      });

      if (isUpdated) {
        setColors(newColors);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.users]);

  return (
    <div className="sb-table-div sb-table-setion">
      {props.isLoading ? (
        <>
          <div className="card-view-div">
            <div className="card-display-row">
              <OnScrollLoading />
            </div>
          </div>
        </>
      ) : (
        <div
          className="card-view-div"
          onScroll={(e) =>
            handleScroll(
              e,
              props.users,
              props.totalUserCount,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <div className="card-display-row">
            {props.users &&
              props.users.map((user, i) => (
                <div key={i} className="card-container-body user-card-view">
                  <div className="card-container user-card-container">
                    <div className="emp-details-row">
                      <div className="empp-img-div">
                        {user?.profilePic ? (
                          <img
                            className="emp-profile"
                            src={`${baseURL}/${user?.profilePic}`}
                            alt="user-profile"
                          />
                        ) : (
                          // <div
                          //   className="emp-profile"
                          //   style={{
                          //     backgroundColor: colors[user.userId]
                          //   }}
                          // >
                          //   {user?.fullName.charAt(0).toUpperCase()}
                          // </div>
                          <img
                            className="emp-profile"
                            src={userImage}
                            alt="user-profile"
                          />
                        )}
                        <div
                          className={`dot ${
                            user?.enableStatus === 1 ? "active" : "inactive"
                          }`}
                        ></div>
                      </div>
                      <div className="emp-details-col">
                        <div className="pb-2">
                          <div className="card-header-text">
                            <strong>{user?.fullName}</strong>
                          </div>
                          <div className="table-row-sub-text color-yellow">
                            {user?.code}
                          </div>
                        </div>
                        <div className="">
                          <span className="color-yellow">
                            L{user?.desingation?.designationLevel}
                          </span>
                          -{" "}
                          <strong>{user?.desingation?.designationName}</strong>
                        </div>
                      </div>
                    </div>

                    <div className="emp-contact-div">
                      <div className="emp-category-div">
                        <div>
                          <h6 className="category-div-header">Department</h6>
                          <div className="">
                            {user?.desingation?.departmentName}
                          </div>
                        </div>
                        <div>
                          <h6 className="category-div-header">Reporting To</h6>
                          <div className="">
                            {user?.hasSubordinates > 0 ? (
                              <p className="subbordinate-link">
                                {user?.managerName}
                                <div
                                  to="#"
                                  onClick={() => {
                                    props.setManagerId(user?.userId);
                                    props.setManagerName(user?.fullName);
                                    props.goBacktoUser();
                                  }}
                                >
                                  <OverlayTrigger
                                    placement={"right"}
                                    delay={{ show: 200, hide: 250 }}
                                    overlay={
                                      <Tooltip id={`tooltip-title`}>
                                        View Subordinates
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      <i className="fa fa-users user-icon" />
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              </p>
                            ) : (
                              user?.managerName
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="emp-contact-details">
                        <div className="">
                          <i className="fa fa-phone"></i> {user?.countryCode}{" "}
                          &nbsp;{user?.contact}
                        </div>
                        <div className="">
                          {user?.email && (
                            <>
                              <i className="fa fa-envelope"></i> {user?.email}
                            </>
                          )}
                        </div>
                      </div>

                      <div className="emp-btn-section">
                        <span
                          onClick={() => {
                            props.setEditUserId(user?.userId);
                            props.userHistoryView();
                          }}
                        >
                          <i className="fa fa-eye" /> History
                        </span>
                        {loggedInRole === 1 && (
                          <span
                            onClick={() => {
                              props.showEditModel();
                              props.setEditUserId(user?.userId);
                            }}
                          >
                            <i className="fa fa-edit" /> Edit
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {props.loading && <OnScrollLoading />}
          </div>
        </div>
      )}
    </div>
  );
}

export default EmployeeCardView;
