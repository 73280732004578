import React, { useState, useEffect } from "react";
import { baseURL } from "../BaseUrl";
import * as swal from "../Consturl/SwalAlert";
import BulkUploadComp from "../ReUseComponents/BulkUploadComp";

function AddBulkProducts(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const salesbeeImgAuth = { Travelize_Authentication: userDetails };

  const [bulkUploadResponse, setBulkUploadResponse] = useState({
    res: null,
    message: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [uploadExcelFile, setUploadExcelFile] = useState("");

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadExcelFile(fileUploaded);
  };
  // console.log("EXCEL-FILE:", uploadExcelFile);

  useEffect(() => {
    setUploadExcelFile("");
    setBulkUploadResponse({ res: "", message: "" });
  }, [props.addBulkUserModel, props.handleCloseBulkUserModel]);

  const addBulkUser = () => {
    if (uploadExcelFile !== null || uploadExcelFile !== "") {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("Files", uploadExcelFile);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(
        `${baseURL}/api/Import/ImportProduct?Directory=BulkProductUploads`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("UPLOAD-FILE-RES::", res);
          if (res.success) {
            setBulkUploadResponse({ res: res.success, message: res.message });
            props.handleCloseBulkUserModel();
            setIsLoading(false);
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            setBulkUploadResponse({
              res: res.success,
              message: res.errorMessage,
            });
            setIsLoading(false);
          }
        });
    }
  };

  const closeModel = () => {
    props.handleCloseBulkUserModel();
  };

  return (
    <div>
      <BulkUploadComp
        pageFrom="Product"
        show={props.addBulkUserModel}
        closeModel={closeModel}
        heading="ADD BULK PRODUCTS"
        handleChange={handleChange}
        downloadTemplateUrl={"BulkProducts/Excel_20240222092853343.xlsx"}
        uploadClick={addBulkUser}
        bulkUploadResponse={bulkUploadResponse}
        isLoading={isLoading}
      />
    </div>
  );
}

export default AddBulkProducts;
