import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import * as HeaderData from "../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import { loadUserDropdown, updateComplaintStatus } from "../../redux/action";
import SbAddBtn, { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import moment from "moment";
import Select from "react-select";

export default function AssignToComplaint(props) {
  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);
  const { singleClientCompaint } = useSelector((state) => state.data);

  const [selectedUser, setSelectedUser] = useState({});

  const closeAssignModal = () => {
    props.setShowAssign(false);
    props.setCompaintId("");
    props.fetchComplaintdata();
  };

  const [updateData, setUpdateData] = useState({
    clientComplaintsID: 0,
    subscriptionID: "",
    userID: "",
    userFullName: "",
    addedByID: "",
    addedByUserFullName: "",
    addedOn: "",
    status: 0,
    clientIDFk: 0,
    date: "",
    productIDFK: 0,
    clientEmail: "",
    clientPhone: 0,
    issue: "",
    description: "",
    issueImage: "",
    inWarranty: 0,
    address: "",
    priority: 0,
    assingnedTo: "",
    adttenedByID: "",
    adttenedOn: "",
    solution: "",
    solutionImage: "",
  });

  useEffect(() => {
    if (singleClientCompaint) {
      setUpdateData(singleClientCompaint);
    }
    dispatch(loadUserDropdown());
  }, [singleClientCompaint]);

  useEffect(() => {
    if (singleClientCompaint) {
      userDropdown.map((drop) => {
        if (drop.value === singleClientCompaint.assingnedTo) {
          setSelectedUser(drop);
        }
      });
    }
  }, [singleClientCompaint, userDropdown]);

  const setUserToAssign = (data) => {
    setSelectedUser(data);
    setUpdateData({ ...updateData, assingnedTo: data.value });
  };

  const updateComplaints = (e) => {
    e.preventDefault();
    // let date = moment(new Date()).format("DD/MM/YYYY");
    // updateData.date = date;
    var postRequest = {
      method: "POST",
      mode: "cors",
      headers: HeaderData.postTravelizeAuth,
      body: JSON.stringify(updateData),
    };
    // console.log(updateData, "updateData")
    dispatch(updateComplaintStatus(postRequest, closeAssignModal, "assign"));
  };

  console.log(selectedUser, "updateData");

  return (
    <div>
      <div>
        <Modal
          show={props.showAssign}
          onHide={closeAssignModal}
          className="main_model add-edit-model"
        >
          <div className="add-edit-heading">
            <h6 className="add-edit-head">Re-Assign</h6>
            <i className="fa fa-times" onClick={closeAssignModal} />
          </div>

          <Form>
            <div className="container add-edit mt-3">
              <div className="mb-3">
                <Select
                  className="react-select-container-list model-select"
                  classNamePrefix="react-select-list"
                  options={userDropdown}
                  placeholder="Select User"
                  value={selectedUser.value ? selectedUser : userDropdown.value}
                  onInputChange={userDropdown.label}
                  onChange={(data) => setUserToAssign(data)}
                  isSearchable={true}
                  // styles={colourStyles}
                />
              </div>
            </div>
            <div className="btns-save-cancel">
              <SBSaveUpdateBtn
                onClickEffect={updateComplaints}
                btnName="Re-Assign"
              />
              <SbCancelBtn btnName="Cancel" onClickEffect={closeAssignModal} />
            </div>
          </Form>
        </Modal>
      </div>
    </div>
  );
}
