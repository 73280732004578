import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Footer from "../../Footer";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/VhdPODetails.scss";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { poDetailsTableData } from "../TestData";
import DataDisplayByColumn from "../DataDisplayByColumn";
import PODetailsTable from "./PODetailsTable";
import { MiCustomBtn } from "../../SbButtons/SbAddBtn";
import { loadPOLineItemDetails } from "../../../redux/action";
import { baseURL } from "../../BaseUrl";
import * as HeaderAuthentication from "../../../Components/HeaderAuthentication";
import AddEditFormComponent from "../../ReUseComponents/AddEditFormComponent";
import CreatePoToInvoice from "./CreatePoToInvoice";

export default function VhdPODetails() {
  const tableHeader = [
    { name: "Line No.", align: "left" },
    { name: "Item Name", align: "left" },
    { name: "Quantity", align: "left" },
    { name: "Discount %", align: "left" },
    { name: "Unit Rate", align: "left" },
    { name: "Line Total", align: "left" },
    { name: "Tax Code", align: "left" },
    { name: "Tax%", align: "left" },
    { name: "Tax Amount (₹)", align: "left" },
    { name: "Gross Total", align: "left" },
    { name: "UOM", align: "left" },
    { name: "Status", align: "center" },
  ];

  let dispatch = useDispatch();
  let { pId } = useParams();

  const { getAllPOLineItemDetails } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const [pOData, setPOData] = useState("");
  const [filterUserId, setFilterUserId] = useState("");
  const [activeStatus, setActiveStatus] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [file, setFile] = useState("");
  const [openInvoiceModel, setOpenInvoiceModel] = useState(false);

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const currentPage = 1;
  //* PAGINATION END *//

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const openCloseCreateInvoiceModel = () => {
    setOpenInvoiceModel(!openInvoiceModel);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchPOLineDetails();
  }, []);

  const fetchPOLineDetails = () => {
    dispatch(loadPOLineItemDetails(pId));
    fetch(
      `${baseURL}/api/VHD/GetALLPO?Po_id=${pId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        setPOData(resp.result[0]);
      })
      .catch((err) => {});
  };

  // FILE TTACHMENT
  const hiddenFileInput = useRef(null);

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setFile(result.toString().replace(/^data:(.*,)?/, ""));
      }
    });
  };

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };
  // FILE TTACHMENT

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setActiveStatus(1);
  };
  //* CLEAR SELECT END *//

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // Page navigation

  return (
    <div>
      {!openInvoiceModel ? (
        <>
          <>
            {/* SIDEBAR - TOPBAR - FOOTER  */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            <Topbar
              toggleMenu={toggleMenu}
              menuCollapse={menuCollapse}
              toggleshift={toggleshift}
            />
            <Footer />
            {/* SIDEBAR - TOPBAR - FOOTER  */}
          </>
          <>
            <div className="page-wrapper">
              <div className={`main-body ${toggleshift.style}`}>
                <div className="page-content">
                  {/* Filter and Header Section */}
                  <div className="po-details-part">
                    <Row>
                      <Col md={5} sm={12} className="cat-col">
                        <div className="page-header-text-div">
                          <div className="back-btn" onClick={goToSettingPage}>
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6 className="page-header-text">
                            Purchase Order List
                          </h6>
                        </div>
                      </Col>
                      <Col md={7} sm={12} className="cat-col">
                        <div className="dflex-j-end">
                          {/* <div className="update-po-status">
                        <MiCustomBtn
                          btnName={"Cancel PO"}
                          btnColor={"secondary"}
                          // onClickEffect={cancelPo}
                        />
                      </div> */}

                          {/* <div className="d-flex up-btn">
                        <MiCustomBtn
                          btnName={"Upload File"}
                          btnColor={"green"}
                          iconName={"fa fa-upload"}
                          onClickEffect={uploadClick}
                        />

                        <input
                          id="getFile"
                          type="file"
                          name="filetobase64"
                          accept="image/pdf"
                          className="btn-upload"
                          ref={hiddenFileInput}
                          onChange={handleChange}
                          style={{ display: "none" }}
                        />
                      </div> */}
                          <MiCustomBtn
                            btnName={"Create Invoice"}
                            btnColor={"primary"}
                            onClickEffect={openCloseCreateInvoiceModel}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="card po-details-card">
                      <Row>
                        <Col md={6} sm={12} className="br-gray">
                          <DataDisplayByColumn
                            dataHead={"Po.DocEntry"}
                            dataBody={pOData.po_Number}
                          />
                          <DataDisplayByColumn
                            dataHead={"Doc.Noc"}
                            dataBody={pOData.po_id}
                          />
                          <DataDisplayByColumn
                            dataHead={"Date"}
                            dataBody={pOData.po_date}
                          />
                          <DataDisplayByColumn
                            dataHead={"Vendor.Ref.No"}
                            dataBody={pOData.vendor_id}
                          />
                          <DataDisplayByColumn
                            dataHead={"Ship.To"}
                            dataBody={pOData.shipping_Address}
                          />
                        </Col>
                        <Col md={6} sm={12} className="bl-gray">
                          <DataDisplayByColumn
                            dataHead={"PO Value"}
                            dataBody={pOData?.pO_Value}
                          />
                          <DataDisplayByColumn
                            dataHead={"Tax Amount(₹)"}
                            dataBody={pOData?.tax_Amount}
                          />
                          <DataDisplayByColumn
                            dataHead={"Doc.Total"}
                            dataBody={pOData?.total_amount}
                          />
                          <DataDisplayByColumn
                            dataHead={"Branch"}
                            dataBody={pOData?.brnach ?? "-"}
                          />
                          <DataDisplayByColumn
                            dataHead={"Bill.To"}
                            dataBody={pOData?.billing_Address}
                          />
                        </Col>
                      </Row>
                    </div>
                    {/* TABLE START */}
                    <div className="po-details-table-part">
                      <PODetailsTable
                        tableHeader={tableHeader}
                        tableData={getAllPOLineItemDetails}
                      />
                    </div>
                  </div>
                  {/* TABLE END */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>

              {/* MAIN BODY END */}
            </div>
          </>
        </>
      ) : (
        <CreatePoToInvoice
          handleClose={openCloseCreateInvoiceModel}
          poLineItems={getAllPOLineItemDetails}
          pOData={pOData}
        />
      )}
    </div>
  );
}
