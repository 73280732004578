import React, { useEffect, useState } from "react";
import travelizeSalesBeeLogo from "../../images/MatricLogo.svg";
import "../../styles/SignIn.scss";
import "../../styles/CommonStyle.scss";
// import "../../MediaFiles/Media.scss";
import { Link } from "react-router-dom";
import travelize from "../../images/metric-favicon.svg";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import { baseURL } from "../BaseUrl";
import { Button, Form, Image, Row, Col, Carousel } from "react-bootstrap"; // Import Row and Col from React Bootstrap
import { NumberValidation } from "../ValidationFormat";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCountryCodes,
  loadReferredByDropdowndata,
} from "../../redux/action";
import { toast } from "react-toastify";
import SignInGifImag from "../../SignInGifImag";
import CryptoJS from "crypto-js";

const Signup = () => {
  let themeColor = localStorage.getItem("theme");
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const { getReferredByDropdownData } = useSelector((state) => state.data);
  const { countryCodes } = useSelector((state) => state.data);

  const [companyName, setCompanyName] = useState("");
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState({
    label: "+91",
    value: "+91",
  });
  const [contactNo, setContactNo] = useState(null);

  const [referredBy, setReferredBy] = useState({
    value: "",
    label: "",
  });
  const [captchaVerification, setCaptchaVerification] = useState(false);

  const location = useLocation();
  const [locationPathNames, setLocationPathNames] = useState([]);
  const [showReferredByDropdown, setShowReferredByDropdown] = useState(true);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    dispatch(loadReferredByDropdowndata());
    dispatch(loadCountryCodes());
  }, []);

  useEffect(() => {
    const searchParams = window.location.hash.includes("/Trial?");
    setLocationPathNames(location.pathname.split("/"));
    if (searchParams) {
      setShowReferredByDropdown(false);
    } else {
      setShowReferredByDropdown(true);
    }
  }, [location]);

  const getUrlParameter = () => {
    const encryptedParam = window.location.hash.split("?")[1];
    if (encryptedParam) {
      try {
        // Decrypt the encrypted parameter value
        const bytes = CryptoJS.AES.decrypt(encryptedParam, "yourSecretKey123");
        const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
        // console.log("Decrypted Value:", decryptedValue);

        // Extract the 'name' and 'email' parameters from the decrypted value
        const paramsArray = decryptedValue.split("&");
        let nameValue = null;
        let emailValue = null;
        paramsArray.forEach((param) => {
          const [key, value] = param.split("=");
          if (key === "name") {
            nameValue = decodeURIComponent(value);
          } else if (key === "email") {
            emailValue = decodeURIComponent(value);
          }
        });
        // setReferredBy("Email");
        // Return the 'name' and 'email' parameter values
        return { name: nameValue, email: emailValue };
      } catch (error) {
        console.error("Decryption Error:", error);
        return null;
      }
    }
    return { name: null, email: null };
  };

  const { name: nameFromUrl, email: emailFromUrl } = getUrlParameter();

  const pathname = location.pathname;

  // Define placeholder text based on the pathname
  let placeholderText = "";
  switch (pathname) {
    case "/Trial":
      placeholderText = "Source";
      break;
    case "/Trial/BDE":
      placeholderText = "Referred By";
      break;
    // Add more cases for other pathnames
    default:
      placeholderText = "Source";
  }

  useEffect(() => {
    // Update dropdown options based on the pathname
    const pathname = location.pathname;

    const trialOptions = [
      { value: "Website", label: "Website" },
      { value: "Email", label: "Email" },
      { value: "BusinessTeam", label: "Business Team" },
      { value: "SocialMedia", label: "Social Media" },
    ];

    switch (pathname) {
      case "/signup":
        setOptions(trialOptions);
        break;
      case "/Trial/BDE":
        // Use options from the state data
        setOptions(getReferredByDropdownData);
        break;
      // Add more cases for other pathnames
      default:
        // Use the default options
        setOptions(trialOptions);
    }
  }, [location.pathname, getReferredByDropdownData]);

  let currentYear = new Date().getFullYear();

  const onChange = (value) => {
    if (value !== "" && value !== null) {
      setCaptchaVerification(true);
    }
  };

  const handleReferredByChange = (selectedOption) => {
    setReferredBy(selectedOption.label);
  };

  const createSubscription = (e) => {
    e.preventDefault();
    if (captchaVerification) {
      if (email && companyName && contactNo && countryCode) {
        const subscriptionData = {
          subscriptionTypeID: 0,
          companyName: companyName,
          clientName: clientName,
          email:
            emailFromUrl !== null
              ? email + (emailFromUrl && "," + emailFromUrl)
              : email,
          countryCode: countryCode.value,
          contact: contactNo,
          createdByUserName: nameFromUrl || referredBy,
          password: `MetricInfo_${currentYear}`, // Password for New Subscriptions
        };
        let requestoption = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(subscriptionData),
        };
        fetch(`${baseURL}/api/Subscription/AddSubscription`, requestoption)
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              toast.success("Your Subscription has Created Successfully");
              // console.log(locationPathNames, "locationPathNames");
              if (locationPathNames.includes("BDE")) {
                const userNamePassword = {
                  UserName: email,
                  Password: `MetricInfo_${currentYear}`,
                };
                let requestoption = {
                  method: "POST",
                  mode: "cors",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(userNamePassword),
                };
                fetch(`${baseURL}/api/Authentication/Login`, requestoption)
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.success) {
                      sessionStorage.setItem(
                        "usdtls",
                        JSON.stringify(res.result)
                      );

                      //// CHECK SUBSCRIPTION EXPIRY
                      let requestOptionS = {
                        method: "GET",
                        mode: "cors",
                        headers: {
                          "Content-Type": "application/json",
                          Accept: "application/json",
                          Travelize_Authentication: res.result,
                        },
                      };
                      fetch(
                        `${baseURL}/api/DashBoard/GetLoginUserDetails`,
                        requestOptionS
                      )
                        .then((res) => res.json())
                        .then((res) => {
                          const userLoginDetails = res.result;
                          // navigate("/signupSubscription", {
                          //   state: userLoginDetails,
                          // });
                          window.location.reload();
                        })
                        .catch((error) => {
                          console.log("ERROR :", error);
                        });
                    } else {
                      console.log(res, "res.errorMessage");
                    }
                  })
                  .catch((error) => {
                    console.log("ERROR :", error);
                  });
              } else {
                resetAll();
              }
            } else {
              toast.error(res.errorMessage);
            }
          });
      } else {
        toast.error("Please fill all the fields");
      }
    } else {
      toast.error("Captcha is Required");
    }
  };

  const resetAll = () => {
    setTimeout(() => 5000);
    window.location.reload();
  };

  const [trailFlag, setTrailFlag] = useState(false);

  useEffect(() => {
    let url = window.location.href;
    if (url.includes("Trail")) {
      setTrailFlag(true);
    }
  }, [window.location.href]);

  return (
    <div>
      <div className="container-fluid">
        <div className="sigin-page-body">
          {/* <Row> */}
          <div className="dflex-flow-row">
            <Col lg={5} md={5} sm={12} className="signIn-container-left-div">
              <SignInGifImag />
            </Col>
            <Col lg={2} md={2} sm={12}>
              <div className="devider">
                <div className="v1"></div>
              </div>
            </Col>
            <Col lg={5} md={5} sm={12} className="signIn-container-right-div">
              <div className="metricinfo-logo">
                <Image
                  src={travelize}
                  alt="MetricInfo Logo"
                  className="sb-trv-logo"
                />
              </div>
              <div className="signin-header signup-part">
                <div className="signup-header">
                  <h2>Sign Up</h2>
                  <div className="form-title">
                    Please fill in this form to create an account!
                  </div>
                </div>
                <Form onSubmit={createSubscription} className="login-form">
                  <div className="form-group">
                    <div className="icon-with-input before">
                      <i className="fa fa-building" />
                      <input
                        type="text"
                        className="form-control signIn-form-control"
                        placeholder="Company Name"
                        onChange={(e) => setCompanyName(e.target.value)}
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt3">
                    <div className="icon-with-input before">
                      <i className="fa fa-id-card" />
                      <input
                        type="text"
                        className="form-control signIn-form-control"
                        placeholder="Contact Person"
                        onChange={(e) => setClientName(e.target.value)}
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt3">
                    <div className="icon-with-input before">
                      <i className="fa fa-envelope"></i>
                      <input
                        type="email"
                        className="form-control signIn-form-control"
                        placeholder="Enter your email"
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt3">
                    <div className="contact-input hv-center">
                      <div className="signup-contry-codes-div">
                        <Select
                          name="countrycode"
                          className="cntry-code"
                          classNamePrefix="react-select-list"
                          defaultValue={countryCodes[0]}
                          options={countryCodes}
                          placeholder="Code"
                          value={countryCode}
                          onInputChange={countryCodes.label}
                          onChange={(data) => setCountryCode(data)}
                          isSearchable={true}
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div
                        style={{ width: "100%", marginLeft: "10px" }}
                        className="icon-with-input before"
                      >
                        <i className="fa fa-phone" />
                        <input
                          type="tel"
                          className="form-control signIn-form-control"
                          placeholder="Mobile Number"
                          maxLength={countryCode.value == "+91" ? 10 : 15}
                          minLength={countryCode.value == "+91" ? 10 : 5}
                          value={contactNo}
                          onChange={(e) => setContactNo(e.target.value)}
                          onKeyDown={NumberValidation}
                          required
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt3" style={{ width: "100%" }}>
                    {showReferredByDropdown && (
                      <div className="icon-with-select sbefore signup-contry-codes-div">
                        <i className="fa fa-globe" />
                        <Select
                          className="source-creation m-0"
                          classNamePrefix="react-select-list"
                          placeholder={placeholderText}
                          options={options}
                          value={referredBy.value && referredBy}
                          onChange={handleReferredByChange}
                          isSearchable={true}
                          autoComplete="off"
                        />
                      </div>
                    )}
                  </div>
                  <div className="recaptcha-part">
                    <ReCAPTCHA
                      sitekey="6LcC984nAAAAAA3eYGW7xlxb12ooQRUCu1TkFHum"
                      className="recaptch-modal-kit"
                      onChange={onChange}
                      size={"normal"}
                    />
                  </div>
                  <div className="signin-button mt10 hv-center">
                    <Button
                      type="submit"
                      className="btn btn-signin-button signup"
                    >
                      Sign up
                    </Button>
                  </div>
                  <div className="back-to-signin mt-1">
                    Do you have an account ?&nbsp;
                    <Link to="/" className="color-green">
                      <b>Sign In</b>
                    </Link>
                  </div>
                </Form>
              </div>
            </Col>
          </div>
          {/* </Row> */}
        </div>
      </div>
    </div>
  );
};

export default Signup;
