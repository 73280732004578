import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  OverLayData,
  truncateString,
} from "../../ReUseFunctions/ReuseFunctions";
import MappedUsers from "./MappedUsers";
import { handleScroll } from "../../OnScrollLoading";

export default function VendorTable(props) {
  const [showModel, setShowModel] = useState(false);
  const [mainapperUserData, setMapperUserData] = useState("");

  const handleClose = () => {
    setShowModel(!showModel);
  };

  return (
    <div>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer
            onScroll={(e) =>
              handleScroll(
                e,
                props.tableData,
                props.totalData,
                props.setLoading,
                props.setPageSize,
                props.PageSize
              )
            }
          >
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="vendor-table"
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {props.tableHeader.map((data, i) => (
                    <TableCell key={i} align={data?.align} sx={data.width}>
                      {data?.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tableData.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.vendor_id}
                    </TableCell>
                    <TableCell
                      sx={props.setWidthToMaxContent}
                      className="color-green"
                    >
                      <strong>{data?.vendor_Name}</strong>
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      <div>
                        <b>Name : </b>
                        <span>{data?.contact_Person}</span>
                      </div>
                      <div>
                        <b>Email : </b>
                        <span className="color-yellow">
                          {data?.vendor_Email}
                        </span>
                      </div>
                      <div>
                        <b> Mobile : </b>{" "}
                        <span className="color-green">
                          {data?.mobile_Number}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.country}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.state}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.city}
                    </TableCell>
                    <TableCell style={{ minWidth: "150px" }}>
                      <div>
                        {data?.location
                          ? truncateString(data?.location, 30)
                          : "NA"}{" "}
                        &nbsp;
                        <OverLayData data={data?.location} />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        className="reporting-to-users color-green cursor-pointer"
                        onClick={() => {
                          handleClose();
                          setMapperUserData(data?.mapped_To);
                        }}
                      >
                        <i className="fa fa-users" />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className={`${data?.vendor_Status}-status`}>
                        <span className="status-text">
                          {data?.vendor_Status}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="edit-view-link">
                        <div
                          className="edit-icon-text"
                          onClick={() => {
                            props.openCloseAddEditModel();
                            props.setVendorID(data.vendor_id);
                          }}
                        >
                          <span className="hv-center">
                            <i className="fa fa-edit" />
                            Edit
                          </span>
                        </div>
                        {/* <div className="view-icon-text">
                          <span className="hv-center">
                            <i className="fa fa-eye" />
                            View
                          </span>
                        </div> */}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      {showModel && (
        <MappedUsers
          show={showModel}
          handleClose={handleClose}
          users={mainapperUserData}
        />
      )}
    </div>
  );
}
