import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/Users.scss";
import "../../styles/CommonStyle.scss";
import { Col, Row } from "react-bootstrap";
import ViewSubordinates from "./ViewSubbordinates";
import { baseURL } from "../BaseUrl";
import UserHistory from "./UserHistory";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsStatusList } from "../DropdownsData";
import InternetIssue from "../InternetIssue";
import {
  loadUserDropdown,
  loadDesignationDrop,
  loadDepartmentDrop,
  loadRolesDropDown,
  loadPageBreadCome,
  loadStateList,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import SbDropDownBtn from "../SbButtons/SbDropDownBtn";
import { MiClearButton } from "../SbButtons/SbCancelBtn";
import UserTable from "./UserTable";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import AddUsersData from "./AddUsersData";
import UpdateUserData from "./UpdateUserData";
import AddUserBulkData from "./AddUserBulkData";
import EmployeeCardView from "./EmployeeCardView";
import { travelizeAuth } from "../HeaderAuthentication";
import { loggedInRole } from "../LoginRole";
import ProgressBar from "../Dashboard/ProgressBar";
import DownloadLoading from "../ReUseComponents/DownloadLoading";

function Users() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  /* HEADER AUTH END */

  let tableHeader = [
    { name: "NAME", align: "left", width: "200px" },
    { name: "CONTACT DETAILS", align: "left", width: "150px" },
    { name: "DESIGNATION", align: "left", width: "150px" },
    // { name: "ROLE", align: "left", width: "120px" },
    { name: "DEPARTMENT", align: "left", width: "150px" },
    { name: "REPORTING", align: "left", width: "180px" },
    { name: "STATUS", align: "center", width: "100px" },
    { name: "ACTION", align: "center", width: "150px" },
  ];

  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);
  const { desigationdrop } = useSelector((state) => state.data);
  const { departmentDrop } = useSelector((state) => state.data);
  const { rolesDropdown } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [addusermodelshow, setAddusermodelshow] = useState(false);
  const [addBulkUserModel, setAddBulkUserModel] = useState(false);
  const [editusermodelshow, setEditusermodelshow] = useState(false);
  const [users, setUsers] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [editUserId, setEditUserId] = useState("");
  const [activeUserCount, setActiveUserCount] = useState("");
  const [activeStatus, setActiveStatus] = useState(1);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [totalUsers, setTotalUsers] = useState("");
  const [managerId, setManagerId] = useState("");
  const [managerName, setManagerName] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [subordinateActive, setSubordinateActive] = useState(false);
  const [userHistoryPage, setUserHistoryPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [reportDesignation, setReportDesignation] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [holidayStateID, setHolidayStateID] = useState(0);
  const [loading, setLoading] = useState(false);

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const currentPage = 1;
  //* PAGINATION END *//

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div
  const [pageGridView, setPageGridView] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const goBacktoUser = () => {
    setSubordinateActive(!subordinateActive);
  };

  const userHistoryView = () => {
    setUserHistoryPage(!userHistoryPage);
  };

  const handleClose = () => {
    setAddusermodelshow(!addusermodelshow);
  };

  const showEditModel = () => {
    setEditusermodelshow(!editusermodelshow);
  };

  const handleCloseBulkUserModel = () => {
    setAddBulkUserModel(!addBulkUserModel);
  };

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    window.scrollTo(0, 0);
    let breadCome = { mainpage: "Users" };
    dispatch(loadPageBreadCome(breadCome));
    dispatch(loadUserDropdown());
    dispatch(loadDesignationDrop());
    dispatch(loadDepartmentDrop());
    dispatch(loadRolesDropDown());
    dispatch(loadStateList(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //* FETCH DROPDOWN DATA END *//

  //* GET USER DETAIL START  *//
  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    PageSize,
    currentPage,
    filterUserId,
    reportDesignation,
    departmentId,
    roleId,
    activeStatus,
    searchValue,
    holidayStateID,
  ]);

  const getUserDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/User/UserDetails?userId=${filterUserId}&ManagerID=${managerId}&DesignationID=${reportDesignation}&DepartmentID=${departmentId}&RoleID=${roleId}&Holiday_List_State=${holidayStateID}&EnableStatus=${activeStatus}&pageSize=${PageSize}&currentPage=${currentPage}&Text=${searchValue}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setTotalUsers(res.result[0].subscription.maxUserCount);
          setUsers(res.result);
          setTotalUserCount(res.recordCount[0]);
          setActiveUserCount(res.recordCount[1]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setUsers("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* GET USER DETAIL END *//

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setManagerId("");
    setReportDesignation(0);
    setDepartmentId(0);
    setRoleId(0);
    setActiveStatus(1);
    setHolidayStateID(0);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportUserDetails = () => {
    exportExcel(
      `${baseURL}/api/Export/UserExcelExport?userId=${filterUserId}&ManagerID=${managerId}&DesignationID=${reportDesignation}&DepartmentID=${departmentId}&RoleID=${roleId}&enableStatus=${activeStatus}&pageSize=${PageSize}&currentPage=${currentPage}`,
      "Users.xlsx",
      setDownloadIsLoading
    );
  };
  //* EXPORT FILE END *//

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // Page navigation

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {!addusermodelshow && !editusermodelshow && !addBulkUserModel && (
            <>
              <div className="top-and-sidebar">
                <Sidenavbar
                  menuCollapse={menuCollapse}
                  toggleshift={toggleshift}
                />

                <Topbar
                  toggleMenu={toggleMenu}
                  menuCollapse={menuCollapse}
                  toggleshift={toggleshift}
                />
              </div>
              {/* MAIN BODY START */}

              {/* PAGE CONTENT START */}
              <div className="page-wrapper">
                {!subordinateActive && !userHistoryPage ? (
                  <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                      {/* Filter and Header Section */}
                      <div className="user-table">
                        <Row className="search-row">
                          <Col md={4} sm={4} className="cat-col">
                            <div className="page-header-text-div">
                              <div
                                className="back-btn"
                                onClick={goToSettingPage}
                              >
                                <img
                                  src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                  alt=""
                                />
                              </div>
                              <h6 className="page-header-text">
                                USERS / EMPLOYEE ({activeUserCount} /{" "}
                                {totalUsers})
                              </h6>
                            </div>
                          </Col>
                          <Col md={5} sm={4} className="cat-col">
                            <div className="input-div">
                              <input
                                type="search"
                                id="metricInfo_search_bar"
                                placeholder="Mobile / Email"
                                className="form-control setting-input"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                              />
                              <i className="fa fa-search search-icon" />
                            </div>
                          </Col>
                          <Col md={3} sm={4} className="cat-col">
                            <div
                              className="search-bar-header"
                              style={{ float: "right" }}
                            >
                              <div className="page-top-bar-dropdowns page-top-Filters">
                                <div
                                  className="header-filter-btn"
                                  onClick={() => setPageGridView(!pageGridView)}
                                >
                                  <div className="grid-tile-view">
                                    {pageGridView ? (
                                      <i className="fa fa-th" />
                                    ) : (
                                      <i className="fa fa-th-list" />
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="header-filter-btn"
                                  onClick={() => setFilter(!filter)}
                                >
                                  <div className="filter-icon-text">
                                    <i className="bi bi-sliders" />
                                    <span>Filters</span>
                                  </div>
                                </div>
                                {loggedInRole === 1 && (
                                  <SbDropDownBtn
                                    openAddModal={handleClose}
                                    pageName="User"
                                    addBulkModel={handleCloseBulkUserModel}
                                  />
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        {filter && (
                          <div className="page-filter-section">
                            <div className="filter-row-input-boxes">
                              <div>
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={userDropdown}
                                  placeholder="Select User"
                                  value={
                                    filterUserId === ""
                                      ? ""
                                      : userDropdown.value
                                  }
                                  onInputChange={userDropdown.label}
                                  onChange={(data) =>
                                    setFilterUserId(data.value)
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div>
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  defaultValue={desigationdrop[null]}
                                  options={desigationdrop}
                                  placeholder="Designation"
                                  value={
                                    reportDesignation === 0
                                      ? ""
                                      : desigationdrop.value
                                  }
                                  onInputChange={desigationdrop.label}
                                  onChange={(data) =>
                                    setReportDesignation(data.value)
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div>
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={departmentDrop}
                                  placeholder="Department"
                                  value={
                                    departmentId === 0
                                      ? ""
                                      : departmentDrop.value
                                  }
                                  onInputChange={departmentDrop.label}
                                  onChange={(data) =>
                                    setDepartmentId(data.value)
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div>
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={rolesDropdown}
                                  placeholder="Select Role"
                                  value={
                                    roleId === 0 ? "" : rolesDropdown.value
                                  }
                                  onInputChange={rolesDropdown.label}
                                  onChange={(data) => setRoleId(data.value)}
                                  isSearchable={true}
                                />
                              </div>

                              <div>
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={optionsStatusList}
                                  placeholder="Select Status"
                                  value={
                                    activeStatus === 2
                                      ? ""
                                      : activeStatus
                                      ? activeStatus
                                      : optionsStatusList.value
                                  }
                                  onInputChange={optionsStatusList.label}
                                  onChange={(data) =>
                                    setActiveStatus(data.value)
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <MiClearButton
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {/* Filter and Header Section */}

                      {/* USER TABLE START */}
                      {pageGridView ? (
                        <UserTable
                          tableHeader={tableHeader}
                          users={users}
                          isLoading={isLoading}
                          totalUserCount={totalUserCount}
                          loading={loading}
                          setPageSize={setPageSize}
                          PageSize={PageSize}
                          setLoading={setLoading}
                          setManagerId={setManagerId}
                          setManagerName={setManagerName}
                          goBacktoUser={goBacktoUser}
                          showEditModel={showEditModel}
                          setEditUserId={setEditUserId}
                          userHistoryView={userHistoryView}
                        />
                      ) : (
                        <EmployeeCardView
                          users={users}
                          isLoading={isLoading}
                          totalUserCount={totalUserCount}
                          loading={loading}
                          setPageSize={setPageSize}
                          PageSize={PageSize}
                          setLoading={setLoading}
                          setManagerId={setManagerId}
                          setManagerName={setManagerName}
                          goBacktoUser={goBacktoUser}
                          showEditModel={showEditModel}
                          setEditUserId={setEditUserId}
                          userHistoryView={userHistoryView}
                        />
                      )}

                      <div className="user-table">
                        <div className="download-and-pagination">
                          <SbDownloadBtn
                            btnName="Download"
                            onClickEffect={exportUserDetails}
                          />
                        </div>
                      </div>
                      {/* USER TABLE END */}

                      {/* PAGE CONTENT END*/}
                    </div>
                  </div>
                ) : (
                  <>
                    {subordinateActive ? (
                      <ViewSubordinates
                        roleId={roleId}
                        managerId={managerId}
                        managerName={managerName}
                        toggleshift={toggleshift}
                        goBacktoUser={goBacktoUser}
                        subordinateActive={subordinateActive}
                      />
                    ) : (
                      <>
                        {userHistoryPage ? (
                          <UserHistory
                            userHistoryView={userHistoryView}
                            toggleshift={toggleshift}
                            userId={editUserId}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </>
                )}
                <></>
                {/* MAIN BODY END */}
              </div>
              <Footer />
            </>
          )}

          {downloadIsLoading && (
            <>
              <DownloadLoading />
            </>
          )}

          {addusermodelshow && (
            <AddUsersData
              getUserDetails={getUserDetails}
              addusermodelshow={addusermodelshow}
              handleClose={handleClose}
            />
          )}
          {editusermodelshow && (
            <UpdateUserData
              userID={editUserId}
              getUserDetails={getUserDetails}
              editusermodelshow={editusermodelshow}
              showEditModel={showEditModel}
            />
          )}
          {addBulkUserModel && (
            <AddUserBulkData
              addBulkUserModel={addBulkUserModel}
              handleCloseBulkUserModel={handleCloseBulkUserModel}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Users;
