import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Claims.scss";
import { Col, Row } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import { MiClearButton } from "../SbButtons/SbCancelBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import AccessDenied from "../../AccessDenied";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import Footer from "../Footer";
import { travelizeAuth } from "../HeaderAuthentication";
import { loggedInId } from "../LoginRole";
import {
  loadManagerDropdown,
  loadUserDropdownByManager,
} from "../../redux/action";
import DownloadLoading from "../ReUseComponents/DownloadLoading";

export default function ClaimSummary() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  let dispatch = useDispatch();
  const { managerDropdown } = useSelector((state) => state.data);
  const { userDropdownByManager } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [claimSummaryDetails, setClaimSummaryDetails] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [managerID, setManagerID] = useState({
    value: loggedInId,
    label: "Select Manager",
  });
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadManagerDropdown());
    dispatch(loadUserDropdownByManager(managerID.value));
  }, [managerID]);

  // GET CLAIM DETAILS START
  useEffect(() => {
    {
      fromDate && toDate && fetchclaimSummaryDetails();
    }
  }, [filterUserId, managerID, fromDate, toDate, currentPage, PageSize]);

  const fetchclaimSummaryDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Claim/GetClaimSummary?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${filterUserId}&ManagerID=${
        managerID.value
      }&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setClaimSummaryDetails(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setClaimSummaryDetails("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };
  // GET CLAIM DETAILS END

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setManagerID({
      value: loggedInId,
      label: "Select Manager",
    });
    setFromDate(new Date());
    setToDate(new Date());
  };

  const exportExcelClaim = () => {
    exportExcel(
      `${baseURL}/api/Export/ClaimSummaryExcelExport?UserID=${filterUserId}&ManagerID=${
        managerID.value
      }&FromDate=${moment(fromDate).format("DD/MM/YYYY")}&ToDate=${moment(
        toDate
      ).format("DD/MM/YYYY")}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Claim_Summary.xlsx",
      setDownloadIsLoading
    );
  };

  return (
    <div>
      <div id="main-page">
        {userDetails !== null && userDetails !== "" ? (
          <>
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />

            {/* TOPBAR START */}
            <Topbar
              toggleMenu={toggleMenu}
              menuCollapse={menuCollapse}
              toggleshift={toggleshift}
            />
            {/* TOPBAR END */}

            {/* MAIN BODY START */}
            <div className="page-wrapper">
              {/* PAGE CONTENT START */}

              <div className={`main-body ${toggleshift.style}`}>
                <div className="page-content">
                  {/* Filter and Header Section */}
                  <div className="user-table">
                    <Row className="page-filter-section">
                      <Col md={3} className="cat-col">
                        <div className="page-header-text-div">
                          <h6 className="page-header-text">CLAIMS SUMMARY</h6>
                        </div>
                      </Col>
                      <Col md={9} className="cat-col">
                        <div className="filter-row-input-boxes-end">
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={managerDropdown}
                              placeholder="Select Manager"
                              value={managerID}
                              onInputChange={managerDropdown.label}
                              onChange={(data) => setManagerID(data)}
                              isSearchable={true}
                            />
                          </div>
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={userDropdownByManager}
                              placeholder="Select User"
                              value={
                                filterUserId === ""
                                  ? ""
                                  : userDropdownByManager.value
                              }
                              onInputChange={userDropdownByManager.label}
                              onChange={(data) => setFilterUserId(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <div className="from-to-date-select">
                            <div className="input-group-text" id="from-label">
                              From - To
                            </div>
                            <div className="date-picker-with-icon">
                              <DatePicker
                                selected={fromDate}
                                className="select from-date date-filter"
                                id="date-range-picker"
                                dateFormat="dd/MM/yyyy"
                                selectsRange
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={(date) => setSelectSearchDrop(date)}
                                maxDate={new Date()}
                              />
                              <div>
                                <i
                                  className="fa fa-calendar-o calander-icon"
                                  style={{ right: "6px" }}
                                />
                              </div>
                            </div>
                          </div>
                          <MiClearButton
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* Filter and Header Section */}

                  {/* USER TABLE START */}
                  <div className="claims-table">
                    <div
                      className="table-responsive claims-summary-scrollbar"
                      onScroll={(e) =>
                        handleScroll(
                          e,
                          claimSummaryDetails,
                          totalData,
                          setLoading,
                          setPageSize,
                          PageSize
                        )
                      }
                    >
                      <table className="table claims-manager-table">
                        <thead className="thead-light claims-manager-thead">
                          <tr>
                            <th>USER DETAILS</th>
                            <th>
                              LOCAL CLAIM
                              <div className="approved-claim-manager">
                                Manager
                              </div>
                            </th>
                            <th>
                              OS CLAIM
                              <div className="approved-claim-manager">
                                Manager
                              </div>
                            </th>
                            <th>
                              LOCAL CLAIM
                              <div className="approved-claim-manager">
                                Admin
                              </div>
                            </th>
                            <th>
                              OS CLAIM
                              <div className="approved-claim-manager">
                                Admin
                              </div>
                            </th>
                            <th>BY MANAGER</th>
                            <th>BY ADMIN</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                            </>
                          ) : claimSummaryDetails !== "" ? (
                            claimSummaryDetails?.map((data, i) => (
                              <tr className="claims-list-row-data" key={i}>
                                <td className="br-1">
                                  <strong> {data?.FullName} </strong>
                                  <br />
                                  <span>{data?.Email}</span>
                                  <br />
                                  <span>
                                    {data?.CountryCode} {data?.Contact}
                                  </span>
                                </td>
                                <td className="summary-data">
                                  <p>
                                    <span>Applied -</span>
                                    <span>
                                      <strong>
                                        {data?.LocalAppliedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Pending -</span>
                                    <span>
                                      <strong>
                                        {data?.LocalManagerPendingClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Rejected -</span>
                                    <span>
                                      <strong>
                                        {data?.LocalManagerRejectedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span> Approved -</span>
                                    <span>
                                      <strong>
                                        {data?.LocalManagerApprovedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                </td>
                                <td className="summary-data">
                                  <p>
                                    <span>Applied -</span>
                                    <span>
                                      <strong>
                                        {data?.OSAppliedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Pending -</span>
                                    <span>
                                      <strong>
                                        {data?.OSManagerPendingClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Rejected -</span>
                                    <span>
                                      <strong>
                                        {data?.OSManagerRejectedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Approved -</span>
                                    <span>
                                      <strong>
                                        {data?.OSManagerApprovedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                </td>
                                <td className="summary-data">
                                  <p>
                                    <span>Applied -</span>
                                    <span>
                                      <strong>
                                        {data?.LocalAppliedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span> Pending -</span>
                                    <span>
                                      <strong>
                                        {data?.LocalAdminPendingClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span> Rejected -</span>
                                    <span>
                                      <strong>
                                        {data?.LocalAdminRejectedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Approved -</span>
                                    <span>
                                      <strong>
                                        {data?.LocalAdminApprovedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                </td>
                                <td className="summary-data">
                                  <p>
                                    <span>Applied -</span>
                                    <span>
                                      <strong>
                                        {data?.OSAppliedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Pending -</span>
                                    <span>
                                      <strong>
                                        {data?.OSAdminPendingClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Rejected -</span>
                                    <span>
                                      <strong>
                                        {data?.OSAdminRejectedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Approved -</span>
                                    <span>
                                      <strong>
                                        {data?.OSAdminApprovedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                </td>
                                <td className="summary-data">
                                  <p>
                                    <span> Total Applied -</span>
                                    <span>
                                      <strong>
                                        {data?.TotalAppliedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Total Pending -</span>
                                    <span>
                                      <strong>
                                        {data?.TotalManagerPendingClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Total Rejected -</span>
                                    <span>
                                      <strong>
                                        {data?.TotalManagerRejectedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Total Approved -</span>
                                    <span>
                                      <strong>
                                        {data?.TotalManagerApprovedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                </td>
                                <td className="summary-data">
                                  <p>
                                    <span>Total Applied -</span>
                                    <span>
                                      <strong>
                                        {" "}
                                        {data?.TotalAppliedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Total Pending -</span>
                                    <span>
                                      <strong>
                                        {data?.TotalAdminPendingClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Total Rejected -</span>
                                    <span>
                                      <strong>
                                        {data?.TotalAdminRejectedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Total Approved -</span>
                                    <span>
                                      <strong>
                                        {data?.TotalAdminApprovedClaimValue}
                                      </strong>
                                    </span>
                                  </p>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7} className="text-center">
                                ----No Data Found----
                              </td>
                            </tr>
                          )}
                          {loading && <OnScrollLoading />}
                        </tbody>
                      </table>
                    </div>
                    <div className="download-and-pagination">
                      <SbDownloadBtn onClickEffect={exportExcelClaim} />
                    </div>
                  </div>
                  {/* USER TABLE END */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>
            </div>
            <Footer />
            {downloadIsLoading && (
              <>
                <DownloadLoading />
              </>
            )}
          </>
        ) : (
          <AccessDenied />
        )}
      </div>
    </div>
  );
}
