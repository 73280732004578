import React, { useState, useEffect } from "react";
import "../../styles/Attendance.scss";
import profileimg from "../../images/profile-1.png";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ViewClaimAttachmentmodel from "./ViewClaimAttachmentmodel";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import {
  SkeletonPendingClaimHead,
  SkeletonPendingTableData,
} from "../Skeleton Loading/SkeletonPendingClaim";
import SkeletonApproved from "../Skeleton Loading/SkeletonApproved";
import Accordion from "react-bootstrap/Accordion";
import { Col, Row } from "react-bootstrap";
import SbCancelBtn, { MiClearButton } from "../SbButtons/SbCancelBtn";
import { SbDownloadBtn, SbUserwiseReportBtn } from "../SbButtons/SbAddBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { travelizeAuth } from "../HeaderAuthentication";
import DownloadLoading from "../ReUseComponents/DownloadLoading";

function ApprovedClaimbyManager(props) {
  const [fromDate, setFromDate] = useState(new Date(props.fromDate));
  const [toDate, setToDate] = useState(new Date(props.toDate));
  const [approvedClaimDeatils, setApprovedClaimDeatils] = useState([]);
  const [attachmentUrl, setAttachmentUrl] = useState([]);
  const [claimAttachmentmodel, setClaimAttachmentmodel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const handleCloseviewattachment = () => setClaimAttachmentmodel(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  useEffect(() => {
    {
      fromDate && toDate && fetchApprovedClaimData();
    }
  }, [fromDate, toDate, PageSize, currentPage]);

  // GET MANAGER APPROVED FOR APPROVAL CLAIM DETAILS START
  const fetchApprovedClaimData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/OSClaim/Get?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format("DD/MM/YYYY")}&UserID=${
        props.claimUserId
      }&StatusIDList=43,47,48&SearchStatusType=Claim&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("APPROVED-CLAIM::", res.result);
        if (res.success) {
          setApprovedClaimDeatils(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setApprovedClaimDeatils("");
          setIsLoading(false);
          setLoading(false);
        }
      });
  };
  // GET MANAGER APPROVED FOR APPROVAL CLAIM DETAILS END

  const clearSerachBySelect = () => {
    setFromDate(new Date(props.fromDate));
    setToDate(new Date(props.toDate));
  };

  const exportExcelClaim = () => {
    exportExcel(
      `${baseURL}/api/Export/OSClaimDetailExcelExport?FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${
        props.claimUserId
      }&StatusIDList=43,47,48&SearchStatusType=Claim&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "OS_Manager_Approved_ClaimData.xlsx",
      setDownloadIsLoading
    );
  };

  const exportUserwiseReport = () => {
    exportExcel(
      `${baseURL}/api/Export/OSUserWiseClaimExcelExport?UserID=${
        props.claimUserId
      }&StatusIDList=43,47,48&SearchStatusType=Claim&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "OS_Manager_ApprovedClaim_UserwiseReport.xlsx",
      setDownloadIsLoading
    );
  };

  return (
    <div id="main-page">
      {props.approvedClaim ? (
        <>
          {/* MAIN BODY START */}
          <div>
            {/* PAGE CONTENT START */}

            {/* Filter and Header Section */}
            <div className="user-table">
              <Row className="page-filter-section">
                <Col md={4} className="cat-col">
                  <div className="page-header-text-div">
                    <div
                      className="back-btn"
                      onClick={props.goBackToClaimfromApprovedPage}
                    >
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt="back-arrow"
                      />
                    </div>
                    <h6 className="page-header-text">
                      Approved Claim by Manager
                    </h6>
                  </div>
                </Col>
                <Col md={8} className="cat-col">
                  <div className="filter-row-input-boxes-end">
                    <div className="from-to-date-select">
                      <div className="input-group-text" id="from-label">
                        From - To
                      </div>
                      <div className="date-picker-with-icon">
                        <DatePicker
                          selected={fromDate}
                          className="select from-date date-filter"
                          id="date-range-picker"
                          dateFormat="dd/MM/yyyy"
                          selectsRange
                          startDate={fromDate}
                          endDate={toDate}
                          onChange={(date) => setSelectSearchDrop(date)}
                          maxDate={new Date()}
                        />
                        <div>
                          <i
                            className="fa fa-calendar-o calander-icon"
                            style={{ right: "6px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <MiClearButton
                      onClickEffect={clearSerachBySelect}
                      btnName="Clear"
                    />
                    <SbUserwiseReportBtn
                      onClickEffect={exportUserwiseReport}
                      btnName="Userwise Report"
                    />
                  </div>
                </Col>
              </Row>
            </div>
            {/* Filter and Header Section */}

            {/* USER TABLE START */}
            <div className="approvedclaim-table">
              <div className="pendingfor-approval">
                {isLoading ? (
                  <SkeletonPendingClaimHead />
                ) : (
                  <div className="pendingapproval-records">
                    <div className="claim-user-details-img">
                      <img
                        className="pendingapproval-user-imgage"
                        src={profileimg}
                        alt=""
                      />
                      <div className="pendingapproval-username-details">
                        <span>
                          <b>User Name :</b> &nbsp;{" "}
                          {approvedClaimDeatils[0]?.fullName}
                        </span>
                        <span>
                          <b>Email ID :</b> &nbsp;{" "}
                          {approvedClaimDeatils[0]?.email}
                        </span>
                        <span>
                          <b>Contact No.:</b> &nbsp;{" "}
                          {approvedClaimDeatils[0]?.countryCode}{" "}
                          {approvedClaimDeatils[0]?.contact}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="table-responsive approvedclaim-scroll"
                  onScroll={(e) =>
                    handleScroll(
                      e,
                      approvedClaimDeatils,
                      totalData,
                      setLoading,
                      setPageSize,
                      PageSize
                    )
                  }
                >
                  {isLoading ? (
                    <SkeletonPendingTableData />
                  ) : approvedClaimDeatils !== "" &&
                    approvedClaimDeatils.length > 0 ? (
                    approvedClaimDeatils?.map((data, i) => (
                      <table className="table attendance-list-table" key={i}>
                        <thead className="thead-light approved-claim-thead">
                          <tr>
                            <th>
                              {i + 1}. AppliedDate : {data?.claimDate}
                            </th>
                            <th>No. of Days : {data?.noOfDays}</th>
                            <th>From Date : {data?.fromDate}</th>
                            <th>To Date : {data?.toDate}</th>
                            <th>From Location : {data?.fromLocation}</th>
                            <th>To Location : {data?.toLocation}</th>
                            <th>Status : {data?.byManager?.status}</th>
                          </tr>
                          <tr>
                            <th>Type</th>
                            <th>Attachment</th>
                            <th>Applied</th>
                            <th>Approved</th>
                            <th>Approved By</th>
                            <th colSpan={2}>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            <tr className="attendance-list-row-data">
                              <td>{data?.fareClaim?.mot} Fare</td>
                              <td>
                                {data?.fareClaim?.attachment == "" ? (
                                  <span
                                    class="material-symbols-outlined"
                                    title="No-attachment"
                                    style={{ fontSize: "18px" }}
                                  >
                                    attach_file_off
                                  </span>
                                ) : (
                                  <span
                                    class="material-symbols-outlined"
                                    title="View"
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setClaimAttachmentmodel(
                                        !claimAttachmentmodel
                                      );
                                      setAttachmentUrl(
                                        data?.fareClaim?.attachment.split(
                                          ","
                                        ) ?? ""
                                      );
                                    }}
                                  >
                                    attach_file
                                  </span>
                                )}
                              </td>
                              <td>{data?.fareClaim?.amount}</td>
                              <td>
                                {data?.byManager?.approvedOSClaimAmount ?? 0}
                              </td>
                              <td>{data?.byManager?.fullName}</td>
                              <td colSpan={2}> {data?.fareClaim?.remark}</td>
                            </tr>
                            <tr className="attendance-list-row-data">
                              <td>Lodging</td>
                              <td>
                                {data?.hotelClaim?.attachment == "" ? (
                                  <span
                                    class="material-symbols-outlined"
                                    title="No-attachment"
                                    style={{ fontSize: "18px" }}
                                  >
                                    attach_file_off
                                  </span>
                                ) : (
                                  <span
                                    class="material-symbols-outlined"
                                    title="View"
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setClaimAttachmentmodel(
                                        !claimAttachmentmodel
                                      );
                                      setAttachmentUrl(
                                        data?.hotelClaim?.attachment.split(
                                          ","
                                        ) ?? ""
                                      );
                                    }}
                                  >
                                    attach_file
                                  </span>
                                )}
                              </td>
                              <td>{data?.hotelClaim?.amount}</td>
                              <td>
                                {data?.byManager?.approvedHotelClaimAmount}
                              </td>
                              <td>{data?.byManager?.fullName}</td>
                              <td colSpan={2}> {data?.hotelClaim?.remark}</td>
                            </tr>
                            <tr className="attendance-list-row-data">
                              <td>Food</td>
                              <td>
                                {data?.foodClaim?.attachment == "" ? (
                                  <span
                                    class="material-symbols-outlined"
                                    title="No-attachment"
                                    style={{ fontSize: "18px" }}
                                  >
                                    attach_file_off
                                  </span>
                                ) : (
                                  <span
                                    class="material-symbols-outlined"
                                    title="View"
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setClaimAttachmentmodel(
                                        !claimAttachmentmodel
                                      );
                                      setAttachmentUrl(
                                        data?.foodClaim?.attachment.split(
                                          ","
                                        ) ?? ""
                                      );
                                    }}
                                  >
                                    attach_file
                                  </span>
                                )}
                              </td>
                              <td>{data?.foodClaim?.amount}</td>
                              <td>
                                {data?.byManager?.approvedFoodClaimAmount}
                              </td>
                              <td>{data?.byManager?.fullName}</td>
                              <td colSpan={2}> {data?.foodClaim?.remark}</td>
                            </tr>
                            <tr className="attendance-list-row-data">
                              <td>Local Convayence</td>
                              <td>
                                {data?.localClaim?.attachment == "" ? (
                                  <span
                                    class="material-symbols-outlined"
                                    title="No-attachment"
                                    style={{ fontSize: "18px" }}
                                  >
                                    attach_file_off
                                  </span>
                                ) : (
                                  <span
                                    class="material-symbols-outlined"
                                    title="View"
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setClaimAttachmentmodel(
                                        !claimAttachmentmodel
                                      );
                                      setAttachmentUrl(
                                        data?.localClaim?.attachment.split(
                                          ","
                                        ) ?? ""
                                      );
                                    }}
                                  >
                                    attach_file
                                  </span>
                                )}
                              </td>
                              <td>{data?.localClaim?.amount}</td>
                              <td>
                                {data?.byManager?.approvedLocalClaimAmount}
                              </td>
                              <td>{data?.byManager?.fullName}</td>
                              <td colSpan={2}> {data?.localClaim?.remark}</td>
                            </tr>
                            <tr className="attendance-list-row-data">
                              <td>Others</td>
                              <td>
                                {data?.othersClaim?.attachment == "" ? (
                                  <span
                                    class="material-symbols-outlined"
                                    title="No-attachment"
                                    style={{ fontSize: "18px" }}
                                  >
                                    attach_file_off
                                  </span>
                                ) : (
                                  <span
                                    class="material-symbols-outlined"
                                    title="View"
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setClaimAttachmentmodel(
                                        !claimAttachmentmodel
                                      );
                                      setAttachmentUrl(
                                        data?.othersClaim?.attachment.split(
                                          ","
                                        ) ?? ""
                                      );
                                    }}
                                  >
                                    attach_file
                                  </span>
                                )}
                              </td>
                              <td>{data?.othersClaim?.amount}</td>
                              <td>
                                {data?.byManager?.approvedOthersClaimAmount}
                              </td>
                              <td>{data?.byManager?.fullName}</td>
                              <td colSpan={2}> {data?.othersClaim?.remark}</td>
                            </tr>
                            <tr className="pendingapproval-row-data total-value">
                              <td>
                                {" "}
                                &nbsp; &nbsp;
                                <b>
                                  Approved Date :{" "}
                                  {/* {moment(data?.byManager?.date).format(
                                    "DD/MM/YYYY"
                                  )} */}
                                  {data?.byManager?.date}
                                </b>
                              </td>
                              <td>
                                <b>TOTAL</b>
                              </td>
                              <td>
                                <b>{data?.totalAmount}</b>
                              </td>
                              <td>
                                <b>
                                  {data?.byManager?.approvedTotalClaimAmount}
                                </b>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </>
                        </tbody>
                      </table>
                    ))
                  ) : (
                    <div>
                      <span className="text-center" style={{ width: "100%" }}>
                        --- NO DATA FOUND ---
                      </span>
                    </div>
                  )}
                  {loading && <OnScrollLoading />}
                </div>
              </div>
            </div>
            {/* USER TABLE END */}

            <div className="download-and-pagination">
              <SbDownloadBtn onClickEffect={exportExcelClaim} />
            </div>

            {/* PAGE CONTENT END*/}

            {/* MAIN BODY END */}
            {downloadIsLoading && (
              <>
                <DownloadLoading />
              </>
            )}

            {claimAttachmentmodel ? (
              <ViewClaimAttachmentmodel
                claimAttachmentmodel={claimAttachmentmodel}
                handleCloseviewattachment={handleCloseviewattachment}
                attachmentUrl={attachmentUrl}
              />
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default ApprovedClaimbyManager;
