import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/BaseFare.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { loadRouteDeviationData, loadUserDropdown } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import SbCancelBtn, { MiClearButton } from "../SbButtons/SbCancelBtn";
import { loggedInRole } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { SkeletonMeetPurpose } from "../Skeleton Loading/SkeletonMeetPurpose";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import "../../styles/Meetings.scss";
import { exportExcel } from "../DownloadFile";
import { baseURL } from "../BaseUrl";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import DownloadLoading from "../ReUseComponents/DownloadLoading";

function RouteDeviationReport(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const navigate = useNavigate();

  let tableHeader = [
    { name: "ROUTE ID", align: "left", width: "150px" },
    { name: "USER NAME", align: "left", width: "150px" },
    { name: "CLIENT NAME", align: "left", width: "200px" },
    { name: "MEETING DATE And Time", align: "left", width: "200px" },
    { name: "ACTUAL ROUTE", align: "left", width: "250px" },
    { name: "REMARKS", align: "left", width: "200px" },
    { name: "DEVIATION INDICATOR", align: "left", width: "200px" },
    { name: "VARIANCE", align: "left", width: "250px" },
  ];

  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);
  const { getRouteDeviation } = useSelector((state) => state.data);
  const { totalCount } = useSelector((state) => state.data);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const [openFilterTab, setOpenFilterTab] = useState(false);

  //code for filter
  const [filterUserId, setFilterUserId] = useState("");
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const [fromDate, setFromDate] = useState(firstDayOfMonth);
  const [toDate, setToDate] = useState(lastDayOfMonth);

  const [filterArray, setFilterArray] = useState([]);

  //code for search bar

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    let list = [];
    if (fromDate && toDate) {
      list.push({
        key: "FromDate",
        value: moment(new Date(fromDate)).format("DD/MM/YYYY"),
      });
      list.push({
        key: "ToDate",
        value: moment(new Date(toDate)).format("DD/MM/YYYY"),
      });
    }
    setFilterArray(list);
  }, [fromDate, toDate]);

  const setSearchData = (value) => {
    let list;
    if (filterArray.length !== 0) {
      list = filterArray.filter((item) => item.key !== "Text");
    } else {
      list = [];
    }
    if (value) {
      list.push({ key: "Text", value: value });
    } else {
      list = filterArray.filter((item) => item.key !== "Text");
    }
    setSearchValue(value);
    setFilterArray(list);
  };

  const setSelectSearchDrop = (data, type) => {
    let list = [];
    if (type === "user") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "UserID");
      }
      list.push({ key: "UserID", value: data.value });
      setFilterUserId(data);
    } else if (type === "date") {
      const [start, end] = data;
      if (filterArray.length !== 0) {
        if (start) {
          list = filterArray.filter((item) => item.key !== "FromDate");
        }
        if (end) {
          list = filterArray.filter((item) => item.key !== "ToDate");
        }
      }
      if (start && end == null) {
        list.push({
          key: "FromDate",
          value: moment(new Date(start)).format("DD/MM/YYYY"),
        });
      }
      setFromDate(start);
      if (end) {
        list.push({
          key: "ToDate",
          value: moment(new Date(end)).format("DD/MM/YYYY"),
        });
      }
      setToDate(end);
    }
    setFilterArray(list);
  };

  const fetchRouteDeviationData = () => {
    dispatch(
      loadRouteDeviationData(
        filterArray,
        currentPage,
        PageSize,
        setIsLoading,
        setLoading
      )
    );
  };
  useEffect(() => {
    fetchRouteDeviationData(filterArray, currentPage, PageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterArray, currentPage, PageSize]);

  useEffect(() => {
    dispatch(loadUserDropdown());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setFromDate(firstDayOfMonth);
    setToDate(lastDayOfMonth);

    setFilterArray([]);
  };

  const exportRouteDeviationDetails = () => {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        param = param + "&" + item.key + "=" + item.value;
      });
    }
    exportExcel(
      `${baseURL}/api/Export/RouteDiviationDetailExcelExport?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      "RouteDeviation.xlsx",
      setDownloadIsLoading
    );
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* MAIN BODY START */}
          <div>
            {/* PAGE CONTENT START */}
            <div className={`main-body ${props.toggleshift.style}`}>
              <div className="page-content">
                <div>
                  <Row className="page-filter-section">
                    <Col md={4} className="p-0">
                      <div className="page-header-text-div">
                        <div
                          className="back-btn"
                          onClick={props.backtoRouteFromRouteDeviation}
                        >
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">
                          Route Deviation Report
                        </h6>
                      </div>
                    </Col>
                    <Col md={5} className="p-0">
                      <div className="input-div">
                        <input
                          type="search"
                          placeholder="Find a Route"
                          className="form-control setting-input"
                          id="metricInfo_search_bar"
                          value={searchValue}
                          onChange={(e) => setSearchData(e.target.value)}
                        />
                        <i className="fa fa-search search-icon"></i>
                      </div>
                    </Col>
                    <Col md={3} className="cat-col">
                      <div className="filter-row-input-boxes-end">
                        <div
                          className="header-filter-btn"
                          onClick={() => setOpenFilterTab(!openFilterTab)}
                        >
                          <div className="filter-icon-text">
                            <i className="bi bi-sliders" />
                            <span>Filters</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab && (
                    <div className="page-filter-section">
                      <div className="filter-row-input-boxes">
                        <div>
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={userDropdown}
                            placeholder="Select User"
                            value={filterUserId}
                            onInputChange={userDropdown.label}
                            onChange={(data) =>
                              setSelectSearchDrop(data, "user")
                            }
                            isSearchable={true}
                          />
                        </div>

                        <div className="from-to-date-select">
                          <div className="input-group-text" id="from-label">
                            From - To
                          </div>
                          <div className="date-picker-with-icon">
                            <DatePicker
                              selected={fromDate}
                              className="select from-date date-filter"
                              id="date-range-picker"
                              dateFormat="dd/MM/yyyy"
                              selectsRange
                              startDate={fromDate}
                              endDate={toDate}
                              onChange={(data) =>
                                setSelectSearchDrop(data, "date")
                              }
                              maxDate={new Date()}
                            />
                            <div>
                              <i
                                className="fa fa-calendar-o calander-icon"
                                style={{ right: "6px" }}
                              />
                            </div>
                          </div>
                        </div>
                        <MiClearButton
                          onClickEffect={clearSerachBySelect}
                          btnName="Clear"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="sb-table-div sb-table-setion">
                  <Paper
                    sx={{ width: "100%", overflow: "hidden" }}
                    className="table-main-div"
                  >
                    <TableContainer
                      sx={{ maxHeight: 440 }}
                      onScroll={(e) =>
                        handleScroll(
                          e,
                          getRouteDeviation,
                          totalCount,
                          setLoading,
                          setPageSize,
                          PageSize
                        )
                      }
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className="basefare-table"
                      >
                        <TableHead className="custom-table-header">
                          <TableRow>
                            {tableHeader.map((name, i) => (
                              <TableCell
                                key={i}
                                align={name.align}
                                className={`thead-cell-products`}
                                style={{ minWidth: name.width }}
                              >
                                {name.name}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        {isLoading ? (
                          <>
                            {tableHeader.map((name, i) => (
                              <SkeletonMeetPurpose key={i} />
                            ))}
                          </>
                        ) : (
                          <>
                            {getRouteDeviation &&
                            getRouteDeviation.length === 0 ? (
                              <TableBody>
                                <TableRow align="center">
                                  <TableCell
                                    align="center"
                                    colSpan={tableHeader.length}
                                  >
                                    <div style={{ padding: "20px 20px" }}>
                                      <img
                                        src="../../../../images/DefaultImages/NodataFound.jpg"
                                        alt=""
                                        style={{ width: "10%" }}
                                      />
                                      <br />
                                      <span>No Data Found...!</span>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ) : (
                              <TableBody>
                                {getRouteDeviation &&
                                  getRouteDeviation.map((data, i) => (
                                    <TableRow
                                      align="center"
                                      key={i}
                                      className="client-list-row-data"
                                    >
                                      <TableCell>
                                        <span>
                                          Route No : #R_{data.routeID ?? "NA"}
                                        </span>
                                      </TableCell>
                                      <TableCell>
                                        <span className="users-list-name">
                                          <div>
                                            <div className="table-row-head-Text">
                                              {data?.userFullName ?? "NA"}
                                            </div>
                                            <div className="table-row-sub-text">
                                              {data?.userCode}
                                            </div>
                                          </div>
                                        </span>
                                      </TableCell>
                                      <TableCell>
                                        <span>{data?.clientName ?? "NA"}</span>
                                      </TableCell>
                                      <TableCell>
                                        <span>
                                          {moment(
                                            new Date(data.meetingDate)
                                          ).format("DD MMM YYYY")}
                                        </span>
                                        <br />
                                        <span>
                                          {moment(
                                            data.meetingTime,
                                            "HH:mm"
                                          ).format("hh:mm A")}
                                        </span>
                                      </TableCell>
                                      <TableCell>
                                        <i className="fa fa-thumb-tack mtdtls-icon" />{" "}
                                        &nbsp; {data?.clientLoc ?? "NA"}
                                        <span className="client-meet-location">
                                          <p className="client-meet-lat">
                                            <span className="color-yellow">
                                              <i className="bi bi-geo-alt" />
                                            </span>
                                            {data?.clientLat ?? "NA"}
                                          </p>
                                          <p className="client-meet-lan">
                                            <span className="color-yellow">
                                              <i className="bi bi-geo-alt" />
                                            </span>
                                            {data?.clientLong ?? "NA"}
                                          </p>
                                        </span>
                                      </TableCell>
                                      <TableCell>
                                        {data.meetingRemarks === "" ||
                                        data.meetingRemarks === null
                                          ? "NA"
                                          : data.meetingRemarks}
                                      </TableCell>
                                      <TableCell>
                                        {data.diviationIndicator === " " ||
                                        data.diviationIndicator === null
                                          ? "NA"
                                          : data.diviationIndicator}
                                      </TableCell>
                                      <TableCell>
                                        {data.variance === "SKIP" && (
                                          <span className="status-badge complete-status">
                                            {data.variance}
                                          </span>
                                        )}
                                        {data.variance === "As Per Plan" && (
                                          <span className="status-badge pending-status">
                                            {data.variance}
                                          </span>
                                        )}
                                        {data.variance ===
                                          "Unmapped Address(Deviation)" && (
                                          <span className="status-badge unmapped-status">
                                            {data.variance}
                                          </span>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                {loading && <OnScrollLoading />}
                              </TableBody>
                            )}
                          </>
                        )}
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>

                <div className="download-and-pagination">
                  <SbDownloadBtn
                    btnName="Download"
                    onClickEffect={exportRouteDeviationDetails}
                  />
                </div>
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>

          {downloadIsLoading && (
            <>
              <DownloadLoading />
            </>
          )}
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default RouteDeviationReport;
