import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import "../../styles/Orders.scss";
import moment from "moment";
import { baseURL } from "../BaseUrl";
import { useEffect } from "react";
import {
  FormattedAmount,
  dateFormatIST,
  getRandomColor,
} from "../FormattedAmount";
import { Link } from "react-router-dom";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { loadOrderHistory } from "../../redux/action";

function OrderListTable(props) {
  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table table-responsive">
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((name, i) => (
                  <TableCell
                    align={name.align}
                    key={i}
                    className={`thead-cell-products`}
                    style={{ minWidth: `${name.width}` }}
                  >
                    {name.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <>
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
              </>
            ) : (
              <>
                {props.productItems.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        colSpan={props.tableHeader.length}
                        align="center"
                      >
                        <div style={{ padding: "50px 20px" }}>
                          You have not added any products to cart...!
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {props.productItems &&
                      props.productItems.map((item, i) => (
                        <React.Fragment key={i}>
                          <TableRow
                            align="center"
                            className="cart-list-row-data order-row"
                          >
                            <TableCell align="center">
                              <div className="users-list-name">
                                <input
                                  type="checkbox"
                                  value=""
                                  onChange={(e) =>
                                    props.selectProduct(e, item.orderItemID)
                                  }
                                  checked={props.selectedProducts.includes(
                                    item.orderItemID
                                  )}
                                />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="users-list-name">
                                <div>
                                  {item.product_Image ? (
                                    <img
                                      className="table-data-users-imgages company-logo"
                                      src={`${baseURL}/${item.product_Image}`}
                                      alt=""
                                    />
                                  ) : (
                                    <div
                                      className="table-data-users-imgages default-image"
                                      style={{ background: "#04a96d" }}
                                    >
                                      {item.product_Name
                                        .charAt(0)
                                        .toUpperCase()}
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <div
                                    className="table-row-head-Text"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.product_Name}
                                  </div>
                                  <div
                                    className="table-row-head-Text"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.description.length > 25
                                      ? item.description.substring(0, 25) +
                                        "..."
                                      : item.description}
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                            {/* <TableCell>
                                                        {item.description.length > 25
                                                            ? item.description.substring(0, 25) + "..."
                                                            : item.description}
                                                    </TableCell> */}
                            <TableCell align="center">
                              <span className="color-secondary">
                                <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                                {FormattedAmount(item.price)}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span>
                                <b>{item.quantity}</b>
                              </span>
                            </TableCell>
                            <TableCell align="right">
                              <span className="primary-color">
                                <b>
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {FormattedAmount(
                                    parseFloat(item.price) *
                                      parseFloat(item.quantity)
                                  )}
                                </b>
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              {props.singleOrder.orderStatus === "Delivered" ? (
                                <span>
                                  <b>{item.sent_Quantity} Sent</b>
                                </span>
                              ) : (
                                <span>
                                  <b>{item.pending_Quantity} Pending</b>
                                </span>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {props.singleOrder.orderStatus === "Delivered" ? (
                                <span className="complete-status status-badge">
                                  {props.singleOrder.orderStatus}
                                </span>
                              ) : (
                                <div className="table-input-div">
                                  <input
                                    type="number"
                                    className="form-control form add-product-input"
                                    placeholder={
                                      item.pending_Quantity == 0
                                        ? "All Dispatched"
                                        : "Enter Quantity"
                                    }
                                    name="price"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                    }}
                                    value={
                                      item.dispatchQty ? item.dispatchQty : ""
                                    }
                                    onChange={(e) =>
                                      props.handleOrderInput(
                                        e,
                                        item.orderItemID
                                      )
                                    }
                                    readOnly={
                                      props.selectedProducts.length !== 0 &&
                                      !props.selectedProducts.includes(
                                        item.orderItemID
                                      )
                                    }
                                    disabled={
                                      item.pending_Quantity == 0 ? true : false
                                    }
                                  />
                                </div>
                              )}
                              {item.dispatchQty > item.quantity && (
                                <small
                                  id={`Error`}
                                  className="form-text text-muted "
                                >
                                  dispatched qty should not be exceeded than
                                  ordered qty
                                </small>
                              )}
                              {item.dispatchQty <= 0 && (
                                <small
                                  id={`Error`}
                                  className="form-text text-muted "
                                >
                                  dispatched qty should not be negative value
                                </small>
                              )}
                              {item.status && (
                                <small
                                  id={`Error`}
                                  className="form-text text-muted "
                                >
                                  Selected product Qty is Required
                                </small>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <span className="user-action-td">
                                {props.selectedProducts.length === 0 && (
                                  <>
                                    {!item.dispatchQty ||
                                    item.dispatchQty > item.quantity ||
                                    item.dispatchQty <= 0 ? (
                                      <span
                                        style={{ opacity: "0.5" }}
                                        className="user-edit-link"
                                      >
                                        <i className="fa fa-truck"></i>&nbsp;
                                        Dispatch
                                      </span>
                                    ) : (
                                      <Link
                                        to="#"
                                        onClick={() =>
                                          props.sendDispatchedQty(
                                            item.orderItemID
                                          )
                                        }
                                        className="user-edit-link"
                                      >
                                        <i className="fa fa-truck"></i>&nbsp;
                                        Dispatch
                                      </Link>
                                    )}
                                  </>
                                )}

                                <span
                                  className="user-view-link"
                                  onClick={() =>
                                    props.handleView(
                                      item.orderID,
                                      item.orderItemID
                                    )
                                  }
                                >
                                  <i className="fa fa-eye"></i> History
                                </span>
                              </span>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    <TableRow
                      align="center"
                      className="cart-list-row-data total-count-row"
                    >
                      <TableCell></TableCell>
                      <TableCell colSpan={2}>
                        <div className="table-row-head-Text">Total</div>
                      </TableCell>
                      <TableCell align="center">
                        <span>
                          <b>{props.singleOrder.totalQTY}</b>
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <span>
                          <b></b>
                        </span>
                        <span className="primary-color">
                          <b>
                            <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                            {FormattedAmount(props.singleOrder.totalAmount)}
                          </b>
                        </span>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        {props.selectedProducts.length !== 0 && (
                          <SBSaveUpdateBtn
                            btnName="Bulk Dispatch"
                            onClickEffect={() => props.sendDispatchedQty(null)}
                          />
                        )}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default OrderListTable;
