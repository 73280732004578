import React, { useEffect, useLayoutEffect, useState } from "react";
import Footer from "../../Footer";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { MiClearButton } from "../../SbButtons/SbCancelBtn";
import { optionsStatusList } from "../../DropdownsData";
import { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import { invDetailsTableData } from "../TestData";
import InvoiceStatusTable from "./InvoiceStatusTable";
import "../../../styles/VhdInvoiceStatus.scss";
import { setWidthToMaxContent } from "../VhdCommonStyles";
import SbDropDownBtn from "../../SbButtons/SbDropDownBtn";
import AddInvoiceModel from "./AddInvoiceModel";
import { loadAllInvoiceDetails } from "../../../redux/action";

export default function VhdInvoiceStatus() {
  const tableHeader = [
    { name: "INV.DocNo", align: "left", width: setWidthToMaxContent },
    { name: "INV.Doc.Date", align: "left", width: setWidthToMaxContent },
    { name: "PO.DocNo", align: "left", width: setWidthToMaxContent },
    { name: "PO.Doc.Date", align: "left", width: setWidthToMaxContent },
    { name: "Vendor Code", align: "left", width: setWidthToMaxContent },
    { name: "Vendor Name", align: "left", width: setWidthToMaxContent },
    { name: "Invoice Amount", align: "left", width: setWidthToMaxContent },
    { name: "Tax Amount", align: "left", width: setWidthToMaxContent },
    { name: "Grand Total(₹)", align: "left", width: setWidthToMaxContent },
    { name: "Inv. Attachment", align: "center", width: "auto" },
    { name: "Po. Attachment", align: "center", width: "auto" },
    { name: "Dept.Status", align: "center", width: "auto" },
    { name: "Fin.Status", align: "center", width: "auto" },
  ];

  let dispatch = useDispatch();

  const { getALlInvoiceDetails } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const [filterUserId, setFilterUserId] = useState("");
  const [activeStatus, setActiveStatus] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const [addEditInvoice, setAddEditInvoice] = useState(false);
  const [invoiceID, setInvoiceID] = useState(null);

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const [totalData, setTotalData] = useState(0);
  const currentPage = 1;
  //* PAGINATION END *//

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchINVDetails();
  }, [PageSize, currentPage, setTotalData]);

  const fetchINVDetails = () => {
    dispatch(loadAllInvoiceDetails(PageSize, currentPage, setTotalData));
  };

  const openCloseAddEditModel = () => {
    setAddEditInvoice(!addEditInvoice);
  };

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setActiveStatus(1);
  };
  //* CLEAR SELECT END *//

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // Page navigation

  return (
    <div>
      {!addEditInvoice ? (
        <>
          <>
            {/* SIDEBAR - TOPBAR - FOOTER  */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            <Topbar
              toggleMenu={toggleMenu}
              menuCollapse={menuCollapse}
              toggleshift={toggleshift}
            />
            <Footer />
            {/* SIDEBAR - TOPBAR - FOOTER  */}
          </>
          <>
            <div className="page-wrapper">
              <div className={`main-body ${toggleshift.style}`}>
                <div className="page-content">
                  {/* Filter and Header Section */}
                  <div className="user-table">
                    <Row className="search-row">
                      <Col md={4} sm={12} className="cat-col">
                        <div className="page-header-text-div">
                          <div className="back-btn" onClick={goToSettingPage}>
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6 className="page-header-text">Invoice Status</h6>
                        </div>
                      </Col>
                      <Col md={5} sm={12} className="cat-col">
                        <div className="input-div">
                          <input
                            type="search"
                            id="metricInfo_search_bar"
                            placeholder="Search..."
                            className="form-control setting-input"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                          <i className="fa fa-search search-icon" />
                        </div>
                      </Col>
                      <Col md={3} sm={12} className="cat-col">
                        <div
                          className="search-bar-header"
                          style={{ float: "right" }}
                        >
                          <div className="page-top-bar-dropdowns page-top-Filters">
                            <div
                              className="header-filter-btn"
                              onClick={() => setFilter(!filter)}
                            >
                              <div className="filter-icon-text">
                                <i className="bi bi-sliders" />
                                <span>Filters</span>
                              </div>
                            </div>
                            {/* <SbDropDownBtn
                              pageName="Invoice"
                              openAddModal={openCloseAddEditModel}
                              // addBulkModel={handleCloseBulkUserModel}
                            /> */}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {filter && (
                      <div className="page-filter-section">
                        <div className="filter-row-input-boxes">
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              // options={userDropdown}
                              placeholder="Select Vendor"
                              // value={
                              //   filterUserId === "" ? "" : userDropdown.value
                              // }
                              // onInputChange={userDropdown.label}
                              onChange={(data) => setFilterUserId(data.value)}
                              isSearchable={true}
                            />
                          </div>

                          <div className="from-to-date-select">
                            <div className="input-group-text" id="from-label">
                              From - To
                            </div>
                            <div className="date-picker-with-icon">
                              <DatePicker
                                selected={fromDate}
                                className="select from-date date-filter"
                                id="date-range-picker"
                                dateFormat="dd/MM/yyyy"
                                selectsRange
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={(date) => setSelectSearchDrop(date)}
                                // maxDate={new Date()}
                              />
                              <div>
                                <i
                                  className="fa fa-calendar-o calander-icon"
                                  style={{ right: "6px" }}
                                />
                              </div>
                            </div>
                          </div>

                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={optionsStatusList}
                              placeholder="Select Status"
                              value={
                                activeStatus === 2
                                  ? ""
                                  : activeStatus
                                  ? activeStatus
                                  : optionsStatusList.value
                              }
                              onInputChange={optionsStatusList.label}
                              onChange={(data) => setActiveStatus(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <MiClearButton
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Filter and Header Section */}
                  <div className="invoicestatus-page-table-part">
                    {/* TABLE START */}
                    <InvoiceStatusTable
                      tableHeader={tableHeader}
                      tableData={getALlInvoiceDetails}
                      setWidthToMaxContent={setWidthToMaxContent}
                      setLoading={setLoading}
                      setPageSize={setPageSize}
                      PageSize={PageSize}
                      totalData={totalData}
                    />

                    <div className="download-and-pagination">
                      <SbDownloadBtn
                        btnName="Download"
                        // onClickEffect={exportUserDetails}
                      />
                    </div>
                  </div>
                  {/* TABLE END */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>

              <></>
              {/* MAIN BODY END */}
            </div>
          </>
        </>
      ) : (
        <AddInvoiceModel
          setInvoiceID={setInvoiceID}
          invoiceID={invoiceID}
          handleClose={openCloseAddEditModel}
        />
      )}
    </div>
  );
}
