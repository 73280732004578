import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { SkeletonMeetPurpose } from "../Skeleton Loading/SkeletonMeetPurpose";
import { FormattedAmount } from "../FormattedAmount";
import { useSelector } from "react-redux";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";

function ReferenceInfoTable(props) {
  let tableHeader = [
    { name: "REFERENCE NO", align: "left", width: "200px" },
    { name: "NAME", align: "left", width: "150px" },
    { name: "BRANCH", align: "left", width: "200px" },
    { name: "BDE", align: "left", width: "200px" },
    { name: "REFERENCE REVENUE", align: "center", width: "200px" },
    { name: "COMMISSION EARNED", align: "center", width: "150px" },
    { name: "WITHDRAWAL", align: "center", width: "150px" },
    { name: "WALLET BALANCE", align: "center", width: "150px" },
    { name: "Action", align: "center", width: "150px" },
  ];

  const { totalCount } = useSelector((state) => state.data);

  return (
    <>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer sx={{ maxHeight: 440 }}
            onScroll={(e) =>
              handleScroll(
                e,
                props.cardData,
                totalCount,
                props.setLoading,
                props.setPageSize,
                props.PageSize
              )
            }
          >
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="basefare-table"
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {tableHeader.map((name, i) => (
                    <TableCell
                      key={i}
                      align={name.align}
                      className={`thead-cell-products`}
                      style={{ minWidth: name.width }}
                    >
                      {name.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {props.isLoading ? (
                <>
                  {tableHeader.map((name, i) => (
                    <SkeletonMeetPurpose key={i} />
                  ))}
                </>
              ) : (
                <>
                  {props.cardData && props.cardData.length === 0 ? (
                    <TableBody>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={tableHeader.length}>
                          <div style={{ padding: "20px 20px" }}>
                            <img
                              src="../../../../images/DefaultImages/NodataFound.jpg"
                              alt=""
                              style={{ width: "10%" }}
                            />
                            <br />
                            <span>No Data Found...!</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {props.cardData.map((data, index) => (
                        <TableRow
                          align="center"
                          key={index}
                          className="client-list-row-data"
                        >
                          <TableCell>
                            <div className='table-row-head-Text'>
                              {data.referelCode}
                            </div>
                          </TableCell>
                          <TableCell>{data.userName}</TableCell>
                          <TableCell>{"-"}</TableCell>
                          <TableCell>{data.bde}</TableCell>
                          <TableCell align="center">{data.revenue}</TableCell>
                          <TableCell align="center"><span className='prime-highlight-value'>
                            <i className="fa fa-inr" aria-hidden="true"></i>{FormattedAmount(data.earned)}
                          </span></TableCell>
                          <TableCell align="center">
                            <span className='color-warning'>
                              <i className="fa fa-inr" aria-hidden="true"></i>{FormattedAmount(data.claimed)}
                            </span></TableCell>
                          <TableCell align="center">
                            <span className='color-secondary'>
                              <i className="fa fa-inr" aria-hidden="true"></i>{FormattedAmount(data.closingBalance)}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <button
                              className="btn client-edit-link"
                              onClick={() => {
                                props.selectedUserPassbook(data.gfRefProfileID);
                              }}
                            >
                              <i class="fa-solid fa-building-columns" />{" "}
                              Passbook
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                      {props.loading && <OnScrollLoading />}
                    </TableBody>
                  )}
                </>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
}

export default ReferenceInfoTable;
