import React, { useState, useEffect, useLayoutEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/AddmeetingModel.scss";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import * as swal from "../Consturl/SwalAlert";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { travelizeAuth } from "../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import {
  loadClientDropDown,
  loadGetSingleClient,
  loadMeetingPurposeData,
  loadUserDropdown,
} from "../../redux/action";
import AddEditFormComponent from "../ReUseComponents/AddEditFormComponent";

function Addmeetingmodel(props) {
  let dispatch = useDispatch();

  const { userDropdown } = useSelector((state) => state.data);
  const { meetingPurposeData } = useSelector((state) => state.data);
  const { clientDropdown } = useSelector((state) => state.data);
  const { singleClient } = useSelector((state) => state.data);

  const [filterUserId, setFilterUserId] = useState("");
  const [date, setDate] = useState(new Date());
  const [meetPurposeId, setMeetPurposeId] = useState("");
  const [mobilenum, setMobileNum] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [clientId, setClientId] = useState(-1);
  const [clientName, setClientName] = useState("");
  const [meetTime, setMeetTime] = useState({ val: null, time: null });
  const [assignMeetTime, setAssignMeetTime] = useState("");
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const [fieldReq, setFieldReq] = useState(false);

  ///////////// TIMES SLOTS FOR ADD MEETING START
  const current = new Date();
  const dateNow = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const timeNow = Math.floor(`${current.getHours()}`);

  let x = 15; //minutes interval
  let times = []; // time array
  let tt = 0; // start time
  let ap = ["AM", "PM"]; // AM-PM

  //loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    // let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    let mm = tt % 60; // getting minutes of the hour in 0-55 format
    let hh12 = hh % 12;
    if (hh12 === 0) {
      hh12 = 12;
    }
    times[i] =
      ("0" + hh12).slice(-2) +
      ":" +
      ("0" + mm).slice(-2) +
      ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }
  // TIMES SLOTS FOR ADD MEETING START

  const selectTime = (time) => {
    console.log("SELECT-TIME:", time);
    setMeetTime({ val: time, time: `${time.$H}:${time.$m}` });
    let timeS = time.$d.toTimeString().slice(0, 5);
    const currentTime = timeS.slice(0, 2);
    if (currentTime > 12) {
      let newTime = currentTime - 12;
      if (newTime < 10) {
        let str = timeS.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        setAssignMeetTime("0" + exactTime + " " + "PM");
      }
    } else if (currentTime === 12) {
      setAssignMeetTime(timeS + " " + "PM");
    } else {
      setAssignMeetTime(timeS + " " + "AM");
    }
  };

  useLayoutEffect(() => {
    dispatch(loadUserDropdown());
    dispatch(loadMeetingPurposeData());
    setAddress("");
    setMobileNum("");
  }, []);

  useLayoutEffect(() => {
    dispatch(loadClientDropDown(filterUserId));
    setAddress("");
    setMobileNum("");
  }, [filterUserId]);

  useEffect(() => {
    dispatch(loadGetSingleClient(clientId));
  }, [clientId]);

  useEffect(() => {
    {
      singleClient && setAddress(singleClient?.clientLoc?.location);
      setMobileNum(singleClient?.mobile);
      setClientName(singleClient?.clientName);
      setLocation({
        lat: singleClient?.clientLoc?.latitude,
        lng: singleClient?.clientLoc?.longitude,
      });
    }
  }, [singleClient]);

  const addMeetings = (e) => {
    e.preventDefault();
    if (
      filterUserId !== "" &&
      clientName !== "" &&
      meetPurposeId !== "" &&
      meetTime !== null &&
      assignMeetTime !== ""
    ) {
      const addMeetingDetails = {
        userId: filterUserId,
        clientLoc: {
          latitude: location.lat,
          longitude: location.lng,
          location: address,
        },
        clientName: clientName,
        purposeId: JSON.parse(meetPurposeId),
        meetingDate: moment(date).format("DD/MM/YYYY"),
        meetingTime: assignMeetTime,
        contact: JSON.parse(mobilenum),
        status: "Pending",
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(addMeetingDetails),
      };
      fetch(`${baseURL}/api/Meeting/SaveMeeting`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.getMeetings();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required !",
      });
    }
  };

  const isCurrentDate =
    date.getDay() + date.getMonth() + date.getFullYear() ==
    new Date().getDay() + new Date().getMonth() + new Date().getFullYear();

  const isTime = new Date().getHours() + ":" + (new Date().getMinutes() + 30);
  // console.log("TIME:", isCurrentDate ? `2023-01-30T${isTime}` : "NO");

  const closeModel = () => {
    props.handleClose();
  };

  return (
    <div>
      <AddEditFormComponent
        handleClose={closeModel}
        formName={"Add Meeting"}
        formSubTitle={"Please enter the user infomation."}
        sidebarItems={["Add Meeting"]}
        childrens={
          <form onSubmit={addMeetings}>
            <div className="container">
              <div className="add-meeting">
                <div className="row assign-to d-flex align-items-center">
                  <div className="col-md-4 mb-2">
                    <label
                      htmlFor="exampleFormControlInput"
                      className="form-label input-model-label"
                    >
                      Assigned to
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      options={userDropdown}
                      placeholder="Select User"
                      value={userDropdown.value}
                      onInputChange={userDropdown.label}
                      onChange={(data) => setFilterUserId(data.value)}
                      isSearchable={true}
                    />
                    {fieldReq && filterUserId == "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row meeting-date d-flex align-items-center">
                  <div className="col-md-4 mb-2">
                    <label
                      htmlFor="exampleFormControlInput"
                      className="form-label input-model-label"
                    >
                      Meeting Date
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <div className="meeting-date-picker-with-icon">
                      <DatePicker
                        selected={date}
                        className="select-add-meeting-model meeting-date model-input"
                        dateFormat="dd/MM/yyyy"
                        // selectsEnd
                        // startDate={date}
                        // endDate={toDate}
                        minDate={new Date()}
                        onChange={(date) => setDate(date)}
                        // maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </div>
                  </div>
                </div>

                <div className="row meeting-time d-flex align-items-center">
                  <div className="col-md-4 mb-2">
                    <label
                      htmlFor="exampleFormControlInput"
                      className="form-label input-model-label"
                    >
                      Meeting Time
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopTimePicker
                        // label="Time"
                        className="form-control select-add-meeting-model"
                        value={meetTime.val}
                        onChange={(time) => {
                          selectTime(time);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        minTime={
                          isCurrentDate
                            ? dayjs(`2023-01-30T${isTime}`)
                            : dayjs("2023-01-30T09:00")
                        }
                        maxTime={dayjs("2023-01-31T22:00")}
                        minutesStep={15}
                      />
                    </LocalizationProvider>
                    {fieldReq && meetTime.time === null ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row meeting-purpose d-flex align-items-center">
                  <div className="col-md-4 mb-2">
                    <label
                      htmlFor="exampleFormControlInput"
                      className="form-label input-model-label"
                    >
                      Meeting Purpose
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      options={meetingPurposeData}
                      placeholder="Select Purpose"
                      value={meetingPurposeData.value}
                      onInputChange={meetingPurposeData.label}
                      onChange={(data) => setMeetPurposeId(data.value)}
                      isSearchable={true}
                    />
                    {fieldReq && meetPurposeId === "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row client-name d-flex align-items-center">
                  <div className="col-md-4 mb-2">
                    <label
                      htmlFor="exampleFormControlInput"
                      className="form-label input-model-label"
                    >
                      Company Name
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      options={clientDropdown}
                      placeholder="Select Company"
                      value={clientDropdown.value}
                      onInputChange={clientDropdown.label}
                      onChange={(data) => setClientId(data.value)}
                      isSearchable={true}
                    />
                    {fieldReq && clientId === null && clientName === "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row mobile-number d-flex align-items-center">
                  <div className="col-md-4 mb-2">
                    <label
                      htmlFor="exampleFormControlInput"
                      className="form-label input-model-label"
                    >
                      Mobile Number
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <input
                      type="text"
                      className="form-control form add-meeting-input model-input"
                      id="exampleFormControlInput1"
                      placeholder="Mobile Number"
                      readOnly={true}
                      value={mobilenum}
                      onChange={(e) => setMobileNum(e.target.value)}
                      // required
                    />
                    {fieldReq && clientId === null && mobilenum === "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row location d-flex align-items-center">
                  <div className="col-md-4 mb-2">
                    <label
                      htmlFor="exampleFormControlInput"
                      className="form-label input-model-label"
                    >
                      Location
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <textarea
                      rows="3"
                      cols="20"
                      name="usrtxt"
                      wrap="hard"
                      className="form-control form add-meeting-input location"
                      placeholder="Street Address/House No./Locality"
                      readOnly={true}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    {fieldReq && clientId === null && address === "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="add-page-save-cancel">
                <SBSaveUpdateBtn btnName="Save" />
                <SbCancelBtn
                  btnName="Cancel"
                  onClickEffect={() => {
                    props.handleClose();
                  }}
                />
              </div>
            </div>
          </form>
        }
      />
      {/* <Modal
        show={props.show}
        // onHide={props.handleClose}
        className="main_model"
      >
       
      </Modal> */}
      <></>
    </div>
  );
}

export default Addmeetingmodel;
