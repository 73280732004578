import React, { useState, useEffect, useLayoutEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { MultiSelect } from "react-multi-select-component";
import "../../styles/AddEditModel.scss";
import * as swal from "../Consturl/SwalAlert";
import moment from "moment";
import { baseURL } from "../BaseUrl";
import {
  loadUserDropdown,
  loadUserRegion,
  loadClientCategoryDrop,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { travelizeAuth } from "../HeaderAuthentication";
import AddEditFormComponent from "../ReUseComponents/AddEditFormComponent";
import SetClientsMeetDate from "./SetClientsMeetDate";

export default function AddRoute(props) {
  const loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const subscriptionID = loginDetails.subscriptionId;

  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);
  const { userRegionResult } = useSelector((state) => state.data);
  const { categoryDropdown } = useSelector((state) => state.data);

  const routeTypes = [
    { value: 1, label: "By Zone" },
    { value: 2, label: "By State" },
    { value: 3, label: "By City" },
    { value: 4, label: "By Company" },
    { value: 5, label: "Other" },
  ];

  const clientsListBy = [
    { value: 1, label: "User" },
    { value: 2, label: "State" },
    { value: 3, label: "City" },
  ];

  const frequencies = [
    { value: "None", label: "None" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
  ];

  const [routeName, setRouteName] = useState("");
  const [filterUserId, setFilterUserId] = useState("");
  const [routeType, setRouteType] = useState(null);
  const [clientsListByID, setClientsListByID] = useState(null);
  const [frequency, setFrequency] = useState("None");
  const [clientList, setClientList] = useState([]);
  const [clientId, setClientId] = useState([]);
  const [clients, setClients] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [stateListSingleSelect, setStateListSingleSelect] = useState([]);
  const [cityListSingleSelect, setCityListSingleSelect] = useState([]);
  const [zone, setZone] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [zoneIds, setZoneIds] = useState([]);
  const [stateIds, setStateIds] = useState([]);
  const [cityIds, setCityIds] = useState([]);
  const [clientLoading, setClientLoading] = useState(true);
  const [userIdForClient, setUserIdForClient] = useState("");
  const [categoryNames, setCategoryNames] = useState([]);
  const [categoryID, setCategoryID] = useState([]);
  const [stateForClient, setStateForClient] = useState([]);
  const [cityForClient, setCityForClient] = useState([]);
  const [stateIdsForClient, setStateIdsForClient] = useState([]);
  const [cityIdsForClient, setCityIdsForClient] = useState([]);
  const [ownText, setOwnText] = useState("");
  const [oldIds, setOldIDS] = useState([]);
  const [areaNamesByCity, setAreaNamesByCity] = useState([]);
  const [areasIdsCity, setAreasIdsCity] = useState([]);
  const [areasByCity, setAreasByCity] = useState([]);
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [nextModel, setNextModel] = useState(false);
  const [clientsData, setClientsData] = useState("");
  const [filterdClientsIds, setFilterdClientsIds] = useState([]);
  const [fromDateofRoute, setFromDateofRoute] = useState();
  const [toDateofRoute, setToDateofRoute] = useState();
  const [routeUserName, setRouteUserName] = useState("");

  useLayoutEffect(() => {
    dispatch(loadUserRegion());
    dispatch(loadUserDropdown());
    dispatch(loadClientCategoryDrop());
  }, []);

  useEffect(() => {
    if (frequency == "Weekly") {
      const oneWeekDate = new Date(
        fromDate.getFullYear(),
        fromDate.getMonth(),
        fromDate.getDate() + 6
      );
      setToDate(oneWeekDate);
    } else if (frequency == "Monthly") {
      const oneMonth = new Date(
        fromDate.getFullYear(),
        fromDate.getMonth(),
        fromDate.getDate() + 29
      );
      setToDate(oneMonth);
    }
  }, [fromDate, frequency]);

  useEffect(() => {
    if (props.editRouteID !== null) {
      let requestOption = {
        method: "GET",
        mode: "cors",
        headers: travelizeAuth,
      };

      fetch(`${baseURL}/api/Route/Get/${props.editRouteID}`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setRouteName(res.result.name);
            setFrequency(res.result.frequency);
            setOwnText(res.result.routeTypeDetails);
            // Parse the input date string into a JavaScript Date object
            const [dayf, monthf, yearf] = res.result.fromDate
              .split("/")
              .map(Number);
            const [dayt, montht, yeart] = res.result.toDate
              .split("/")
              .map(Number);
            const dateFrom = new Date(yearf, monthf - 1, dayf);
            const dateTo = new Date(yeart, montht - 1, dayt);
            setFromDate(dateFrom);
            setToDate(dateTo);
            setFilterUserId(res.result.addedByID);
            setRouteType(
              res.result.routeType == "By Zone"
                ? 1
                : res.result.routeType == "By State"
                ? 2
                : res.result.routeType == "By City"
                ? 3
                : res.result.routeType == "By Company"
                ? 4
                : 5
            );
            if (res.result.routeType == "By Zone") {
              setZoneIds(res.result.routeTypeDetails.split(",").map(Number));
              setZone(
                userRegionResult?.allZones?.filter((zone) =>
                  res.result.routeTypeDetails
                    .split(",")
                    .includes(zone.zoneId.toString())
                )
              );
              setOldIDS(res.result.routeTypeDetails.split(",").map(Number));
            } else if (res.result.routeType == "By State") {
              const uniqueZones = userRegionResult?.allStates?.filter((state) =>
                res.result.routeTypeDetails
                  .split(",")
                  .includes(state.stateId.toString())
              );
              setZoneIds(
                uniqueZones
                  ?.filter((uz, index) => {
                    return (
                      index ===
                      uniqueZones.findIndex((o) => uz.zoneId == o.zoneId)
                    );
                  })
                  .map((z) => z.zoneId)
              );

              setZone(
                uniqueZones?.filter((uz, index) => {
                  return (
                    index ===
                    uniqueZones.findIndex((o) => uz.zoneName == o.zoneName)
                  );
                })
              );
              setStateIds(res.result.routeTypeDetails.split(",").map(Number));
              setState(
                userRegionResult?.allStates?.filter((state) =>
                  res.result.routeTypeDetails
                    .split(",")
                    .includes(state.stateId.toString())
                )
              );
              setOldIDS(res.result.routeTypeDetails.split(",").map(Number));
            } else if (res.result.routeType == "By City") {
              const uniqueZones = userRegionResult?.allCities?.filter((city) =>
                res.result.routeTypeDetails
                  .split(",")
                  .includes(city.cityId.toString())
              );
              setZoneIds(
                uniqueZones
                  ?.filter((uz, index) => {
                    return (
                      index ===
                      uniqueZones.findIndex((o) => uz.zoneId == o.zoneId)
                    );
                  })
                  .map((z) => z.zoneId)
              );

              setZone(
                uniqueZones?.filter((uz, index) => {
                  return (
                    index ===
                    uniqueZones?.findIndex((o) => uz.zoneName == o.zoneName)
                  );
                })
              );
              const uniqueStates = userRegionResult?.allCities?.filter((city) =>
                res.result.routeTypeDetails
                  .split(",")
                  .includes(city.cityId.toString())
              );
              setStateIds(
                uniqueStates
                  ?.filter((us, index) => {
                    return (
                      index ===
                      uniqueStates.findIndex((o) => us.stateId == o.stateId)
                    );
                  })
                  .map((s) => s.stateId)
              );

              setState(
                uniqueStates?.filter((us, index) => {
                  return (
                    index ===
                    uniqueStates?.findIndex((o) => us.stateName == o.stateName)
                  );
                })
              );
              setCityIds(res.result.routeTypeDetails.split(",").map(Number));
              setCity(
                userRegionResult?.allCities?.filter((city) =>
                  res.result.routeTypeDetails
                    .split(",")
                    .includes(city.cityId.toString())
                )
              );
              setOldIDS(res.result.routeTypeDetails.split(",").map(Number));
            } else if (res.result.routeType == "Other") {
              // const uniqueZones = userRegionResult?.allCities?.filter((city) =>
              //   res.result.routeTypeDetails
              //     .split(",")
              //     .includes(city.cityId.toString())
              // );
              setAreasIdsCity(
                res.result.routeTypeDetails.split(",").map(Number)
              );
              fetchAreasByAreaID(res.result.routeTypeDetails);
            }
          } else {
            setRouteName("");
            setFrequency("None");
            setFromDate(new Date());
            setToDate(new Date());
            setRouteType(null);
            setZone([]);
            setZoneIds([]);
            setState([]);
            setStateIds([]);
            setCity([]);
            setCityIds([]);
          }
        });
    }
  }, [props.editRouteID]);

  useEffect(() => {
    fetchDropdownByZone();
  }, [zoneIds, stateIds, clientsListByID]);

  const fetchDropdownByZone = () => {
    let stateResponse = userRegionResult.allStates;
    let cityResponse = userRegionResult.allCities;
    setStateListSingleSelect(stateResponse);
    if (zoneIds?.length > 0) {
      setStateList(
        stateResponse
          ?.filter((z) => zoneIds.includes(z.zoneId))
          ?.map((item) => item)
      );
    }
    setCityListSingleSelect(cityResponse);
    if (stateIds?.length > 0) {
      setCityList(
        cityResponse
          ?.filter((s) => stateIds.includes(s.stateId))
          ?.map((item) => item)
      );
    }
  };

  useEffect(() => {
    {
      routeType == 5 && fetchAreasByCityID();
    }
  }, [cityIds]);

  const fetchAreasByCityID = () => {
    if (cityIds?.length > 0) {
      let requestOption = {
        method: "GET",
        mode: "cors",
        headers: travelizeAuth,
      };
      fetch(
        `${baseURL}/api/CityArea/BindDropDownCityArea?CityId=${cityIds}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setAreasByCity(
              res.result?.map((area) => {
                return { value: area.cityAreaID, label: area.areaName };
              })
            );
          } else {
            setAreasByCity([]);
          }
        });
    }
    return;
  };

  const fetchAreasByAreaID = (areaID) => {
    if (areaID !== null && areaID !== "") {
      let requestOption = {
        method: "GET",
        mode: "cors",
        headers: travelizeAuth,
      };
      fetch(
        `${baseURL}/api/CityArea/BindDropDownCityArea?MultiCityAreaFilter=${areaID}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            // console.log("ROUTTTT-DETAILS:", res.result);
            setZoneIds([res.result[0].zoneId]);
            setZone([res.result[0]]);
            setStateIds([res.result[0].stateId]);
            setState([res.result[0]]);
            setCityIds([res.result[0].cityId]);
            setCity([res.result[0]]);
            setAreaNamesByCity(
              res.result.map((area) => {
                return { value: area.cityAreaID, label: area.areaName };
              })
            );
          } else {
            console.log("ERROR:", res.result);
          }
        });
    }
  };

  const [assignedUserval, setAssignedUserval] = useState("");
  const [frequencyVal, setFrequencyVal] = useState("");

  useEffect(() => {
    if (filterUserId !== "" && filterUserId !== null) {
      userDropdown.map((data) => {
        if (filterUserId == data.value) {
          setAssignedUserval(data);
        }
      });
    }

    if (frequency !== "" && frequency !== null) {
      frequencies.map((item) => {
        if (frequency == item.value) {
          setFrequencyVal(item);
        }
      });
    }
  }, [filterUserId, frequency]);

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  const handleRouteType = (value) => {
    setRouteType(value);
    setClientsListByID(null);
  };

  useLayoutEffect(() => {
    if (userRegionResult) {
      setZoneList(userRegionResult.allZones);
    } else {
      setZoneList([]);
    }
  }, [userRegionResult]);

  useEffect(() => {
    if (routeType == 1) {
      if (!oldIds.every((data) => zoneIds.includes(data))) {
        toast.error(`You Can't Delete the old Zones`);
        setZoneIds(oldIds);
      }
    } else if (routeType == 2) {
      if (!oldIds.every((data) => stateIds.includes(data))) {
        toast.error(`You Can't Delete the old States`);
        setStateIds(oldIds);
      }
    } else if (routeType == 3) {
      if (!oldIds.every((data) => cityIds.includes(data))) {
        toast.error(`You Can't Delete the old Cities`);
        setCityIds(oldIds);
      }
    }
  }, [zoneIds, stateIds, cityIds]);

  const handleChangeZone = (selectedList) => {
    setZone(selectedList);
    setZoneIds(selectedList.map((z) => z.zoneId));
  };

  const handleChangeState = (selectedList) => {
    setState(selectedList);
    setStateIds(selectedList.map((s) => s.stateId));
  };

  const handleChangeCity = (selectedList) => {
    setCity(selectedList);
    setCityIds(selectedList.map((s) => s.cityId));
  };

  const handleChangeArea = (selectedList) => {
    setAreaNamesByCity(selectedList);
    setAreasIdsCity(selectedList.map((s) => s.value));
  };

  const handleChangeCategoryClient = (selectedList) => {
    setCategoryNames(selectedList);
    setCategoryID(selectedList.map((cat) => cat.value));
  };

  const handleChangeStateForClient = (selectedList) => {
    setStateForClient(selectedList);
    setStateIdsForClient(selectedList.map((s) => s.stateId));
  };

  const handleChangeCityForClient = (selectedList) => {
    setCityForClient(selectedList);
    setCityIdsForClient(selectedList.map((s) => s.cityId));
  };

  useEffect(() => {
    getClientsDataByFilters();
  }, [categoryID, stateIdsForClient, cityIdsForClient, userIdForClient]);

  const getClientsDataByFilters = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/Client/Get?PageSize=13664025&CurrentPage=1&EnabledStatus=1&CategoryID=${
        categoryID[0] ?? 0
      }&MultiCategoryFilter=${categoryID?.toString() ?? ""}${
        clientsListByID == 1
          ? `&UserID=${userIdForClient}`
          : clientsListByID == 2
          ? `&Stateid=${stateIdsForClient[0] ?? 0}&MultiStateFilter=${
              stateIdsForClient.toString() ?? ""
            }`
          : clientsListByID == 3
          ? `&CityID=${cityIdsForClient[0] ?? 0}&MultiCityFilter=${
              cityIdsForClient.toString() ?? ""
            }`
          : ""
      }`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setClientList(
            res.result?.map((client) => {
              return { value: client.clientID, label: client.clientName };
            })
          );
          setClientLoading(false);
        } else {
          setClientList([]);
          setClientLoading(false);
        }
      });
  };

  // MULTI SELECT DROPDOWN FUNCTION START
  const handleSelectClient = (selectedList) => {
    setClients(selectedList);
    setClientId(selectedList.map((c) => c.value));
  };
  // MULTI SELECT DROPDOWN FUNCTION END

  const createRouteWithoutClient = (e) => {
    e.preventDefault();
    if (
      routeName && fromDate && toDate && routeType && props.editRouteID == null
        ? (routeType == 1 && zoneIds.length > 0) ||
          (routeType == 2 && stateIds.length > 0) ||
          (routeType == 3 && cityIds.length > 0) ||
          (routeType == 5 && areasIdsCity.length > 0)
        : routeName &&
          fromDate &&
          toDate &&
          routeType &&
          props.editRouteID !== null &&
          ((routeType == 1 && zoneIds.length > 0) ||
            (routeType == 2 && stateIds.length > 0) ||
            (routeType == 3 && cityIds.length > 0) ||
            (routeType == 5 && areasIdsCity.length > 0) ||
            (routeType == 4 && filterUserId !== loginDetails.userId))
    ) {
      let newVisitData = {
        routeID: props.editRouteID ?? 0,
        subscriptionID: subscriptionID,
        name: routeName,
        frequency: frequency,
        addedByID: filterUserId !== "" ? filterUserId : loginDetails.userId,
        status: 1,
        fromDate: moment(fromDate).format("DD/MM/YYYY"),
        toDate: moment(toDate).format("DD/MM/YYYY"),
        routeClient: [],
        routeType:
          routeType == 1
            ? "By Zone"
            : routeType == 2
            ? "By State"
            : routeType == 3
            ? "By City"
            : routeType == 4
            ? "By Company"
            : "Other",
        routeTypeDetails:
          routeType == 1
            ? zoneIds?.toString()
            : routeType == 2
            ? stateIds?.toString()
            : routeType == 3
            ? cityIds?.toString()
            : areasIdsCity?.toString(),
      };
      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(newVisitData),
      };
      if (props.editRouteID == null) {
        fetch(`${baseURL}/api/Route/AddRoute`, requestoption)
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              props.handleClose();
              props.fetchRoutePlans();
              swal.showAlert(
                "Success",
                `Route Plan Added ${res.message}`,
                "success"
              );
            } else {
              toast.error(`${res.errorMessage}`);
            }
          })
          .catch((error) => {
            // console.log("ERROR :", error);
            toast.error(`${error}`);
          });
      } else {
        fetch(`${baseURL}/api/Route/UpdateRoute`, requestoption)
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              props.handleClose();
              props.fetchRoutePlans();
              swal.showAlert(
                "Success",
                `Route Plan Added ${res.message}`,
                "success"
              );
            } else {
              toast.error(`${res.errorMessage}`);
            }
          })
          .catch((error) => {
            // console.log("ERROR :", error);
            toast.error(`${error}`);
          });
      }
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required",
      });
    }
  };

  const goToNextModel = (e) => {
    e.preventDefault();
    if (routeName && clientId.length !== 0 && fromDate && toDate) {
      let routeClientData = [];
      for (let ids of clientId) {
        routeClientData.push({
          addedByID: filterUserId !== "" ? filterUserId : loginDetails.userId,
          clientID: ids,
          meetingDate: "",
          meetingTime: "",
          purposeID: 0,
          status: 1,
          subscriptionID: subscriptionID,
          userId: filterUserId !== "" ? filterUserId : loginDetails.userId,
        });
      }
      const clietsNDates = {
        subscriptionID: subscriptionID,
        name: routeName,
        frequency: frequency,
        addedByID: filterUserId !== "" ? filterUserId : loginDetails.userId,
        status: 1,
        fromDate: moment(fromDate).format("DD/MM/YYYY"),
        toDate: moment(toDate).format("DD/MM/YYYY"),
        routeClient: routeClientData,
      };

      let filterdUser = userDropdown.filter((usrlst) =>
        filterUserId.includes(usrlst.value)
      );
      setRouteUserName(filterdUser[0]?.label);
      setFilterdClientsIds(clientId);
      setClientsData(clietsNDates);
      setFromDateofRoute(fromDate);
      setToDateofRoute(toDate);
      setNextModel(true);
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Please select Clients",
      });
    }
  };

  const closeModel = () => {
    props.handleClose();
  };

  const handleClose = () => {
    setNextModel(false);
  };

  return (
    <AddEditFormComponent
      handleClose={nextModel ? handleClose : closeModel}
      formName={
        nextModel
          ? `Set Client Visit Date-Time (User : ${routeUserName ?? ""})`
          : `${props.editRouteID ? "Edit Route Plan" : "Create Route Plan"}`
      }
      formSubTitle={"Please enter the route plan details."}
      sidebarItems={["Add Route"]}
      childrens={
        !nextModel ? (
          <>
            <form
              onSubmit={
                routeType == 4 && props.editRouteID == null
                  ? goToNextModel
                  : createRouteWithoutClient
              }
            >
              <div className="container add-edit add-route-page">
                <div className="add-route-plan-model">
                  <Row className="model-inputs mb-3">
                    <Col>
                      <div>
                        <FloatingLabel
                          label="Enter Route Name"
                          className={`${
                            routeName
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                          style={{ width: "100%" }}
                        >
                          <Form.Control
                            type="text"
                            className="change-model"
                            placeholder="Route Name"
                            autoComplete="off"
                            value={routeName}
                            onChange={(e) => setRouteName(e.target.value)}
                            // required
                          />
                        </FloatingLabel>
                        {fieldReq && routeName == "" ? (
                          <small
                            id={`Error${alertMessage.alertType}`}
                            className="form-text text-muted "
                          >
                            Required !
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <FloatingLabel
                          label="Select Frequency"
                          className={`${
                            frequency
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Select
                            className="react-select-container-list model-select"
                            classNamePrefix="react-select-list"
                            defaultValue={
                              props.editRouteID !== null
                                ? frequencyVal
                                : frequencies[0]
                            }
                            options={frequencies}
                            placeholder="Select Frequency"
                            value={frequencyVal}
                            onInputChange={frequencies.label}
                            onChange={(data) => setFrequency(data.value)}
                            isSearchable={true}
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </Col>
                  </Row>
                  <Row className="model-inputs mb-3 align-center">
                    <Col>
                      <div>
                        <FloatingLabel
                          label="From - To Date"
                          className={`${
                            fromDate || toDate
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <div className="meeting-date-picker-with-icon">
                            <DatePicker
                              selected={fromDate}
                              className="select-add-meeting-model meeting-date"
                              id="date-range-picker"
                              dateFormat="dd/MM/yyyy"
                              selectsRange
                              startDate={fromDate}
                              endDate={toDate}
                              onChange={(date) => setSelectSearchDrop(date)}
                              minDate={new Date()}
                              // maxDate={oneWeekDate}
                              autoComplete="off"
                            />
                            <i className="fa fa-calendar-o calander-icon" />
                          </div>
                        </FloatingLabel>
                      </div>
                    </Col>
                    <Col>
                      <div className="radio-label color-green mb-1">
                        Route Type{" "}
                        {fieldReq && routeType === null ? (
                          <small
                            id={`Error${alertMessage.alertType}`}
                            className="form-text text-muted "
                          >
                            Please select below option
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="model-inputs dflex-j-start">
                        {routeTypes.map((item, i) => (
                          <div key={i}>
                            <Form.Check
                              inline
                              label={item?.label}
                              value={item?.value}
                              name="maptype"
                              type="radio"
                              id={`inlineradio-maptype-${i}`}
                              onChange={(e) =>
                                handleRouteType(
                                  props.editRouteID == null
                                    ? e.target.value
                                    : routeType
                                )
                              }
                              checked={item?.value == routeType ? true : false}
                            />
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>

                  {routeType == 4 && props.editRouteID == null && (
                    <>
                      <Row className="model-inputs mb-3 align-center">
                        <Col>
                          <div>
                            <FloatingLabel
                              label="Categories"
                              className={`${
                                categoryID
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                              }`}
                            >
                              <Multiselect
                                className="select-add-user-model zone model-select"
                                options={categoryDropdown} // Options to display in the dropdown
                                selectedValues={categoryNames} // Preselected value to persist in dropdown
                                onSelect={handleChangeCategoryClient} // Function will trigger on select event
                                onRemove={handleChangeCategoryClient} // Function will trigger on remove event
                                showCheckbox={true}
                                displayValue="label" // Property name to display in the dropdown options
                                placeholder={"Select Category"}
                                emptyRecordMsg="No options available"
                                avoidHighlightFirstOption={true}
                                closeIcon="cancel"
                                showArrow={true}
                                closeOnSelect={true}
                                hidePlaceholder={true}
                              />
                            </FloatingLabel>
                          </div>
                        </Col>
                        <Col>
                          <div className="radio-label color-green mb-1">
                            Company List By{" "}
                          </div>
                          <div className="model-inputs dflex-j-start">
                            {clientsListBy.map((item, i) => (
                              <div key={i}>
                                <Form.Check
                                  inline
                                  label={item?.label}
                                  value={item?.value}
                                  name="clientsby"
                                  type="radio"
                                  id={`inlineradio-clientsby-${i}`}
                                  onChange={(e) =>
                                    setClientsListByID(e.target.value)
                                  }
                                  checked={
                                    item?.value == clientsListByID
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  <>
                    {clientsListByID == 1 ? (
                      <Row className="mb-3">
                        <Col>
                          <div>
                            <FloatingLabel
                              label="Users"
                              className={`${
                                routeType
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                              }`}
                            >
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                options={userDropdown}
                                placeholder="Select User"
                                value={
                                  userIdForClient ? userDropdown.value : ""
                                }
                                onInputChange={userDropdown?.label}
                                onChange={(data) =>
                                  setUserIdForClient(data.value)
                                }
                                isSearchable={true}
                                // required
                              />
                            </FloatingLabel>
                          </div>
                        </Col>
                        <Col></Col>
                      </Row>
                    ) : clientsListByID == 2 ? (
                      <Row className="mb-3">
                        <Col>
                          <div>
                            <FloatingLabel
                              label="States"
                              className={`${
                                routeType
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                              }`}
                            >
                              <Multiselect
                                className="select-add-user-model zone model-select"
                                options={stateListSingleSelect} // Options to display in the dropdown
                                selectedValues={stateForClient} // Preselected value to persist in dropdown
                                onSelect={handleChangeStateForClient} // Function will trigger on select event
                                onRemove={handleChangeStateForClient} // Function will trigger on remove event
                                showCheckbox={true}
                                displayValue="stateName" // Property name to display in the dropdown options
                                placeholder={"Select States"}
                                emptyRecordMsg="No options available"
                                avoidHighlightFirstOption={true}
                                closeIcon="cancel"
                                groupBy="zoneName"
                                showArrow={true}
                                closeOnSelect={true}
                                hidePlaceholder={true}
                              />
                            </FloatingLabel>
                          </div>
                        </Col>
                        <Col></Col>
                      </Row>
                    ) : clientsListByID == 3 ? (
                      <Row className="mb-3">
                        <Col>
                          <div>
                            <FloatingLabel
                              label="Cities"
                              className={`${
                                routeType
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                              }`}
                            >
                              <Multiselect
                                className="select-add-user-model zone model-select"
                                options={cityListSingleSelect} // Options to display in the dropdown
                                selectedValues={cityForClient} // Preselected value to persist in dropdown
                                onSelect={handleChangeCityForClient} // Function will trigger on select event
                                onRemove={handleChangeCityForClient} // Function will trigger on remove event
                                showCheckbox={true}
                                displayValue="cityName" // Property name to display in the dropdown options
                                placeholder={"Select City"}
                                emptyRecordMsg="No options available"
                                avoidHighlightFirstOption={true}
                                closeIcon="cancel"
                                groupBy="stateName"
                                showArrow={true}
                                closeOnSelect={true}
                                hidePlaceholder={true}
                              />
                            </FloatingLabel>
                          </div>
                        </Col>
                        <Col></Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </>
                  {/* <Row> */}
                  <div className="d-flex wrap-items-gap align-baseline">
                    {(routeType == 1 ||
                      routeType == 2 ||
                      routeType == 3 ||
                      routeType == 5) && (
                      <div className="row mb-3">
                        <FloatingLabel
                          label="Zones"
                          className={`${
                            zone?.length > 0
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                          style={{ maxWidth: "250px" }}
                        >
                          <Multiselect
                            className="select-add-user-model zone model-select"
                            options={zoneList} // Options to display in the dropdown
                            selectedValues={zone} // Preselected value to persist in dropdown
                            onSelect={handleChangeZone} // Function will trigger on select event
                            onRemove={handleChangeZone} // Function will trigger on remove event
                            showCheckbox={true}
                            displayValue="zoneName" // Property name to display in the dropdown options
                            placeholder={"Select Zone"}
                            emptyRecordMsg="No options available"
                            avoidHighlightFirstOption={true}
                            closeIcon="cancel"
                            showArrow={true}
                            selectionLimit={3}
                            closeOnSelect={true}
                            hidePlaceholder={true}
                            disable={routeType == 5 && props.editRouteID}
                          />
                        </FloatingLabel>
                        {fieldReq && (zoneIds?.length == 0 || zoneIds == "") ? (
                          <small
                            id={`Error${alertMessage.alertType}`}
                            className="form-text text-muted "
                          >
                            Please select zone
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    {(routeType == 2 || routeType == 3 || routeType == 5) && (
                      <div className="row mb-3">
                        <FloatingLabel
                          label="States"
                          className={`${
                            state?.length > 0
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                          style={{ maxWidth: "250px" }}
                        >
                          <Multiselect
                            className="select-add-user-model"
                            options={stateList} // Options to display in the dropdown
                            selectedValues={state} // Preselected value to persist in dropdown
                            onSelect={handleChangeState} // Function will trigger on select event
                            onRemove={handleChangeState} // Function will trigger on remove event
                            showCheckbox={true}
                            displayValue="stateName" // Property name to display in the dropdown options
                            placeholder={"Select States"}
                            emptyRecordMsg="No options available"
                            avoidHighlightFirstOption={true}
                            closeIcon="cancel"
                            groupBy="zoneName"
                            showArrow={true}
                            hidePlaceholder={true}
                            disable={routeType == 5 && props.editRouteID}
                          />
                        </FloatingLabel>
                        {fieldReq &&
                        (stateIds?.length == 0 || stateIds == "") ? (
                          <small
                            id={`Error${alertMessage.alertType}`}
                            className="form-text text-muted "
                          >
                            Please select state
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    {(routeType == 3 || routeType == 5) && (
                      <div className="row mb-3">
                        <FloatingLabel
                          label="Cities"
                          className={`${
                            city?.length > 0
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                          style={{ maxWidth: "350px" }}
                        >
                          <Multiselect
                            className="select-add-user-model"
                            options={cityList} // Options to display in the dropdown
                            selectedValues={city} // Preselected value to persist in dropdown
                            onSelect={handleChangeCity} // Function will trigger on select event
                            onRemove={handleChangeCity} // Function will trigger on remove event
                            showCheckbox={true}
                            displayValue="cityName" // Property name to display in the dropdown options
                            placeholder={"Select Cities"}
                            emptyRecordMsg="No options available"
                            avoidHighlightFirstOption={true}
                            closeIcon="cancel"
                            groupBy="stateName"
                            showArrow={true}
                            hidePlaceholder={true}
                            selectionLimit={routeType == 5 ? 1 : 100}
                            disable={routeType == 5 && props.editRouteID}
                          />
                        </FloatingLabel>
                        {fieldReq && (cityIds?.length == 0 || cityIds == "") ? (
                          <small
                            id={`Error${alertMessage.alertType}`}
                            className="form-text text-muted "
                          >
                            Please select city
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                  {/* </Row> */}
                  {routeType == 4 &&
                    props.editRouteID == null &&
                    (clientLoading ? (
                      ""
                    ) : (
                      <div>
                        <Row className="model-inputs mb-3">
                          <Col>
                            <FloatingLabel
                              label="Company"
                              className={`${
                                clientId?.length > 0
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                              }`}
                            >
                              <MultiSelect
                                className="select-add-user-model"
                                options={clientList}
                                value={clients}
                                onChange={handleSelectClient}
                                labelledBy={
                                  clientLoading ? "Loading..." : "Select"
                                }
                              />
                            </FloatingLabel>
                            {fieldReq &&
                            (clients?.length == 0 || clients == "") ? (
                              <small
                                id={`Error${alertMessage.alertType}`}
                                className="form-text text-muted "
                              >
                                Please select clients
                              </small>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col></Col>
                        </Row>
                      </div>
                    ))}
                  {routeType == 5 && (
                    <Row className="model-inputs mb-3">
                      <Col>
                        <div>
                          <FloatingLabel
                            label="Area"
                            className={`${
                              areasIdsCity?.length > 0
                                ? "float-visible-select"
                                : "float-hidden float-select"
                            }`}
                          >
                            <MultiSelect
                              className="select-add-user-model"
                              options={areasByCity}
                              value={areaNamesByCity}
                              onChange={handleChangeArea}
                              labelledBy={"Select"}
                            />
                          </FloatingLabel>
                          {fieldReq &&
                          (areasIdsCity?.length == 0 || areasIdsCity == "") ? (
                            <small
                              id={`Error${alertMessage.alertType}`}
                              className="form-text text-muted "
                            >
                              Please select area
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      <Col></Col>
                    </Row>
                  )}
                  <Row className="model-inputs mb-3">
                    <Col>
                      <div>
                        <FloatingLabel
                          label="Select User"
                          className={`${
                            filterUserId
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Select
                            className="react-select-container-list model-select"
                            classNamePrefix="react-select-list"
                            options={userDropdown}
                            placeholder="Select User"
                            value={assignedUserval}
                            onInputChange={userDropdown.label}
                            onChange={(data) => setFilterUserId(data.value)}
                            isSearchable={true}
                          />
                        </FloatingLabel>
                        {fieldReq &&
                        props.editRouteID !== null &&
                        filterUserId == loginDetails.userId ? (
                          <small
                            id={`Error${alertMessage.alertType}`}
                            className="form-text text-muted "
                          >
                            Please assign to User
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col></Col>
                  </Row>
                </div>
                <div className="add-page-save-cancel">
                  <SBSaveUpdateBtn
                    btnName={
                      routeType == 4 && props.editRouteID == null
                        ? "Next"
                        : "Save"
                    }
                  />
                  <SbCancelBtn
                    btnName="Cancel"
                    onClickEffect={props.handleClose}
                  />
                </div>
              </div>
            </form>
          </>
        ) : (
          nextModel && (
            <>
              <SetClientsMeetDate
                handleClose={closeModel}
                clientsData={clientsData}
                filterdClientsIds={filterdClientsIds}
                routeUserName={routeUserName}
                fromDateofRoute={fromDateofRoute}
                toDateofRoute={toDateofRoute}
                fetchRoutePlans={props.fetchRoutePlans()}
                closeModel={closeModel}
              />
            </>
          )
        )
      }
    />
  );
}
