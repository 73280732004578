import React, { useEffect, useLayoutEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import GoogleMapComp from "../GoogleMapComp";
import { baseURL } from "../BaseUrl";
import OnScrollLoading from "../OnScrollLoading";
import { loadCityList, loadStateList } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import NoDataImg from "../../images/no-data-found.png";
import OpenStreenMap from "../OpenStreenMap";

export default function AllClientsInMap(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  let dispatch = useDispatch();
  let { cityList } = useSelector((state) => state.data);
  let { stateList } = useSelector((state) => state.data);

  const [clientsData, setClientsData] = useState([]);
  const [centerLatLng, setCenterLatLng] = useState({
    lat: null,
    lng: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [clientSearchVal, setClientSearchVal] = useState("");
  const [cityId, setCityId] = useState("0");
  const [stateId, setStateId] = useState("0");

  useLayoutEffect(() => {
    dispatch(loadCityList(0));
    dispatch(loadStateList(0));
  }, [props.openClientsInMap]);

  useEffect(() => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Client/GetAllClientMap?Text=${clientSearchVal}&CityID=${cityId}&Stateid=${stateId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("CLIENTS:::", res.result.length);
          setClientsData(
            res.result.filter(
              (data) =>
                data.clientLoc.latitude !== null &&
                data.clientLoc.longitude !== null &&
                data.clientLoc.latitude !== 0 &&
                data.clientLoc.longitude !== 0 &&
                data.clientLoc.latitude !== "" &&
                data.clientLoc.longitude !== ""
            )
          );
          let centerLatitudeLongitude = {
            lat: +res.result[0]?.clientLoc?.latitude,
            lng: +res.result[res.result?.length - 1]?.clientLoc?.longitude,
          };
          setCenterLatLng(centerLatitudeLongitude);
          setIsLoading(false);
        } else {
          setClientsData([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [clientSearchVal, cityId, stateId]);

  // console.log("CENTER-LAT-LNG::", cityId, clientsData, centerLatLng);

  return (
    <div>
      {" "}
      <Modal
        show={props.openClientsInMap}
        // onHide={props.openGoogleMaps}
        className="main_model google-maps-route-model p-0"
      >
        <div>
          <div className="add-user-heading">
            <h6 className="add-user-head">CLIENTS IN MAP</h6>
            <div className="search-bars hv-center">
              <div className="input-div" style={{ marginRight: "10px" }}>
                <input
                  type="search"
                  id="metricInfo_search_bar"
                  placeholder="Search Client By Name"
                  className="form-control setting-input"
                  value={clientSearchVal}
                  onChange={(e) => setClientSearchVal(e.target.value)}
                />
                <i className="fa fa-search search-icon" />
              </div>
              {/* <div className="input-div" style={{ marginLeft: "10px" }}>
                <Select
                  className="react-select-container-list"
                  classNamePrefix="react-select-list"
                  options={stateList}
                  placeholder="Select State"
                  value={stateList.value}
                  onInputChange={stateList.label}
                  onChange={(data) => setStateId(data.value)}
                  isSearchable={true}
                />
              </div> */}
              <div className="input-div" style={{ marginLeft: "10px" }}>
                <Select
                  className="react-select-container-list"
                  classNamePrefix="react-select-list"
                  options={cityList}
                  placeholder="Select City"
                  value={cityList.value}
                  onInputChange={cityList.label}
                  onChange={(data) => setCityId(data.value)}
                  isSearchable={true}
                />
              </div>
            </div>
            <i
              className="fa fa-times"
              onClick={props.showAllClientsInMapModal}
            />
          </div>

          {isLoading ? (
            <>
              <OnScrollLoading />
            </>
          ) : (
            <div className="google-map-box">
              {clientsData.length > 0 ? (
                // <GoogleMapComp
                //   centerLatLng={centerLatLng}
                //   clientsLocations={clientsData}
                //   pageType="AllClientsInMap"
                // />
                <OpenStreenMap
                  centerLatLng={centerLatLng}
                  clientsLocations={clientsData}
                  pageType="AllClientsInMap"
                />
              ) : (
                <div className="iframe-part">
                  <div className="hv-center">
                    <img
                      src={NoDataImg}
                      alt="no-data-img"
                      className="mCS_img_loaded"
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
