import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../Skeleton Loading/SkeletonStdTable";
import { baseURL } from "../BaseUrl";
import { travelizeAuth } from "../HeaderAuthentication";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import { loadUserDropdown } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { MiClearButton } from "../SbButtons/SbCancelBtn";
import ViewFormDataModel from "./ViewFormDataModel";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";

export default function FormsDataByUser(props) {
  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);

  const [isLoading, setIsLoading] = useState(true);
  const [formsDataUser, setFormsDataUser] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [searchVal, setSearchVal] = useState("");
  const [viewFormData, setViewFormData] = useState(false);
  const [tableId, setTableId] = useState("");
  const [customPageId, setCustomPageId] = useState(0);
  const [userDetail, setUserDetail] = useState("");
  const [loading, setLoading] = useState(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  useEffect(() => {
    dispatch(loadUserDropdown());
  }, []);

  useEffect(() => {
    fetchCustomFormNames();
  }, [
    filterUserId,
    searchVal,
    fromDate,
    toDate,
    PageSize,
    currentPage,
    props.customPageID,
  ]);

  const fetchCustomFormNames = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/CustomeFieldValue/GetFormGroup?UserId=${filterUserId}&TableID=${searchVal}&PageSize=${PageSize}&CurrentPage=${currentPage}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&Status=1&CustomePagesID=${props.customPageID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setFormsDataUser(res.result);
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setFormsDataUser([]);
          setIsLoading(false);
          setLoading(false);
        }
      });
  };

  const handleClose = () => {
    setViewFormData(false);
  };

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setFromDate(new Date());
    setToDate(new Date());
  };
  //* CLEAR SELECT END *//

  return (
    <div>
      <div id="main-page">
        <>
          {/* MAIN BODY START */}
          <div className="customforms-n-names-data">
            {/* Filter and Header Section */}
            {props.filter && (
              <div className="page-filter-section">
                <div className="dflex-j-between" style={{ width: "100%" }}>
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={props.backToFormPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">
                      {" "}
                      {props.formName ?? ""}{" "}
                    </h6>
                  </div>

                  <div className="filter-row-input-boxes-end">
                    <div className="input-div">
                      <input
                        type="search"
                        placeholder="Search Form ID"
                        className="form-control setting-input"
                        value={searchVal}
                        onChange={(e) => setSearchVal(e.target.value)}
                      />
                      <i className="fa fa-search search-icon" />
                    </div>
                    <div>
                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        options={userDropdown}
                        placeholder="Select User"
                        value={filterUserId == "" ? "" : userDropdown.value}
                        onInputChange={userDropdown.label}
                        onChange={(data) => setFilterUserId(data.value)}
                        isSearchable={true}
                      />
                    </div>
                    <div className="from-to-date-select">
                      <div className="input-group-text" id="from-label">
                        From - To
                      </div>
                      <div className="date-picker-with-icon">
                        <DatePicker
                          selected={fromDate}
                          className="select from-date date-filter"
                          id="date-range-picker"
                          dateFormat="dd/MM/yyyy"
                          selectsRange
                          startDate={fromDate}
                          endDate={toDate}
                          onChange={(date) => setSelectSearchDrop(date)}
                          maxDate={new Date()}
                        />
                        <div>
                          <i
                            className="fa fa-calendar-o calander-icon"
                            style={{ right: "6px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <MiClearButton
                      onClickEffect={clearSerachBySelect}
                      btnName="Clear"
                    />
                  </div>
                </div>
              </div>
            )}
            {/* Filter and Header Section */}

            <div className="customforms-table">
              <div
                className="table-responsive routes-scrollbar"
                onScroll={(e) =>
                  handleScroll(
                    e,
                    formsDataUser,
                    totalData,
                    setLoading,
                    setPageSize,
                    PageSize
                  )
                }
              >
                <table className="table routes-table-data">
                  <thead className="thead-light routes-thead">
                    {isLoading ? (
                      <>
                        <SkeletonStdTableHead />
                      </>
                    ) : (
                      <tr>
                        <th>SL.NO.</th>
                        <th>FORM ID</th>
                        <th>ADDED BY</th>
                        <th>ADDED ON</th>

                        <th style={{ textAlign: "center" }}>ACTION</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <>
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                      </>
                    ) : formsDataUser != "" ? (
                      formsDataUser.map((data, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td> {data?.tableID ?? "NA"}</td>
                          <td>
                            {data?.pageDataValues[0]?.addedByUserFullName}
                          </td>
                          <td>
                            {moment(
                              data?.pageDataValues[0]?.addedOn,
                              "DD/MM/YYYY"
                            ).format("DD MMM YYYY")}
                          </td>

                          <td style={{ textAlign: "center" }}>
                            <div className="user-action-td">
                              <span
                                className="user-edit-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setViewFormData(true);
                                  setCustomPageId(data.customePageID);
                                  setTableId(data.tableID);
                                  setUserDetail({
                                    tableId: data?.tableID,
                                    name: data?.pageDataValues[0]
                                      ?.addedByUserFullName,
                                    date: moment(
                                      data?.pageDataValues[0]?.addedOn,
                                      "DD/MM/YYYY"
                                    ).format("DD MMM YYYY"),
                                  });
                                }}
                              >
                                <i className="bi bi-eye-fill"></i> View
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          {" "}
                          --- NO DATA FOUND ---{" "}
                        </td>
                      </tr>
                    )}
                    {loading && <OnScrollLoading />}
                  </tbody>
                </table>
              </div>
              <div className="download-and-pagination">
                <SbDownloadBtn
                // onClickEffect={}
                // btnName="Download"
                />
              </div>
            </div>
          </div>
        </>
        {viewFormData ? (
          <ViewFormDataModel
            viewFormData={viewFormData}
            handleClose={handleClose}
            tableId={tableId}
            customPageId={customPageId}
            userDetail={userDetail}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
