import React, { useState, useEffect, useMemo, useRef } from "react";
import AddEditFormComponent from "../../ReUseComponents/AddEditFormComponent";
import { FloatingLabel, Form } from "react-bootstrap";
import Select from "react-select";
import * as swal from "../../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/VhdPurchaseOrder.scss";
import { MiCustomBtn, SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { enableYesNoOptions } from "../../DropdownsData";
import DatePicker from "react-datepicker";
import { baseURL } from "../../BaseUrl";
import { loginSubscriptionID } from "../../LoginRole";
import { travelizeAuth } from "../../HeaderAuthentication";

export default function AddEditPOModel(props) {
  const [poDate, setPODate] = useState(new Date());
  const [lineItemsModule, setLineItemsModule] = useState(false);
  const [file, setFile] = useState("");

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const poExtraLineItemData = {
    item_ID: "",
    item_Description: "",
    quantity_Ordered: "",
    unit_Price: "",
    total_Price: "",
    discount: "",
    tax: "",
    line_Total: "",
    flag: "",
    quantity_Received: "",
    tax_Code: "",
    tax_Per: "",
    tax_Amount: "",
    gross_Total: "",
    uom: "",
    subscriptionID: loginSubscriptionID,
  };

  const [poLineItems, setPOLineItems] = useState([
    {
      item_ID: "",
      item_Description: "",
      quantity_Ordered: "",
      unit_Price: "",
      total_Price: "",
      discount: "",
      tax: "",
      line_Total: "",
      flag: "",
      quantity_Received: "",
      tax_Code: "",
      tax_Per: "",
      tax_Amount: "",
      gross_Total: "",
      uom: "",
      subscriptionID: loginSubscriptionID,
    },
  ]);

  const [purchaseOrderData, setPurchaseOrderData] = useState({
    // po_id: 0,
    // vendorRef_No: "",
    vendor_id: "",
    po_Number: "",
    po_date: moment(new Date()).format("DD/MM/YYYY"),
    pO_Value: "",
    po_status: "Pending",
    tax_Amount: "",
    total_amount: "",
    fileContent: "",
    attachment: "",
    currency: "",
    shipping_Address: "",
    billing_Address: "",
    remarks: "",
    subscriptionId: loginSubscriptionID,
    poLineItemDetail: [],
  });

  const {
    // vendorRef_No,
    vendor_id,
    po_Number,
    po_date,
    pO_Value,
    po_status,
    tax_Amount,
    total_amount,
    approved_By,
    fileContent,
    attachment,
    shipping_Address,
    billing_Address,
    remarks,
  } = purchaseOrderData;

  useEffect(() => {
    setPurchaseOrderData({
      ...purchaseOrderData,
      ["poLineItemDetail"]: [...poLineItems],
    });
  }, [poLineItems]);

  // FILE TTACHMENT
  const hiddenFileInput = useRef(null);

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setFile(result.toString().replace(/^data:(.*,)?/, ""));
        setPurchaseOrderData({
          ...purchaseOrderData,
          ["fileContent"]: result.toString().replace(/^data:(.*,)?/, ""),
        });
      }
    });
  };

  const fileToBase64 = (upldfile, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(upldfile);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  // console.log("FILE:", file);
  // FILE TTACHMENT

  const addAnotherRow = () => {
    setPOLineItems([...poLineItems, poExtraLineItemData]);
  };

  const removeRow = (i) => {
    let finalLineItems = poLineItems.filter((data, index) => index !== i);
    setPOLineItems(finalLineItems);
  };

  // console.log("ROW ADDED", poLineItems);

  const handleInputData = (e) => {
    const { name, value } = e.target;
    setPurchaseOrderData({ ...purchaseOrderData, [name]: value });
  };

  const handleInputDataLineItems = (e, index) => {
    const { name, value } = e.target;
    let filteredLineItem = poLineItems.filter((data, i) => index === i);
    filteredLineItem[0][name] = value;
    setPOLineItems([...poLineItems]);
    setPurchaseOrderData({
      ...purchaseOrderData,
      ["poLineItemDetail"]: [...poLineItems],
    });
  };

  const handleSelectChange = (data, type) => {
    switch (type) {
      case "po_date":
        setPODate(data);
        setPurchaseOrderData({
          ...purchaseOrderData,
          [type]: moment(data).format("DD/MM/YYYY"),
        });
        break;
      default:
        setPurchaseOrderData({ ...purchaseOrderData, [type]: data });
    }
  };

  const goToPurchaseOrderLineItems = () => {
    if (
      vendor_id &&
      po_Number &&
      po_date &&
      pO_Value &&
      tax_Amount &&
      total_amount &&
      fileContent &&
      shipping_Address &&
      billing_Address
    ) {
      setLineItemsModule(true);
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required !",
      });
    }
  };

  const backToPrevious = () => {
    setLineItemsModule(false);
  };

  const addPurchaseOrderDetails = (e) => {
    e.preventDefault();
    // console.log(
    //   "TEST-LINEITEMS:",
    //   poLineItems.every(
    //     (data) =>
    //       data.item_Description &&
    //       data.quantity_Ordered &&
    //       data.unit_Price &&
    //       data.line_Total &&
    //       data.tax_Per &&
    //       data.tax &&
    //       data.gross_Total
    //   )
    // );
    if (
      poLineItems.every(
        (data) =>
          data.item_Description &&
          data.quantity_Ordered &&
          data.unit_Price &&
          data.line_Total &&
          data.tax_Per &&
          data.tatax_Amountx &&
          data.gross_Total
      )
    ) {
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(purchaseOrderData),
      };
      fetch(`${baseURL}/api/VHD/AddPO`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            swal.showAlert("Success", `${res.message}`, "success");
            props.fetchPODetails();
            closeModel();
          } else {
            toast.error(res.errorMessage, { autoClose: 5000 });
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required !",
      });
    }
  };

  const closeModel = () => {
    props.handleClose();
    props.setPurchaseOrderID(null);
  };

  return (
    <div>
      <AddEditFormComponent
        handleClose={closeModel}
        formName={"Purchase Order"}
        formSubTitle={
          props.purchaseOrderID
            ? "Edit and update the purchase order details."
            : "Please fill the purchase order details."
        }
        sidebarItems={["Purchase Order"]}
        childrens={
          <>
            {!lineItemsModule ? (
              <form>
                <div className="add-po_headers-to-vhd">
                  <div className="add-po_headers-details">
                    <div className="row">
                      <div className="col my-2">
                        <FloatingLabel
                          label="PO Date"
                          className={`${
                            po_date
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <DatePicker
                            selected={poDate}
                            className="form-control form add-input"
                            dateFormat="dd/MM/yyyy"
                            placeholder="PO Date"
                            onChange={(date) =>
                              handleSelectChange(date, "po_date")
                            }
                            minDate={new Date()}
                            // maxDate={new Date()}
                          />
                        </FloatingLabel>
                        {fieldReq && (po_date == null || po_date == "") ? (
                          <small id={`Error`} className="form-text text-muted ">
                            {alertMessage.message}&nbsp;
                            <i className="fa fa-exclamation-circle" />
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="PO Number"
                          className={`${
                            po_Number
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            name="po_Number"
                            className="form-control form add-input"
                            placeholder="PO Number"
                            value={po_Number}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>

                        {fieldReq && (po_Number == null || po_Number == "") ? (
                          <small id={`Error`} className="form-text text-muted ">
                            {alertMessage.message}&nbsp;
                            <i className="fa fa-exclamation-circle" />
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <div className="col my-2">
                        <FloatingLabel
                          label="Doc.No."
                          className={`${
                            po_Number
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            name="po_Number"
                            className="form-control form add-input"
                            placeholder="Doc.No."
                            // value={docNum}
                            // onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div> */}
                      <div className="col my-2">
                        <FloatingLabel
                          label="Vendor ID"
                          className={`${
                            vendor_id
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            name="vendor_id"
                            className="form-control form add-input"
                            placeholder="Vendor ID"
                            value={vendor_id}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                        {fieldReq && (vendor_id == null || vendor_id == "") ? (
                          <small id={`Error`} className="form-text text-muted ">
                            {alertMessage.message}&nbsp;
                            <i className="fa fa-exclamation-circle" />
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className="col my-2">
                        <FloatingLabel
                          label="Vendor Ref.No"
                          className={`${
                            vendorRef_No
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            name="vendorRef_No"
                            className="form-control form add-input"
                            placeholder="Vendor Ref.No"
                            maxLength={10}
                            value={vendorRef_No}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div> */}
                      <div className="col my-2">
                        <FloatingLabel
                          label="PO Value"
                          className={`${
                            pO_Value
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            name="pO_Value"
                            className="form-control form add-input"
                            placeholder="PO Value"
                            value={pO_Value}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                        {fieldReq && (pO_Value == null || pO_Value == "") ? (
                          <small id={`Error`} className="form-text text-muted ">
                            {alertMessage.message}&nbsp;
                            <i className="fa fa-exclamation-circle" />
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Tax Amount"
                          className={`${
                            tax_Amount
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            name="tax_Amount"
                            className="form-control form add-input"
                            placeholder="Tax Amount"
                            value={tax_Amount}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                        {fieldReq &&
                        (tax_Amount == null || tax_Amount == "") ? (
                          <small id={`Error`} className="form-text text-muted ">
                            {alertMessage.message}&nbsp;
                            <i className="fa fa-exclamation-circle" />
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Doc. Total"
                          className={`${
                            total_amount
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            name="total_amount"
                            className="form-control form add-input"
                            placeholder="Doc. Total"
                            value={total_amount}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                        {fieldReq &&
                        (total_amount == null || total_amount == "") ? (
                          <small id={`Error`} className="form-text text-muted ">
                            {alertMessage.message}&nbsp;
                            <i className="fa fa-exclamation-circle" />
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col my-2">
                        <FloatingLabel
                          label="Shipping Address"
                          className={`${
                            shipping_Address
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <textarea
                            rows="3"
                            cols="20"
                            wrap="hard"
                            autoComplete="off"
                            name="shipping_Address"
                            className="form-control form-textarea add-user-input"
                            placeholder="Shipping Address"
                            value={shipping_Address}
                            onChange={(e) => handleInputData(e)}
                          />
                        </FloatingLabel>
                        {fieldReq &&
                        (shipping_Address == null || shipping_Address == "") ? (
                          <small id={`Error`} className="form-text text-muted ">
                            {alertMessage.message}&nbsp;
                            <i className="fa fa-exclamation-circle" />
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Billing Address"
                          className={`${
                            billing_Address
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <textarea
                            rows="3"
                            cols="20"
                            wrap="hard"
                            autoComplete="off"
                            name="billing_Address"
                            className="form-control form-textarea add-user-input"
                            placeholder="Billing Address"
                            value={billing_Address}
                            onChange={(e) => handleInputData(e)}
                          />
                        </FloatingLabel>
                        {fieldReq &&
                        (billing_Address == null || billing_Address == "") ? (
                          <small id={`Error`} className="form-text text-muted ">
                            {alertMessage.message}&nbsp;
                            <i className="fa fa-exclamation-circle" />
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col my-2">
                        <FloatingLabel
                          label="Remarks"
                          className={`${
                            remarks
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <textarea
                            rows="3"
                            cols="20"
                            wrap="hard"
                            autoComplete="off"
                            name="remarks"
                            className="form-control form-textarea add-user-input"
                            placeholder="Remarks"
                            value={remarks}
                            onChange={(e) => handleInputData(e)}
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <div className="d-flex up-btn">
                          {/* <MiCustomBtn
                            btnName={"Upload File"}
                            btnColor={"green"}
                            iconName={"fa fa-upload"}
                            onClickEffect={uploadClick}
                          /> */}

                          <input
                            id="getFile"
                            type="file"
                            name="filetobase64"
                            accept="image/pdf"
                            className="btn-upload"
                            onChange={handleChange}
                            // ref={hiddenFileInput}
                            // onClickEffect={uploadClick}
                            // style={{ display: "none" }}
                          />
                        </div>
                        {fieldReq &&
                        (fileContent === null || fileContent === "") ? (
                          <small id={`Error`} className="form-text text-muted ">
                            {alertMessage.message}&nbsp;
                            <i className="fa fa-exclamation-circle" />
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row dflex-j-between">
                    <div className="add-page-save-cancel">
                      <SBSaveUpdateBtn
                        btnName="Next"
                        onClickEffect={goToPurchaseOrderLineItems}
                      />
                      <SbCancelBtn
                        btnName="Cancel"
                        onClickEffect={closeModel}
                      />
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <form onSubmit={addPurchaseOrderDetails}>
                {" "}
                <div className="add-po_lineItems-to-vhd">
                  {poLineItems.map((data, i) => (
                    <div className="add-po_lineItems-details" key={i}>
                      <div className="row">
                        <div className="col my-2">
                          <FloatingLabel
                            label="Item Name"
                            className={`${
                              data?.item_Description
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <input
                              type="text"
                              autoComplete="off"
                              name="item_Description"
                              className="form-control form add-input"
                              placeholder="Item Name"
                              value={data?.item_Description}
                              onChange={(e) => handleInputDataLineItems(e, i)}
                              required
                            />
                          </FloatingLabel>

                          {/* {fieldReq && vendorName == "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {`${alertMessage.message}`}
                        </small>
                      ) : (
                        ""
                      )} */}
                        </div>
                        <div className="col my-2">
                          <FloatingLabel
                            label="Item Code"
                            className={`${
                              data.item_ID
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <input
                              type="text"
                              autoComplete="off"
                              name="item_ID"
                              className="form-control form add-input"
                              placeholder="Item Code"
                              value={data.item_ID}
                              onChange={(e) => handleInputDataLineItems(e, i)}
                              // required
                            />
                          </FloatingLabel>
                        </div>

                        <div className="col my-2">
                          <FloatingLabel
                            label="Quantity"
                            className={`${
                              data.quantity_Ordered
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <input
                              type="number"
                              autoComplete="off"
                              name="quantity_Ordered"
                              className="form-control form add-input"
                              placeholder="Quantity"
                              value={data.quantity_Ordered}
                              onChange={(e) => handleInputDataLineItems(e, i)}
                              required
                            />
                          </FloatingLabel>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col my-2">
                          <FloatingLabel
                            label="UOM"
                            className={`${
                              data.uom
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <input
                              type="text"
                              autoComplete="off"
                              name="uom"
                              className="form-control form add-input"
                              placeholder="UOM"
                              value={data.uom}
                              onChange={(e) => handleInputDataLineItems(e, i)}
                              // required
                            />
                          </FloatingLabel>
                        </div>
                        <div className="col my-2">
                          <FloatingLabel
                            label="Unit Rate"
                            className={`${
                              data.unit_Price
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <input
                              type="number"
                              autoComplete="off"
                              name="unit_Price"
                              className="form-control form add-input"
                              placeholder="Unit Rate"
                              value={data.unit_Price}
                              onChange={(e) => handleInputDataLineItems(e, i)}
                              required
                            />
                          </FloatingLabel>
                        </div>
                        <div className="col my-2">
                          <FloatingLabel
                            label="Line Total"
                            className={`${
                              data.line_Total
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <input
                              type="number"
                              autoComplete="off"
                              name="line_Total"
                              className="form-control form add-input"
                              placeholder="Line Total"
                              value={data.line_Total}
                              onChange={(e) => handleInputDataLineItems(e, i)}
                              required
                            />
                          </FloatingLabel>
                        </div>
                        <div className="col my-2">
                          <FloatingLabel
                            label="Discount %"
                            className={`${
                              data.discount
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <input
                              type="number"
                              autoComplete="off"
                              name="discount"
                              className="form-control form add-input"
                              placeholder="Discount %"
                              value={data.discount}
                              onChange={(e) => handleInputDataLineItems(e, i)}
                              // required
                            />
                          </FloatingLabel>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col my-2">
                          <FloatingLabel
                            label="Tax %"
                            className={`${
                              data.tax_Per
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <input
                              type="number"
                              autoComplete="off"
                              name="tax_Per"
                              className="form-control form add-input"
                              placeholder="Tax %"
                              value={data.tax_Per}
                              onChange={(e) => handleInputDataLineItems(e, i)}
                              required
                            />
                          </FloatingLabel>
                        </div>
                        <div className="col my-2">
                          <FloatingLabel
                            label="Tax Code"
                            className={`${
                              data.tax_Code
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <input
                              type="text"
                              autoComplete="off"
                              name="tax_Code"
                              className="form-control form add-input"
                              placeholder="Tax Code"
                              value={data.tax_Code}
                              onChange={(e) => handleInputDataLineItems(e, i)}
                              // required
                            />
                          </FloatingLabel>
                        </div>
                        <div className="col my-2">
                          <FloatingLabel
                            label="Tax Amount"
                            className={`${
                              data.tax_Amount
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <input
                              type="number"
                              autoComplete="off"
                              name="tax_Amount"
                              className="form-control form add-input"
                              placeholder="Tax Amount"
                              value={data.tax_Amount}
                              onChange={(e) => handleInputDataLineItems(e, i)}
                              required
                            />
                          </FloatingLabel>
                        </div>
                        <div className="col my-2">
                          <FloatingLabel
                            label="Gross Total"
                            className={`${
                              data.gross_Total
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <input
                              type="number"
                              autoComplete="off"
                              name="gross_Total"
                              className="form-control form add-input"
                              placeholder="Gross Total"
                              value={data.gross_Total}
                              onChange={(e) => handleInputDataLineItems(e, i)}
                              required
                            />
                          </FloatingLabel>
                        </div>
                      </div>
                      <div
                        className="remove-row"
                        title="Delete"
                        onClick={(e) => removeRow(i)}
                      >
                        <i className="fa fa-trash" />
                      </div>
                    </div>
                  ))}
                  <div className="dflex-j-end my-2">
                    <MiCustomBtn
                      btnName="Row"
                      iconName={"fa fa-plus-circle"}
                      onClickEffect={addAnotherRow}
                      btnColor={"primary"}
                    />
                  </div>
                  <div className="add-page-save-cancel">
                    <SbCancelBtn
                      btnName="Previous"
                      onClickEffect={backToPrevious}
                    />
                    <SBSaveUpdateBtn
                      btnName="Save"
                      // onClickEffect={addPurchaseOrderDetails}
                    />
                  </div>
                </div>
              </form>
            )}
          </>
        }
      />
    </div>
  );
}
