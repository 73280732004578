import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  Polyline,
  CircleMarker,
  useMap,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import markerGreen from "../images/marker-green.svg";
import carGpsMarker from "../images/car_withMarker.png";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

const gpsCarMarker = new L.Icon({
  iconUrl: carGpsMarker,
  iconRetinaUrl: carGpsMarker,
  iconSize: [41, 45],
  iconAnchor: [22, 42],
});

const greenMarker = new L.Icon({
  iconUrl: markerGreen,
  iconRetinaUrl: markerGreen,
  iconSize: [32, 45],
});

const UpdateMap = ({ center, zoom }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);
  return;
};

export default function OpenStreenMap(props) {
  const indiaMapCenter = [21.7679, 78.8718];
  const [zoomSize, setZoomSize] = useState(5);
  const [mapCenterLatLng, setMapCenterLatLng] = useState(indiaMapCenter);
  const [mapStyle, setMapStyle] = useState({
    position: "relative",
    width: "100%",
    height: "86vh",
  });
  const [markerPositions, setMarkerPositions] = useState([]);
  const [polyLinePositions, setPolyLinePositions] = useState([]);

  const polylineStyle = {
    color: "purple",
    weight: 3,
    opacity: 0.7,
    dashArray: "5, 5",
  };

  useEffect(() => {
    centerLatLngSet(props.pageType);
  }, [
    props.pageType,
    props.markerPositions,
    props.clientCordinates,
    props.centerLatLng,
  ]);

  const centerLatLngSet = (pageType) => {
    switch (pageType) {
      case "CheckInByMap":
        setMapCenterLatLng(indiaMapCenter);
        setMapStyle({ ...mapStyle, height: "86vh" });
        setZoomSize(4);
        break;
      case "TrackMeetings":
        setMapCenterLatLng([props.centerLatLng.lat, props.centerLatLng.lng]);
        setMapStyle({ ...mapStyle, height: "85vh" });
        setZoomSize(8);
        if (
          props.markerPositions &&
          props.checkInLocation.lat !== null &&
          props.checkInLocation.lng !== null &&
          props.checkOutLocation.lat !== null &&
          props.checkOutLocation.lng !== null
        ) {
          let meetingsMarkerPositions = [...props.markerPositions];
          const checkInData = props.checkInLocation;
          const checkOutData = props.checkOutLocation;
          meetingsMarkerPositions.unshift(checkInData);
          meetingsMarkerPositions.push(checkOutData);
          setMarkerPositions(meetingsMarkerPositions);
          let polyPositions = [];
          for (let position of meetingsMarkerPositions) {
            polyPositions.push([position.lat, position.lng]);
          }
          setPolyLinePositions([polyPositions]);
        } else {
          setMarkerPositions(props.markerPositions);
          let polyPositions = [];
          for (let position of props.markerPositions) {
            polyPositions.push([position.lat, position.lng]);
          }
          setPolyLinePositions([polyPositions]);
        }
        break;
      case "TrackClients":
        setMapCenterLatLng([props.centerLatLng.lat, props.centerLatLng.lng]);
        setMapStyle({ ...mapStyle, height: "82vh" });
        setZoomSize(10);
        if (
          props.clientCordinates.length > 0 &&
          props.centerLatLng.lat &&
          props.centerLatLng.lng
        ) {
          let clientsBaseRecentlatlng = [...props.clientCordinates];
          let baseLocLatLng = props.centerLatLng;
          clientsBaseRecentlatlng.unshift(baseLocLatLng);
          setMarkerPositions(clientsBaseRecentlatlng);
        }
        break;
      case "AllRoutesInMap":
        setMapCenterLatLng([props.centerLatLng.lat, props.centerLatLng.lng]);
        setMapStyle({ ...mapStyle, height: "86vh" });
        setZoomSize(8);
        setMarkerPositions(props.clientsLocations);
        break;
      case "AllClientsInMap":
        setMapCenterLatLng([props.centerLatLng.lat, props.centerLatLng.lng]);
        setMapStyle({ ...mapStyle, height: "85vh" });
        setZoomSize(6);
        setMarkerPositions(props.clientsLocations);
        break;
      case "ActivityOnMap":
        setMapCenterLatLng([props.centerLatLng.lat, props.centerLatLng.lng]);
        setMapStyle({ ...mapStyle, height: "80vh" });
        setZoomSize(13);
        let polyLineLatsLng = [];
        for (let i = 0; i < props.activitiesByUser.length; i++) {
          polyLineLatsLng.push([
            +props.activitiesByUser[i].cordLoc.latitude,
            +props.activitiesByUser[i].cordLoc.longitude,
          ]);
        }
        setPolyLinePositions([polyLineLatsLng]);
        break;
      default:
    }
  };

  // console.log("CLIENT-DATA:", props.clientsLocations);

  return (
    <div className="openSourceGooglemap">
      <MapContainer
        scrollWheelZoom={true}
        center={mapCenterLatLng}
        zoom={zoomSize}
        style={mapStyle}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {zoomSize !== null &&
          mapCenterLatLng[0] !== null &&
          mapCenterLatLng[1] !== null && (
            <UpdateMap
              center={mapCenterLatLng}
              zoom={zoomSize}
              style={mapStyle}
            />
          )}

        {/* ------ USERS CHECKIN PAGE START ------ */}
        {props.pageType === "CheckInByMap" && (
          <>
            {props.trackUserDetails.map((data, i) => (
              <Marker key={i} position={[data.lat, data.lng]}>
                <Tooltip
                  direction="top"
                  offset={[-15, -5]}
                  opacity={1}
                  permanent
                >
                  <b>{i + 1}</b>
                </Tooltip>

                <Popup>
                  <div style={{ textAlign: "center", width: "200px" }}>
                    <div>
                      <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                        Name : {data?.fullName}
                      </h6>
                    </div>
                    <div>
                      Location : {data?.location}
                      <br />
                      <span>Last Seen : {data?.lastSeen}</span>
                    </div>
                  </div>
                </Popup>
              </Marker>
            ))}
          </>
        )}
        {/* ------ USERS CHECKIN PAGE END ------ */}

        {/* ------ TRACK MEETINGS PAGE START ------ */}
        {props.pageType === "TrackMeetings" && (
          <>
            {markerPositions.map((data, i) => (
              <>
                <Marker key={i} position={[data?.lat, data?.lng]}>
                  <Tooltip
                    direction="top"
                    offset={[-15, -5]}
                    opacity={1}
                    permanent
                  >
                    <b>{i + 1}</b>
                  </Tooltip>

                  <Popup>
                    <div style={{ textAlign: "center", width: "200px" }}>
                      <div>
                        <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                          {data.clientName === "Check-In" ||
                          data.clientName === "Check-Out" ? (
                            <span>{data?.clientName}</span>
                          ) : (
                            <span>Client : {data?.clientName}</span>
                          )}
                        </h6>
                      </div>
                      <div>
                        Location : {data?.location}
                        {data.clientName === "Check-In" ||
                        data.clientName === "Check-Out" ? (
                          <>
                            <br />
                            <span>Time : {data?.time}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Popup>
                </Marker>
              </>
            ))}
            {/* <Polyline
              pathOptions={polylineStyle}
              positions={polyLinePositions}
            /> */}
          </>
        )}
        {/* ------ TRACK MEETINGS PAGE END ------ */}

        {/* ------ TRACK CLIENT PAGE START ------ */}
        {props.pageType === "TrackClients" && (
          <>
            <MarkerClusterGroup chunkedLoading>
              {markerPositions.map((data, i) => (
                <Marker key={i} position={[data?.lat, data?.lng]}>
                  <Tooltip
                    direction="top"
                    offset={[-15, -5]}
                    opacity={1}
                    permanent
                  >
                    <b>{i + 1}</b>
                  </Tooltip>

                  <Popup>
                    <div style={{ textAlign: "center", width: "200px" }}>
                      <div>
                        <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                          {data.clientName ? (
                            <span>Client : {data?.clientName}</span>
                          ) : (
                            <span>Base Location</span>
                          )}
                        </h6>
                      </div>
                      <div>
                        Location : {data?.location}
                        {data.distnace ? (
                          <>
                            <br />
                            <span>Distance : {data?.distnace}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>
          </>
        )}
        {/* ------ TRACK CLIENT PAGE END ------ */}

        {/* ------ ALL MEETINGS IN MAP PAGE START ------ */}
        {props.pageType === "AllRoutesInMap" && (
          <>
            <MarkerClusterGroup chunkedLoading>
              {markerPositions.map((data, i) => (
                <Marker key={i} position={[data?.lat, data?.lng]}>
                  <Tooltip
                    direction="top"
                    offset={[-15, -5]}
                    opacity={1}
                    permanent
                  >
                    <b>{i + 1}</b>
                  </Tooltip>

                  <Popup>
                    <div style={{ textAlign: "center", width: "200px" }}>
                      <div>
                        <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                          {data.clientName ? (
                            <span>Client : {data?.clientName}</span>
                          ) : (
                            <span>{data?.userName}</span>
                          )}
                        </h6>
                      </div>
                      <div>
                        Location : {data?.location}
                        {data.distance ? (
                          <>
                            <br />
                            <span>Distance : {data?.distance}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>
          </>
        )}
        {/* ------ ALL MEETINGS IN MAP PAGE END ------ */}

        {/* ------ ALL CLIENTS IN MAP PAGE START ------ */}
        {props.pageType === "AllClientsInMap" && (
          <>
            <MarkerClusterGroup chunkedLoading maxClusterRadius={100}>
              {markerPositions.map((data, i) => (
                <Marker
                  key={i}
                  position={[
                    data?.clientLoc?.latitude,
                    data?.clientLoc?.longitude,
                  ]}
                >
                  <Tooltip
                    direction="top"
                    offset={[-15, -5]}
                    opacity={1}
                    permanent
                  >
                    <b>{i + 1}</b>
                  </Tooltip>

                  <Popup>
                    <div style={{ textAlign: "center", width: "200px" }}>
                      <div>
                        <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                          <span>Client : {data?.clientName}</span>
                        </h6>
                      </div>
                      <div>Location : {data?.clientLoc?.location}</div>
                    </div>
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>
          </>
        )}
        {/* ------ ALL CLIENTS IN MAP PAGE END ------ */}

        {/* ------ ACTIVITY ON MAP PAGE START ------ */}
        {props.pageType === "ActivityOnMap" && (
          <>
            <Polyline
              pathOptions={polylineStyle}
              positions={polyLinePositions}
            />

            <Marker
              position={[
                props.movingMarkerPosition.lat,
                props.movingMarkerPosition.lng,
              ]}
              icon={gpsCarMarker}
              // placement="center"
            >
              {/* <Tooltip direction="top" offset={[0, 0]} opacity={1} permanent>
                <b>
                  <i className="fa fa-user" />
                </b>
              </Tooltip> */}
              {/* {props.playPause && (
                <Popup>
                  <div style={{ textAlign: "center", width: "200px" }}>
                    <div>
                      <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                        <span>
                          Time : {props.movingMarkerPosition["time"] ?? ""}
                        </span>
                      </h6>
                    </div>
                    <div>
                      Location : {props.movingMarkerPosition["location"] ?? ""}
                    </div>
                  </div>
                </Popup>
              )} */}
            </Marker>
          </>
        )}
        {/* ------ ACTIVITY ON MAP PAGE END ------ */}
      </MapContainer>
    </div>
  );
}
