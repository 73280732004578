import React, { useState, useEffect, useLayoutEffect } from "react";
import "../../../styles/CommonStyle.scss";
import "../../../styles/BaseFare.scss";
import "../../../styles/googleCredits.scss";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import { loadGoogleCredits } from "../../../redux/action";
import { loggedInRole } from "../../LoginRole";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import MobileExceptionTable from "./MobileExceptionTable";
import AccessDenied from "../../../AccessDenied";
import Footer from "../../Footer";
import { MiClearButton } from "../../SbButtons/SbCancelBtn";

function MobileException() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  let tableHeader = [
    // {name: "SL.NO", align: "center"},
    { name: "ERROR DATE TIME", align: "left" },
    { name: "ERROR MESSAGE", align: "left" },
    { name: "API NAME", align: "left" },
    { name: "DATA", align: "left" },
    // {name: "EXCEPTION ID", align: "left"},
  ];

  let dispatch = useDispatch();

  const { googleCreditList } = useSelector((state) => state.data);
  const { apiMobileExceptionData } = useSelector((state) => state.data);

  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [menuCollapse, setMenuCollapse] = useState(false);

  const [show, setShow] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [PageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [filterArray, setFilterArray] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [openFilterTab, setOpenFilterTab] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setSearchData = (value) => {
    let list;
    if (filterArray.length !== 0) {
      list = filterArray.filter((item) => item.key !== "Key");
    } else {
      list = [];
    }
    if (value) {
      list.push({ key: "Key", value: value });
    } else {
      list = filterArray.filter((item) => item.key !== "Key");
    }
    setSearchValue(value);
    setFilterArray(list);
  };

  const setSelectSearchDrop = (data, type) => {
    let list = [];
    if (type === "date") {
      const [start, end] = data;
      if (filterArray.length !== 0) {
        if (start) {
          list = filterArray.filter((item) => item.key !== "FromDate");
        }
        if (end) {
          list = filterArray.filter((item) => item.key !== "ToDate");
        }
      }
      if (start && end == null) {
        list.push({
          key: "FromDate",
          value: moment(new Date(start)).format("DD/MM/YYYY"),
        });
      }
      setFromDate(start);
      if (end) {
        list.push({
          key: "ToDate",
          value: moment(new Date(end)).format("DD/MM/YYYY"),
        });
      }
      setToDate(end);
    }
    setFilterArray(list);
  };

  const onClickCredits = (key) => {
    navigate(`/apicredits/${key}`);
  };

  const [editableData, setEditableData] = useState({});
  const [googleCreditId, setGoogleCreditId] = useState("");

  const editGoogleCredit = (googleApiId) => {
    setGoogleCreditId(googleApiId);
    const dataObject = googleCreditList.filter((item) => {
      return item.GoogleApiKey === googleApiId;
    });
    const [data] = dataObject;
    setEditableData(data);
    setShow(true);
  };

  useEffect(() => {
    fetchGoogleCreditdata();
  }, [filterArray, PageSize, currentPage]);

  const fetchGoogleCreditdata = () => {
    let Flag = "GetMobileException";
    dispatch(
      loadGoogleCredits(Flag, setIsLoading, PageSize, currentPage, filterArray)
    );
  };

  const clearSerachBySelect = () => {
    setFilterArray([]);
    setFromDate(new Date());
    setToDate(new Date());
  };

  // Page navigation
  let navigate = useNavigate();

  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={4} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">Mobile Exception</h6>
                      </div>
                    </Col>
                    <Col md={5} className="cat-col">
                      <div className="input-div">
                        <input
                          type="search"
                          id="metricInfo_search_bar"
                          placeholder="Find a Key"
                          className="form-control setting-input"
                          value={searchValue}
                          onChange={(e) => setSearchData(e.target.value)}
                        />
                        <i className="fa fa-search search-icon"></i>
                      </div>
                    </Col>
                    <Col md={3} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns">
                          <div
                            className="header-filter-btn"
                            onClick={() => setOpenFilterTab(!openFilterTab)}
                          >
                            <div className="filter-icon-text">
                              <i className="bi bi-sliders" />
                              <span>Filters</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab && (
                    <div className="page-filter-section">
                      <div className="filter-row-input-boxes">
                        <div className="from-to-date-select">
                          <div className="input-group-text" id="from-label">
                            From - To
                          </div>
                          <div className="date-picker-with-icon">
                            <DatePicker
                              selected={fromDate}
                              className="select from-date date-filter"
                              id="date-range-picker"
                              dateFormat="dd/MM/yyyy"
                              selectsRange
                              startDate={fromDate}
                              endDate={toDate}
                              onChange={(date) =>
                                setSelectSearchDrop(date, "date")
                              }
                              maxDate={new Date()}
                            />
                            <div>
                              <i
                                className="fa fa-calendar-o calander-icon"
                                style={{ right: "6px" }}
                              />
                            </div>
                          </div>
                        </div>

                        <MiClearButton
                          onClickEffect={clearSerachBySelect}
                          btnName="Clear"
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/*TABLE START */}
                <MobileExceptionTable
                  tableHeader={tableHeader}
                  apiMobileExceptionData={apiMobileExceptionData}
                  isLoading={isLoading}
                  // editGoogleCredit={editGoogleCredit}
                  // onClickCredits={onClickCredits}
                />
                {/*TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default MobileException;
