import React, { useState, useEffect } from "react";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import {
  optionsMeetingStatusList,
  optionsMeetingTypeList,
} from "../DropdownsData";
import { Col, Row } from "react-bootstrap";
import {
  loadClientDropDown,
  loadMeetingPurposeData,
  loadMotDrop,
  loadPageBreadCome,
} from "../../redux/action";
import { MiClearButton } from "../SbButtons/SbCancelBtn";
import UserHistoryTable from "./UserHistoryTable";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { useDispatch, useSelector } from "react-redux";
import { loadUserDropdown } from "../../redux/action";
import DownloadLoading from "../ReUseComponents/DownloadLoading";
import { travelizeAuth } from "../HeaderAuthentication";

function UserHistory(props) {
  let tableHeader = [
    { name: "USER NAME", align: "left", width: "150px" },
    { name: "MEETING DATE / TYPE", align: "left", width: "200px" },
    { name: "COMPANY", align: "left", width: "200px" },
    { name: "LOCATION", align: "left", width: "250px" },
    { name: "STATUS", align: "center", width: "120px" },
    { name: "OTHERS", align: "left", width: "150px" },
    { name: "REMARKS", align: "left", width: "200px" },
  ];

  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);
  const { motDrop } = useSelector((state) => state.data);
  const { clientDropdown } = useSelector((state) => state.data);
  const { meetingPurposeData } = useSelector((state) => state.data);

  const [userHistory, setUserHistory] = useState([]);
  const [MotId, setMotId] = useState(0);
  const [meetPurposeId, setMeetPurposeId] = useState(0);
  const [filterUserId, setFilterUserId] = useState(props.userId);
  const [clientId, setClientId] = useState(0);

  const currentDate = new Date();
  const oneMonthAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 30
  );

  const [fromDate, setFromDate] = useState(oneMonthAgo);
  const [toDate, setToDate] = useState(new Date());
  const [meetingStatus, setMeetingStatus] = useState("");
  const [meetingType, setMeetingType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState(0);
  // PAGINATION END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    let breadCome = { mainpage: "User History" };
    dispatch(loadPageBreadCome(breadCome));
    dispatch(loadUserDropdown());
    dispatch(loadMotDrop());
    dispatch(loadClientDropDown(filterUserId));
    dispatch(loadMeetingPurposeData());
  }, []);

  //* FETCH DROPDOWN DATA END *//

  // GET USER HISTORY DETAIL API START
  useEffect(() => {
    if (fromDate !== null && toDate !== null) {
      getUserDetails();
    }
  }, [
    PageSize,
    currentPage,
    filterUserId,
    clientId,
    fromDate,
    toDate,
    meetingType,
    meetingStatus,
    MotId,
    meetPurposeId,
  ]);

  const getUserDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/Client/GetClientUserHistory?FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${filterUserId}&ClientID=${clientId}&PurposeID=${meetPurposeId}&MotID=${MotId}&type=${meetingType}&status=${meetingStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserHistory(res.result);
          setPageDataSize(res.result.length);
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
          setLoading(false);
        } else {
          setUserHistory([]);
          setUserHistory("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };
  // GET USER HISTORY DETAIL API END

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId(props.userId);
    setMeetingType("");
    setMeetingStatus("");
    setMotId(0);
    setMeetPurposeId(0);
    setClientId(0);
    setFromDate(oneMonthAgo);
    setToDate(new Date());
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportUserHistory = () => {
    exportExcel(
      `${baseURL}/api/Export/ClientUserHistoryExcelExport?FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${filterUserId}&ClientID=${clientId}&PurposeID=${meetPurposeId}&MotID=${MotId}&type=${meetingType}&status=${meetingStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "User_History.xlsx",
      setDownloadIsLoading
    );
  };
  //* EXPORT FILE END *//

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  return (
    <div>
      <div id="main-page">
        <>
          {/* MAIN BODY START */}
          <div>
            {/* PAGE CONTENT START */}
            {props.userHistoryView ? (
              <div className={`main-body ${props.toggleshift.style}`}>
                <div className="page-content">
                  {/* Filter and Header Section */}
                  <div className="user-table">
                    <Row className="search-row">
                      <Col md={3} className="cat-col">
                        <div className="page-header-text-div">
                          <div
                            className="back-btn"
                            onClick={props.userHistoryView}
                          >
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6 className="page-header-text">USERS HISTORY</h6>
                        </div>
                      </Col>
                      <Col md={9} className="cat-col">
                        <div
                          className="search-bar-header"
                          style={{ float: "right" }}
                        >
                          <div className="page-top-bar-dropdowns page-top-Filters">
                            <div
                              className="header-filter-btn"
                              onClick={() => setFilter(!filter)}
                            >
                              <p className="card-head">
                                <i className="bi bi-sliders" />
                                &nbsp;&nbsp;Filters
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {filter && (
                      <div className="page-filter-section">
                        <div className="filter-row-input-boxes">
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={userDropdown}
                              placeholder="Select User"
                              value={
                                filterUserId === props.userId
                                  ? ""
                                  : userDropdown.value
                              }
                              onInputChange={userDropdown.label}
                              onChange={(data) => setFilterUserId(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={clientDropdown}
                              placeholder="Select Client"
                              value={clientId === 0 ? "" : clientDropdown.value}
                              onInputChange={clientDropdown.label}
                              onChange={(data) => setClientId(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={motDrop}
                              placeholder="Select MOT"
                              value={MotId === 0 ? "" : motDrop.value}
                              onInputChange={motDrop.label}
                              onChange={(data) => setMotId(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={meetingPurposeData}
                              placeholder="Select Purpose"
                              value={
                                meetPurposeId === 0
                                  ? ""
                                  : meetingPurposeData.value
                              }
                              onInputChange={meetingPurposeData.label}
                              onChange={(data) => setMeetPurposeId(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              // defaultValue={optionsRoleList[0].label}
                              options={optionsMeetingStatusList}
                              placeholder="Select Status"
                              value={
                                meetingStatus === ""
                                  ? ""
                                  : optionsMeetingStatusList.value
                              }
                              onInputChange={optionsMeetingStatusList.label}
                              onChange={(data) => setMeetingStatus(data.value)}
                              isSearchable={true}
                            />
                          </div>

                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              // defaultValue={optionsRoleList[0].label}
                              options={optionsMeetingTypeList}
                              placeholder="Meeting Type"
                              value={
                                meetingType === ""
                                  ? ""
                                  : optionsMeetingTypeList.value
                              }
                              onInputChange={optionsMeetingTypeList.label}
                              onChange={(data) => setMeetingType(data.value)}
                              isSearchable={true}
                            />
                          </div>

                          <div className="from-to-date-select">
                            <div className="input-group-text" id="from-label">
                              From - To
                            </div>
                            <div className="date-picker-with-icon">
                              <DatePicker
                                selected={fromDate}
                                className="select from-date date-filter"
                                id="date-range-picker"
                                dateFormat="dd/MM/yyyy"
                                selectsRange
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={(date) => setSelectSearchDrop(date)}
                                // maxDate={new Date()}
                              />
                              <div>
                                <i
                                  className="fa fa-calendar-o calander-icon"
                                  style={{ right: "6px" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <MiClearButton
                              onClickEffect={clearSerachBySelect}
                              btnName="Clear"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Filter and Header Section */}

                  {/* USER TABLE START */}
                  <UserHistoryTable
                    tableHeader={tableHeader}
                    isLoading={isLoading}
                    userHistory={userHistory}
                    totalData={totalData}
                    setPageSize={setPageSize}
                    PageSize={PageSize}
                    setLoading={setLoading}
                    loading={loading}
                  />
                  {/* USER TABLE END */}

                  <div className="download-and-pagination">
                    <SbDownloadBtn
                      btnName="Download"
                      onClickEffect={exportUserHistory}
                    />
                  </div>
                  {/* PAGE CONTENT END*/}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>

        {downloadIsLoading && (
          <>
            <DownloadLoading />
          </>
        )}
      </div>
    </div>
  );
}

export default UserHistory;
