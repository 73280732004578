import React, { useEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import * as HeaderData from "../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import {
  loadUploadComplaintImages,
  loadUserDropdown,
  updateComplaintStatus,
} from "../../redux/action";
import SbAddBtn, { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import Select from "react-select";
import moment from "moment";
import { compStatus } from "../DropdownsData";
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";
import { useRef } from "react";
import { baseURL } from "../BaseUrl";

function StatusUpdatePage(props) {
  let dispatch = useDispatch();
  let compaintId = props.compaintId;
  const { singleClientCompaint } = useSelector((state) => state.data);
  const { userDropdown } = useSelector((state) => state.data);

  const hiddenFileInput = useRef(null);
  const [localImage, setLocalImage] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [selectedUser, setSelectedUser] = useState({ value: "", label: "" });
  const [selectedStatus, setSelectedStatus] = useState({
    value: "",
    label: "",
  });

  const [updateData, setUpdateData] = useState({
    clientComplaintsID: 0,
    subscriptionID: "",
    userID: "",
    userFullName: "",
    addedByID: "",
    addedByUserFullName: "",
    addedOn: "",
    status: 0,
    clientIDFk: 0,
    date: "",
    productIDFK: 0,
    clientEmail: "",
    clientPhone: 0,
    issue: "",
    description: "",
    issueImage: "",
    inWarranty: 0,
    address: "",
    priority: 0,
    assingnedTo: "",
    adttenedByID: "",
    adttenedOn: "",
    solution: "",
    solutionImage: "",
  });

  const { solution } = updateData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateData({ ...updateData, [name]: value });
  };

  const handleDropDown = (data, type) => {
    let list = updateData;
    if (type == "User") {
      setSelectedUser(data);
      list.adttenedByID = data.value;
    } else if (type == "Status") {
      setSelectedStatus(data);
      list.status = data.value;
    }
    setUpdateData(list);
  };

  const uploadClick = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length !== 0) {
      const fileUploaded = files[0];
      setImgFile(fileUploaded);
      setImgFileName(files[0].name);

      const reader = new FileReader();
      reader.readAsDataURL(fileUploaded);
      reader.onloadend = () => {
        // updateData.issueImage = reader.result;
        setLocalImage(reader.result);
      };
    }
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];

    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);
    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      // updateData.solutionImage = reader.result;
      setLocalImage(reader.result);
    };
  };

  useEffect(() => {
    dispatch(loadUserDropdown());
    if (singleClientCompaint) {
      setUpdateData(singleClientCompaint);
      compStatus.map((status) => {
        if (status.value === singleClientCompaint.status) {
          setSelectedStatus(status);
        }
      });
      userDropdown.map((user) => {
        if (user.value === singleClientCompaint.adttenedByID) {
          setSelectedUser(user);
        }
      });
    }
  }, [singleClientCompaint]);

  const closeUpdateModal = () => {
    props.setShowUpdate(false);
    props.setCompaintId("");
    props.fetchComplaintdata();
  };

  const [isError, setIsError] = useState(false);
  const updateComplaints = (e) => {
    e.preventDefault();
    if (
      !updateData.status ||
      !updateData.adttenedByID ||
      !updateData.solution
    ) {
      setIsError(true);
    } else {
      let date = moment(new Date()).format("DD/MM/YYYY");
      updateData.date = date;
      if (imgFile) {
        const formData = new FormData();
        if (imgFile) {
          formData.append("Files", imgFile);
        }
        formData.append("Directory", "Complaint");
        formData.append("FileNamePrefix", "Complaint" + imgFile.name);
        var imgurlPost = {
          method: "POST",
          mode: "cors",
          headers: HeaderAuthentication.salesbeeImgAuth,
          body: formData,
        };
        dispatch(
          loadUploadComplaintImages(
            imgurlPost,
            updateData,
            closeUpdateModal,
            compaintId,
            "status"
          )
        );
      } else {
        var updateRequest = {
          method: "POST",
          mode: "cors",
          headers: HeaderAuthentication.postTravelizeAuth,
          body: JSON.stringify(updateData),
        };
        dispatch(
          updateComplaintStatus(updateRequest, closeUpdateModal, "status")
        );
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.showUpdate}
        onHide={closeUpdateModal}
        className="main_model add-edit-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">Update Complaint Status</h6>
          <i className="fa fa-times" onClick={closeUpdateModal} />
        </div>

        <Form>
          <div className="container add-edit mt-3">
            <div className="mb-3">
              <FloatingLabel
                label="Select User"
                className={`${
                  selectedUser.value !== ""
                    ? "float-visible-select"
                    : "float-hidden float-select"
                }`}
              >
                <Select
                  className="react-select-container-list model-select"
                  classNamePrefix="react-select-list"
                  options={userDropdown}
                  placeholder="Select User"
                  value={selectedUser.value && selectedUser}
                  onInputChange={userDropdown.label}
                  onChange={(data) => handleDropDown(data, "User")}
                  isSearchable={true}
                />
              </FloatingLabel>
              {isError && selectedUser.label == "" && (
                <small id={`Error`} className="form-text text-muted ">
                  Please select User
                </small>
              )}
            </div>
            <div className="mb-3">
              <FloatingLabel
                label="Select Status"
                className={`${
                  selectedStatus.value !== ""
                    ? "float-visible-select"
                    : "float-hidden float-select"
                }`}
              >
                <Select
                  className="react-select-container-list model-select"
                  classNamePrefix="react-select-list"
                  options={compStatus}
                  placeholder="Select Status"
                  value={selectedStatus.value && selectedStatus}
                  onInputChange={compStatus.label}
                  onChange={(data) => handleDropDown(data, "Status")}
                  isSearchable={true}
                />
              </FloatingLabel>
              {isError && selectedStatus.label == "" && (
                <small id={`Error`} className="form-text text-muted ">
                  Please select status
                </small>
              )}
            </div>
            <div className="mb-3">
              <FloatingLabel
                label="Solution Given"
                className={`${
                  solution ? "float-input-visible" : "float-hidden float-input"
                }`}
              >
                <Form.Control
                  as="textarea"
                  type="text"
                  className="form-control form-textarea add-product-input"
                  value={solution || ""}
                  name="solution"
                  onChange={handleInputChange}
                  placeholder="Solution Given"
                  autoComplete="off"
                />
              </FloatingLabel>
              {isError && solution == "" && (
                <small id={`Error`} className="form-text text-muted ">
                  Please enter solution
                </small>
              )}
            </div>

            <div className="mb-3">
              <div>
                <button type="button" className="btn btn-add-product-img">
                  <span
                    style={{
                      color: "#04A96D",
                      fontSize: "13px",
                      fontWeight: "700",
                    }}
                  >
                    <div
                      className="drop-area"
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={handleDrop}
                    >
                      {!localImage && !singleClientCompaint?.solutionImage ? (
                        <>
                          <h6>Upload Issue Attachment</h6>
                          <p>Drag and drop images here</p>
                          <p>OR</p>
                        </>
                      ) : (
                        <>
                          {!localImage &&
                          Object.keys(singleClientCompaint).length !== 0 &&
                          singleClientCompaint?.solutionImage ? (
                            <img
                              className="complaint-img"
                              src={`${baseURL}/${singleClientCompaint?.solutionImage}`}
                              alt="Product Images"
                            />
                          ) : (
                            <img
                              className="complaint-img"
                              src={localImage}
                              alt="Product Images"
                            />
                          )}
                        </>
                      )}
                      <SbCancelBtn
                        btnName="Browse"
                        onClickEffect={uploadClick}
                      />
                      <p style={{ fontSize: "11px" }}>
                        Supports Only JPG, JPEG, PNG
                      </p>
                    </div>
                  </span>
                </button>
                <input
                  id="getFile"
                  type="file"
                  multiple
                  name="filetobase64"
                  accept="image/*"
                  className="btn-primary upload"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>

          <div className="btns-save-cancel">
            <SBSaveUpdateBtn
              onClickEffect={updateComplaints}
              btnName="Update Status"
            />
            <SbCancelBtn btnName="Cancel" onClickEffect={closeUpdateModal} />
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default StatusUpdatePage;
