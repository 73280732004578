import React, { useEffect, useRef, useState } from "react";
import "../src/styles/SignIn.scss";
import "../src/styles/CommonStyle.scss";
import "../src/MediaFiles/Media.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as HeaderAuthentication from "../src/Components/HeaderAuthentication";
import { Button, Col, Image, Row, Carousel } from "react-bootstrap";
import travelize from "./images/metric-favicon.svg";
import travelizeSalesBeeLogo from "./images/MatricLogo.svg";
import InternetIssue from "./Components/InternetIssue";
import { useDispatch, useSelector } from "react-redux";
import { loadGetOTP } from "./redux/action";
import SignInOtpTimer from "./SignInOtpTimer";

const OtpVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state;
  console.log(email, "email");

  const [otpError, setOtpError] = useState(false);
  let dispatch = useDispatch();
  const { otpData } = useSelector((state) => state.data);
  console.log(otpData, "otpData");

  const [expireTime, setExpireTime] = useState(120);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to the next input
    if (value !== "" && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
  const result = otp.toString().replace(/,/g, "");

  useEffect(() => {
    if (result && result.length === 6 && otpData) {
      if (parseInt(otpData) === parseInt(result)) {
        navigate("/dashboard");
      } else {
        setOtpError(true);
      }
    }
  }, [result, otpData]);

  const [showTimer, setShowTimer] = useState(true);
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleTimerExpire = () => {
    setShowTimer(false);
    // Handle what should happen when the timer expires (e.g., request a new OTP).
  };

  const getOtpForSuperAdmin = () => {
    setExpireTime(120);
    setShowTimer(true);
    var request = {
      userName: "jagadishgoudavm123@gmail.com",
    };
    let postRequest = {
      method: "POST",
      mode: "cors",
      headers: HeaderAuthentication.postTravelizeAuth,
      body: JSON.stringify(request),
    };
    dispatch(loadGetOTP(postRequest));
  };

  return (
    <div>
      <>
        <div className="container-fluid">
          <div className="sigin-page-body">
            {/* <Row> */}
            <div className="dflex-flow-row">
              <Col lg={5} md={5} sm={12} className="signIn-container-left-div">
                <div className="signIn-container-logo">
                  <Image
                    src={travelizeSalesBeeLogo}
                    alt="MetricInfo"
                    className="signIn-logo"
                  />
                </div>
                <div className="signIn-container-gif">
                  <Image
                    src="../images/sign-animate.gif"
                    alt="MetricInfo Motion Logo"
                    className="signIn-moving-img"
                  />
                  <div className="text-sliding-below-img">
                    <Carousel
                      style={{ width: "100%" }}
                      controls={false}
                      indicators={false}
                      interval={3000}
                    >
                      <Carousel.Item>
                        <h6>
                          <b>Geolocation Monitoring</b>
                        </h6>
                        <p>Real-time tracking to determine precise location</p>
                      </Carousel.Item>
                      <Carousel.Item>
                        <h6>
                          <b>Reporting and Analytics</b>
                        </h6>
                        <p>
                          Offering valuable insights for informed
                          decision-making
                        </p>
                      </Carousel.Item>
                      <Carousel.Item>
                        <h6>
                          <b>Visits and Expenses</b>
                        </h6>
                        <p>
                          Ensuring optimal resource allocation and cost control
                        </p>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </Col>
              <Col lg={2} md={2} sm={12}>
                <div className="devider">
                  <div className="v1"></div>
                </div>
              </Col>
              <Col lg={5} md={5} sm={12} className="signIn-container-right-div">
                <div className="metricinfo-logo">
                  <Image
                    src={travelize}
                    alt="MetricInfo Logo"
                    className="sb-trv-logo"
                  />
                </div>
                <div className="signin-header otp-verification-part">
                  <div className="otp-header-part">
                    <h2 className="mb-2">OTP Verification</h2>
                    <h6>Please verify the OTP send to your email</h6>
                  </div>
                  <form className="login-form">
                    <div className="otp-verification-container">
                      {otp.map((value, index) => (
                        <input
                          key={index}
                          type="password"
                          value={value}
                          maxLength={1}
                          // className="form-control form add-basefare-input"
                          ref={(input) => (inputRefs.current[index] = input)}
                          onChange={(e) =>
                            handleOtpChange(index, e.target.value)
                          }
                          onKeyDown={(e) => handleKeyDown(index, e)}
                        />
                      ))}
                    </div>
                    {otpError && (
                      <small id={`Error`} className="form-text text-muted ">
                        Entered OTP is incorrect
                      </small>
                    )}
                    <div className="forget-pasword-div">
                      <SignInOtpTimer
                        expiryTimeInSeconds={expireTime}
                        setExpireTime={setExpireTime}
                        onTimerExpire={handleTimerExpire}
                        showTimer={showTimer}
                        getOtpForSuperAdmin={getOtpForSuperAdmin}
                      />
                    </div>
                    <div className="signin-button d-flex justify-content-center mt-2">
                      <Button type="button" className="btn btn-signin-button">
                        Verify OTP
                      </Button>
                    </div>
                  </form>
                </div>
              </Col>
            </div>
            {/* </Row> */}
          </div>
        </div>
      </>
    </div>
  );
};

export default OtpVerification;
