export const loggedInRole = JSON.parse(
  sessionStorage.getItem("usrlgndtls")
)?.isAdmin;

export const loginSubscriptionID = JSON.parse(
  sessionStorage.getItem("usrlgndtls")
)?.subscriptionId;

export const loggedInId = JSON.parse(
  sessionStorage.getItem("usrlgndtls")
)?.userId;
