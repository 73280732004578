import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import Select from "react-select";
import * as swal from "../../Consturl/SwalAlert";
import { baseURL } from "../../BaseUrl";
import { optionsTravelTypes, optionsReimburseTypes } from "../../DropdownsData";
import {
  AddbaseFare,
  loadDepartmentDrop,
  loadDepasrtDesignation,
  loadSingleBaseFare,
  loadUpdateBasefare,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { FloatingLabel } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { MultiSelect } from "react-multi-select-component";
import { toast } from "react-toastify";

function ReimbursementLimitsModal(props) {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let editReimburseID = props.editReimburseID;
  let setReimburseID = props.reimburseID;

  let dispatch = useDispatch();
  const { departDesignation } = useSelector((state) => state.data);
  const { departmentDrop } = useSelector((state) => state.data);

  const [showAlert, setShowAlert] = useState(false);
  const [showValidAlert, setShowValidAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });
  const [travelType, setTravelType] = useState({ value: "", label: "" });
  const [motDepartment, setMotDepartment] = useState({ value: "", label: "" });
  const [designations, setDesignations] = useState([]);
  const [allMotDesignation, setAllMotDesignation] = useState([]);
  const [selected, setSelected] = useState([]);
  const [statusFlag, setStatusFlag] = useState(true);

  useEffect(() => {
    // dispatch(loadDesignationDrop())
    dispatch(loadDepartmentDrop());
    if (setReimburseID || editReimburseID) {
      departmentDrop.map((department) => {
        if (department.value) {
          setMotDepartment(department);
          dispatch(loadDepasrtDesignation());
        }
      });
    }
    if (editReimburseID) {
      let requestOption = {
        method: "GET",
        mode: "cors",
        headers: HeaderAuthentication.travelizeAuth,
      };
      fetch(
        `${baseURL}/api/CustomeClaimLimit/Get/${editReimburseID ?? 0}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            console.log("EDIT-RES:", res.result);
            let { ...postItem } = res.result;
            setMotDepartment({
              value: postItem.departmentID,
              label: postItem.departmentName,
            });
            setDesignations([
              {
                value: postItem.designationID,
                label: postItem.designationName,
              },
            ]);
            setPostItem(postItem);
          } else {
            toast.error(`${res.errorMessage}`);
          }
        })
        .catch((error) => {
          // console.log("ERROR :", error);
          toast.error(`${error}`);
        });
    }
  }, [setReimburseID, editReimburseID]);

  const [postItem, setPostItem] = useState({
    customeClaimLimitID:
      editReimburseID !== "" && editReimburseID !== null
        ? Number(editReimburseID)
        : 0,
    subscriptionID: resPassData.subscriptionId,
    addedByID: resPassData.userId,
    addedByUserFullName: resPassData.fullName,
    status: 1,
    designationID: 0,
    departmentID: 0,
    foodCliaimLimit: 0,
    hotelCliaimLimit: 0,
    othersCliaimLimit: 0,
    osLocalConvinienceCliaimLimit: 0,
    osFoodCliaimLimit: 0,
    osHotelCliaimLimit: 0,
    osOthersCliaimLimit: 0,
  });

  const handleInputChange = (e) => {
    setShowValidAlert(false);
    const { name, value } = e.target;
    setPostItem({ ...postItem, [name]: +value });
  };

  console.log("POST-ITEMS:::", postItem);

  const handleSelectChange = (value, type) => {
    // console.log("Selected Value:", value);
    if (type === "department") {
      dispatch(loadDepasrtDesignation(value.value));
      setMotDepartment(value);
      setDesignations([]);
      postItem.departmentID = value.value;
    } else if (type === "designation") {
      if (value.length == departDesignation.length) {
        setDesignations(value);
      } else {
        setDesignations(value);
      }
    }
  };

  const habdleEnableStatus = (e) => {
    if (e === 1) {
      setStatusFlag(true);
      postItem.status = 1;
    } else if (e === 0) {
      setStatusFlag(false);
      postItem.status = 0;
    }
  };

  useEffect(() => {
    if (selected && selected.length !== 0) {
      postItem.reimburseName = selected[0].label;
      // postItem.motID = selected[0].value;
    }
  }, [selected]);

  const handleAutoInputChange = (input) => {
    const filteredOptions = optionsReimburseTypes.filter((option) =>
      option.label.toLowerCase().startsWith(input.toLowerCase())
    );
    return filteredOptions;
  };

  const closeAddModal = () => {
    props.setReimburseID("");
    props.handleClose();
    props.setEditReimburseID("");
    props.setClickType("");
    dispatch(loadSingleBaseFare(null));
    props.fetchReimburseData();
  };

  const addCustomClaimLimit = (e) => {
    e.preventDefault();
    let postCustomClaimData = [];
    if (
      postItem.departmentID &&
      postItem.foodCliaimLimit &&
      postItem.hotelCliaimLimit &&
      postItem.othersCliaimLimit &&
      postItem.osLocalConvinienceCliaimLimit &&
      postItem.osFoodCliaimLimit &&
      postItem.osHotelCliaimLimit &&
      postItem.osOthersCliaimLimit
    ) {
      if (designations.length > 0) {
        for (let desig of designations) {
          postCustomClaimData.push({
            ...postItem,
            designationID: desig.value,
          });
        }
        let requestOption = {
          method: "POST",
          mode: "cors",
          headers: HeaderAuthentication.travelizeAuth,
          body: JSON.stringify(postCustomClaimData),
        };
        fetch(
          `${baseURL}/api/CustomeClaimLimit/BulkManageCustomeClaimLimit`,
          requestOption
        )
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              closeAddModal();
              props.fetchReimburseData();
              swal.showAlert("Success", `${res.message}`, "success");
            } else {
              toast.error(`${res.errorMessage}`);
            }
          })
          .catch((error) => {
            // console.log("ERROR :", error);
            toast.error(`${error}`);
          });
      } else {
        setShowValidAlert(true);
        setShowAlert(true);
        setAlertMessage({
          alertType: "fails",
          message: "Please select designation",
        });
      }
    } else {
      setShowValidAlert(true);
      setShowAlert(true);
      setAlertMessage({
        alertType: "fails",
        message: "Limit per day sholud not be empty or 0",
      });
    }
    console.log("PostCustomClaimData:::", postCustomClaimData);
  };

  return (
    <div>
      <Modal
        show={props.show}
        // onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          <h6 className="add-edit-basefare-head">
            <>
              {editReimburseID
                ? "EDIT REIMBURSEMENT LIMIT"
                : "ADD REIMBURSEMENT LIMIT"}
            </>
          </h6>
          <i className="fa fa-times" onClick={closeAddModal} />
        </div>
        <form>
          <div className="container add-edit-basefare mt-2">
            <div className="product-inputs">
              <div className="mb-3">
                <FloatingLabel
                  label="Department"
                  className={`${
                    motDepartment.value !== ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select add-basefare-input"
                    classNamePrefix="react-select-list"
                    options={departmentDrop}
                    placeholder="Select Department"
                    value={motDepartment.value && motDepartment}
                    onInputChange={departmentDrop.label}
                    onChange={(data) => handleSelectChange(data, "department")}
                    isSearchable={true}
                    isDisabled={editReimburseID ? true : false}
                  />
                </FloatingLabel>
                {showAlert && !postItem.departmentID && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select Department
                  </small>
                )}
              </div>
              <div className="mb-3">
                <FloatingLabel
                  label="Designation"
                  className={`${
                    motDepartment.value !== ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <MultiSelect
                    className="select-add-user-model"
                    options={departDesignation}
                    value={designations}
                    onChange={(selectedList) =>
                      handleSelectChange(selectedList, "designation")
                    }
                    labelledBy={"Select Designation"}
                    disabled={editReimburseID ? true : false}
                  />
                </FloatingLabel>
                {showAlert && designations.length === 0 && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select Designations
                  </small>
                )}
              </div>
              <div className="mb-3">
                <div>
                  <span className="color-green fz13">
                    <b>LOCAL CLAIM LIMIT :</b>
                  </span>
                </div>
                <div className="dflex-j-between mb-3">
                  <div>Food</div>
                  <div>
                    <FloatingLabel
                      label="Limit Per Day"
                      className={`${
                        postItem?.foodCliaimLimit !== "" &&
                        postItem?.foodCliaimLimit !== null
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        className="form-control form add-basefare-input"
                        name="foodCliaimLimit"
                        value={postItem?.foodCliaimLimit}
                        placeholder="Limit Per Day"
                        onChange={handleInputChange}
                        disabled={props.clickType}
                      />
                    </FloatingLabel>
                    {showAlert && !postItem.foodCliaimLimit && (
                      <small id={`Error`} className="form-text text-muted">
                        Please Enter Amount
                      </small>
                    )}
                  </div>
                </div>
                <div className="dflex-j-between mb-3">
                  <div>Hotel / Lodging</div>
                  <div>
                    <FloatingLabel
                      label="Limit Per Day"
                      className={`${
                        postItem?.hotelCliaimLimit !== "" &&
                        postItem?.hotelCliaimLimit !== null
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        className="form-control form add-basefare-input"
                        name="hotelCliaimLimit"
                        value={postItem?.hotelCliaimLimit}
                        placeholder="Limit Per Day"
                        onChange={handleInputChange}
                        disabled={props.clickType}
                      />
                    </FloatingLabel>
                    {showAlert && !postItem.hotelCliaimLimit && (
                      <small id={`Error`} className="form-text text-muted">
                        Please Enter Amount
                      </small>
                    )}
                  </div>
                </div>
                <div className="dflex-j-between mb-3">
                  <div>Others</div>
                  <div>
                    <FloatingLabel
                      label="Limit Per Day"
                      className={`${
                        postItem?.othersCliaimLimit !== "" &&
                        postItem?.othersCliaimLimit !== null
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        className="form-control form add-basefare-input"
                        name="othersCliaimLimit"
                        value={postItem?.othersCliaimLimit}
                        placeholder="Limit Per Day"
                        onChange={handleInputChange}
                        disabled={props.clickType}
                      />
                    </FloatingLabel>
                    {showAlert && !postItem.othersCliaimLimit && (
                      <small id={`Error`} className="form-text text-muted">
                        Please Enter Amount
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div>
                  <span className="color-green fz13">
                    <b>OUTSTATION CLAIM LIMIT :</b>
                  </span>
                </div>
                <div className="dflex-j-between mb-3">
                  <div>Food</div>
                  <div>
                    <FloatingLabel
                      label="Limit Per Day"
                      className={`${
                        postItem?.osFoodCliaimLimit !== "" &&
                        postItem?.osFoodCliaimLimit !== null
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        className="form-control form add-basefare-input"
                        name="osFoodCliaimLimit"
                        value={postItem?.osFoodCliaimLimit}
                        placeholder="Limit Per Day"
                        onChange={handleInputChange}
                        disabled={props.clickType}
                      />
                    </FloatingLabel>
                    {showAlert && !postItem.osFoodCliaimLimit && (
                      <small id={`Error`} className="form-text text-muted">
                        Please Enter Amount
                      </small>
                    )}
                  </div>
                </div>
                <div className="dflex-j-between mb-3">
                  <div>Hotel / Lodging</div>
                  <div>
                    <FloatingLabel
                      label="Limit Per Day"
                      className={`${
                        postItem?.osHotelCliaimLimit !== "" &&
                        postItem?.osHotelCliaimLimit !== null
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        className="form-control form add-basefare-input"
                        name="osHotelCliaimLimit"
                        value={postItem?.osHotelCliaimLimit}
                        placeholder="Limit Per Day"
                        onChange={handleInputChange}
                        disabled={props.clickType}
                      />
                    </FloatingLabel>
                    {showAlert && !postItem.osHotelCliaimLimit && (
                      <small id={`Error`} className="form-text text-muted">
                        Please Enter Amount
                      </small>
                    )}
                  </div>
                </div>
                <div className="dflex-j-between mb-3">
                  <div>Local Conveyance</div>
                  <div>
                    <FloatingLabel
                      label="Limit Per Day"
                      className={`${
                        postItem?.osLocalConvinienceCliaimLimit !== "" &&
                        postItem?.osLocalConvinienceCliaimLimit !== null
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        className="form-control form add-basefare-input"
                        name="osLocalConvinienceCliaimLimit"
                        value={postItem?.osLocalConvinienceCliaimLimit}
                        placeholder="Limit Per Day"
                        onChange={handleInputChange}
                        disabled={props.clickType}
                      />
                    </FloatingLabel>
                    {showAlert && !postItem.osLocalConvinienceCliaimLimit && (
                      <small id={`Error`} className="form-text text-muted">
                        Please Enter Amount
                      </small>
                    )}
                  </div>
                </div>
                <div className="dflex-j-between mb-3">
                  <div>Others</div>
                  <div>
                    <FloatingLabel
                      label="Limit Per Day"
                      className={`${
                        postItem?.osOthersCliaimLimit !== "" &&
                        postItem?.osOthersCliaimLimit !== null
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        className="form-control form add-basefare-input"
                        name="osOthersCliaimLimit"
                        value={postItem?.osOthersCliaimLimit}
                        placeholder="Limit Per Day"
                        onChange={handleInputChange}
                        disabled={props.clickType}
                      />
                    </FloatingLabel>
                    {showAlert && !postItem.osOthersCliaimLimit && (
                      <small id={`Error`} className="form-text text-muted">
                        Please Enter Amount
                      </small>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-2">
                {setReimburseID && (
                  <span className="enable-status d-flex">
                    <h6>Enable</h6>
                    <span className="enable-radio d-flex">
                      <div className="form-check enable-radio-status">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={statusFlag}
                          onChange={() => habdleEnableStatus(1)}
                        />
                        <label className="form-check-label">Yes</label>
                      </div>
                      <div className="form-check enable-radio-status">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={!statusFlag}
                          onChange={() => habdleEnableStatus(0)}
                        />
                        <label className="form-check-label">No</label>
                      </div>
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          {!props.clickType && (
            <SBSaveUpdateBtn
              onClickEffect={addCustomClaimLimit}
              btnName={setReimburseID ? "Update" : "Save"}
            />
          )}
          <SbCancelBtn btnName="Cancel" onClickEffect={closeAddModal} />
        </div>
      </Modal>
    </div>
  );
}

export default ReimbursementLimitsModal;
