import "../../styles/OrderStatus.scss";
import "../../styles/CommonStyle.scss";
import {
  dateFormatIST,
  dateFormatOnlyDate,
  dateFormatOnlyTime,
} from "../FormattedAmount";

const OrderStatus = (props) => {
  //   console.log(props.orderHistory, "props.orderHistory");
  return (
    <div className="order-status-div pt-3">
      <div className="order-status-div1">
        <div className="orderTime">
          <span>
            <b>{dateFormatIST(props.orderDate)}</b>
          </span>
        </div>
        <div className="card1">
          <div className="circle"></div>
          <div className="progressLine">
            <div className="line"></div>
          </div>
        </div>
        <div className="order-status-inner-div">
          <div className="order-confirm-status">
            <span>Order Confirmed</span>
          </div>
          <div className="order-confirm-status">
            <p>{props.itemName}.</p>
          </div>
        </div>
      </div>
      {props.orderHistory?.map((history, i) => (
        <div className="order-status-div1" key={i}>
          <div className="orderTime">
            <div>
              <span className="row-head-Text">
                {dateFormatOnlyDate(history.statusDate)}
              </span>
            </div>
            <div>
              <span className="row-sub-text">
                {dateFormatOnlyTime(history.statusDate)}
              </span>
            </div>
          </div>
          <div className="card1">
            <div className="circle"></div>
            {i != props.orderHistory.length - 1 && (
              <div className="progressLine">
                <div className="line"></div>
              </div>
            )}
          </div>
          <div className="order-status-inner-div">
            <div className="order-confirm-status">
              <span>Dispatched QTY {history.qtY_Sent} </span>
            </div>
            <div className="order-confirm-status">
              <p>{history.statusDescription}.</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderStatus;
