import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import { getRandomColor } from "../FormattedAmount";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { useSelector } from "react-redux";
import companyImage from "../../images/DefaultImages/Default_company.jpg";

function ClientCard(props) {
  const { totalCount } = useSelector((state) => state.data);
  const [colors, setColors] = useState([]);
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    let companies = props.clientData;
    {
      companies?.length > 0 &&
        companies.forEach((ele) => {
          ele.inputState = {
            checkSelect: false,
            clientId: ele.clientID,
          };
        });
      setCompanyData(companies);
    }
  }, [props.clientData]);

  const setReAssignToUser = (state, cId) => {
    if (cId !== 0) {
      let filteredData = companyData.filter((data) => data.clientID == cId);
      filteredData.forEach((item) => {
        item.inputState.checkSelect = state;
      });
    } else {
      companyData.forEach((data) => {
        data.inputState.checkSelect = state;
        data.inputState.clientId = data.clientId;
      });
    }
    setCompanyData([...companyData]);
  };

  useEffect(() => {
    let selectedData = companyData.filter(
      (data) => data.inputState.checkSelect == true
    );

    let checkedAll = companyData.every(
      (data) => data.inputState.checkSelect == true
    );
    setSelectAllCheck(checkedAll);
    props.setReAssignData(selectedData);
  }, [companyData]);

  const selectAllStatus = (event) => {
    if (companyData.map((data) => data?.inputState?.checkSelect == event)) {
      setSelectAllCheck(true);
    } else {
      setSelectAllCheck(false);
    }
  };

  useEffect(() => {
    if (companyData?.length !== 0) {
      const newColors = { ...colors };
      let isUpdated = false;

      companyData.forEach((item) => {
        if (!newColors[item.clientID]) {
          newColors[item.clientID] = getRandomColor();
          isUpdated = true;
        }
      });

      if (isUpdated) {
        setColors(newColors);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  return (
    <div className="sb-table-div sb-table-setion">
      {props.isLoading ? (
        <>
          <div className="card-view-div">
            <div className="card-display-row">
              <OnScrollLoading />
            </div>
          </div>
        </>
      ) : (
        <div
          className="card-view-div"
          onScroll={(e) =>
            handleScroll(
              e,
              companyData,
              totalCount,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <div style={{ margin: "5px auot 0px 10px" }}>
            {props.reAssignEnable && (
              <Form.Check
                inline
                type="checkbox"
                label="Select All"
                value={0}
                className="selectAll-checkbox"
                name="reAssignClients"
                id={"AllSelect"}
                onChange={(e) => {
                  setReAssignToUser(e.target.checked, 0);
                  selectAllStatus(e.target.checked);
                }}
                checked={selectAllCheck}
              />
            )}
          </div>
          <div className="card-display-row">
            {companyData?.length !== 0 &&
              companyData.map((data, i) => (
                <div key={i} className="card-container-body company-card-view">
                  <div className="card-container company-card-container">
                    <div className="main-side">
                      <div className="front side">
                        <div className="content">
                          {props.reAssignEnable && (
                            <div className="card-check-box">
                              <Form.Check
                                inline
                                value={data?.clientID}
                                className="travelBy-radio-btn"
                                name="reAssign-clients"
                                type="checkbox"
                                id={data?.clientID}
                                onChange={(e) =>
                                  setReAssignToUser(
                                    e.target.checked,
                                    data?.clientID
                                  )
                                }
                                checked={data.inputState.checkSelect}
                              />
                            </div>
                          )}
                          <div className="company-data">
                            <div style={{ position: "relative" }}>
                              {data.logo ? (
                                <img
                                  className={`company-logo-img`}
                                  src={`${baseURL}/${data.logo}`}
                                  alt="client-profile"
                                />
                              ) : (
                                <img
                                  className="company-logo-img"
                                  src={companyImage}
                                  alt="client-profile"
                                />
                                // <div
                                //     className={`company-logo-img`}
                                //     style={{ backgroundColor: colors[data.clientID] }}
                                // >
                                //     {data?.clientName.charAt(0).toUpperCase()}
                                // </div>
                              )}
                              <div className={`dot`}>
                                <i
                                  onClick={() =>
                                    props.editClientDetails(data?.clientID)
                                  }
                                  className="fa fa-pencil"
                                ></i>
                              </div>
                            </div>
                            <OverlayTrigger
                              placement={"right"}
                              delay={{ show: 200, hide: 250 }}
                              overlay={
                                <Tooltip id={`tooltip-title`}>Re-Tag</Tooltip>
                              }
                            >
                              <div className="re-tag">
                                <i className="fa fa-thumb-tack" /> -{" "}
                                <span>{data?.retagCount}</span>
                              </div>
                            </OverlayTrigger>
                            <div className="table-row-sub-text ">
                              {data?.enabledStatus === 1 ? (
                                <span className="status-badge complete-status">
                                  Active
                                </span>
                              ) : (
                                <span className="status-badge cancel-status">
                                  InActive
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="contact-person-data">
                            <div className="pb-2">
                              <div className="card-header-text">
                                <strong> {data?.clientName}</strong>
                              </div>
                            </div>
                            <div className="">
                              <i className="fa fa-user"></i> -{" "}
                              {data?.contactPersonName}
                            </div>
                            <div className="">
                              <i className="fa fa-phone"></i> - {data?.mobile}
                            </div>
                            <div className="">
                              {data?.email && (
                                <>
                                  <i className="fa fa-envelope"></i> -{" "}
                                  {data?.email}
                                </>
                              )}
                            </div>
                            <div className="">
                              <i className="fa fa-gears"></i> -{" "}
                              <span>
                                <strong>
                                  {data?.clientCategoryName
                                    ? data?.clientCategoryName
                                    : "-"}{" "}
                                </strong>
                              </span>
                              /
                              {data?.clientStageName
                                ? data?.clientStageName
                                : "-"}
                            </div>
                            <div className="">
                              <i className="fa fa-map-marker"></i> -
                              {data?.clientLoc?.location?.length > 50
                                ? data?.clientLoc?.location.substring(0, 50) +
                                  "..."
                                : data?.clientLoc?.location}
                              <OverlayTrigger
                                placement={"top"}
                                delay={{ show: 200, hide: 250 }}
                                overlay={
                                  <Tooltip id={`tooltip-title`}>
                                    {data?.clientLoc?.location}
                                  </Tooltip>
                                }
                              >
                                <span className="adddress-tooltip-div">
                                  <i className="fa fa-info address-tool"></i>
                                </span>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="back side">
                                            <div className="content">
                                                <div style={{ width: "100%" }}>
                                                    <h5>Location</h5>
                                                    <div className='pb-2'>
                                                        <i className='fa fa-map-marker'></i> {data?.clientLoc?.location}
                                                    </div>
                                                    <div className='icons-section'>
                                                        <div className='icon-div'>
                                                            <Link
                                                                to="#"
                                                                onClick={() => {
                                                                    props.setShowMappedTo(!props.showMappedTo);
                                                                    props.setClientId(data?.clientID);
                                                                }}
                                                                className="view-mapped-user"
                                                            >
                                                                <OverlayTrigger
                                                                    placement={"top"}
                                                                    delay={{ show: 200, hide: 250 }}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-title`}>
                                                                            Mapped To
                                                                        </Tooltip>
                                                                    } >
                                                                    <span>
                                                                        <i className="fa fa-map user-icon" />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </Link>
                                                        </div>

                                                        <div
                                                            onClick={() => {
                                                                props.handleReportData(data?.clientID)
                                                            }}
                                                            className="view-mapped-user icon-div" >
                                                            <OverlayTrigger
                                                                placement={"top"}
                                                                delay={{ show: 200, hide: 250 }}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-title`}>
                                                                        Reporting To
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    <i className="fa fa-users user-icon" />
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>

                                                        <div
                                                            onClick={() => {
                                                                props.handleCustomeFiledsData(data?.clientID)
                                                            }}
                                                            className="view-mapped-user icon-div" >
                                                            <OverlayTrigger
                                                                placement={"top"}
                                                                delay={{ show: 200, hide: 250 }}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-title`}>
                                                                        Custom Fields
                                                                    </Tooltip>
                                                                } >
                                                                <span>
                                                                    <i className="fa fa-snowflake-o user-icon" />
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hover-section">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                            <img src="../../../images/3dots.jpg" alt="" />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => props.editClientDetails(data?.clientID)}>Edit</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                props.setHistoryclientmodel(
                                                                    !props.historyclientmodel
                                                                );
                                                                props.setClientId(data?.clientID);
                                                            }}>History</Dropdown.Item>
                                                            <Dropdown.Item onClick={(e) =>
                                                                props.handleView(data?.clientID)
                                                            }>View</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div> */}
                    </div>
                    <div className="footer-side">
                      <div className="icon-div">
                        <div
                          onClick={() => {
                            props.setShowMappedTo(!props.showMappedTo);
                            props.setClientId(data?.clientID);
                          }}
                          className="view-mapped-user"
                        >
                          <OverlayTrigger
                            placement={"top"}
                            delay={{ show: 200, hide: 250 }}
                            overlay={
                              <Tooltip id={`tooltip-title`}>Mapped To</Tooltip>
                            }
                          >
                            <span>
                              <i className="fa fa-street-view user-icon" />
                            </span>
                          </OverlayTrigger>
                        </div>
                        <div
                          onClick={() => {
                            props.handleReportData(data?.clientID);
                          }}
                          className="view-mapped-user"
                        >
                          <OverlayTrigger
                            placement={"top"}
                            delay={{ show: 200, hide: 250 }}
                            overlay={
                              <Tooltip id={`tooltip-title`}>
                                Reporting To
                              </Tooltip>
                            }
                          >
                            <span>
                              <i className="fa fa-users user-icon" />
                            </span>
                          </OverlayTrigger>
                        </div>
                        <div
                          onClick={() => {
                            props.handleCustomeFiledsData(data?.clientID);
                          }}
                          className="view-mapped-user"
                        >
                          <OverlayTrigger
                            placement={"top"}
                            delay={{ show: 200, hide: 250 }}
                            overlay={
                              <Tooltip id={`tooltip-title`}>
                                Custom Fields
                              </Tooltip>
                            }
                          >
                            <span>
                              <i className="fa fa-snowflake-o user-icon" />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div className="view-profile">
                        <div
                          className="text-bnt"
                          onClick={() => {
                            props.setHistoryclientmodel(
                              !props.historyclientmodel
                            );
                            props.setClientId(data?.clientID);
                          }}
                        >
                          History
                        </div>
                        <div
                          className="text-bnt"
                          onClick={(e) => props.handleView(data?.clientID)}
                        >
                          View
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {props.loading && <OnScrollLoading />}
          </div>
        </div>
      )}
    </div>
  );
}

export default ClientCard;
