import React from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/SuperAdminPage.scss";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

export default function SubScriptionDataCard(props) {
  return (
    <div className="subscription-data-card" onClick={props.onClickCard}>
      <div className="subscription-card-body">
        <div className="subscription-card-row">
          <div className="card-data justify-content-between">
            <div className="leads-card-price">
              <p>{props.cardHeading}</p>
              <span className="leads-card">
                <b>{props.totalData}</b>
                {props.thisWeekData !== null && (
                  <>
                    <img src="../../images/leadsincrease.png" alt="growup" />
                    <p>{props.thisWeekData}</p>
                  </>
                )}
              </span>
            </div>
            <span className="material-symbols-outlined">wallet</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AccountsCard(props) {
  return (
    <Link className="card single-card" to={props.linkUrl}>
      <div className="card-number">{props.icon}</div>
      <div className="card-heading">
        <p>{props.cardHeading}</p>
      </div>
      <div className="card-arrow">
        <AiOutlineArrowRight />
      </div>
    </Link>
  );
}
