import React, { useState, useEffect, useMemo, useRef } from "react";
import AddEditFormComponent from "../../ReUseComponents/AddEditFormComponent";
import { FloatingLabel, Form } from "react-bootstrap";
import Select from "react-select";
import * as swal from "../../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/VhdPurchaseOrder.scss";
import { MiCustomBtn, SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { enableYesNoOptions } from "../../DropdownsData";
import DatePicker from "react-datepicker";
import { baseURL } from "../../BaseUrl";
import { loginSubscriptionID } from "../../LoginRole";
import { travelizeAuth } from "../../HeaderAuthentication";
import { loadInvoiceNumbersDropdown } from "../../../redux/action";

export default function AddEditPayments(props) {
  let dispatch = useDispatch();

  const { allInvoiceNumbersDropdown } = useSelector((state) => state.data);

  const [invoiceNumber, setInvoiceNumber] = useState({
    value: null,
    label: "",
  });
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [lineItemsModule, setLineItemsModule] = useState(false);
  const [file, setFile] = useState("");

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  useEffect(() => {
    dispatch(loadInvoiceNumbersDropdown());
  }, []);

  const [paymentDetailsData, setPaymentDetailsData] = useState({
    payment_no: 0,
    invoice_id: null,
    invoice_number: "",
    vendor_id: "",
    po_id: null,
    po_Number: "",
    payment_amount: "",
    payment_date: moment(new Date()).format("DD/MM/YYYY"),
    utR_number: "",
    payment_status: "Paid",
    paid_Amount: "",
    pending_Amount: "",
    narration: "",
    fileContent: "",
    subscriptionId: loginSubscriptionID,
  });

  const {
    invoice_id,
    invoice_number,
    vendor_id,
    po_id,
    po_Number,
    payment_amount,
    payment_date,
    utR_number,
    payment_status,
    paid_Amount,
    pending_Amount,
    narration,
  } = paymentDetailsData;

  // FILE TTACHMENT
  const hiddenFileInput = useRef(null);

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setFile(result.toString().replace(/^data:(.*,)?/, ""));
        setPaymentDetailsData({
          ...paymentDetailsData,
          ["fileContent"]: result.toString().replace(/^data:(.*,)?/, ""),
        });
      }
    });
  };

  const fileToBase64 = (upldfile, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(upldfile);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };
  // FILE TTACHMENT

  const handleInputData = (e) => {
    const { name, value } = e.target;
    if (name === "po_id" || name === "invoice_id") {
      setPaymentDetailsData({ ...paymentDetailsData, [name]: +value });
    } else {
      setPaymentDetailsData({ ...paymentDetailsData, [name]: value });
    }
  };

  console.log("ADD-PAYMENT-DATA:", paymentDetailsData);

  const handleSelectChange = (data, type) => {
    setPaymentDetailsData({ ...paymentDetailsData, [type]: data });
    switch (type) {
      case "payment_date":
        setPaymentDate(data);
        setPaymentDetailsData({
          ...paymentDetailsData,
          [type]: moment(data).format("DD/MM/YYYY"),
        });

        break;
      case "invoice_number":
        setInvoiceNumber(data);
        setPaymentDetailsData({
          ...paymentDetailsData,
          [type]: data.value,
        });
        break;
      default:
    }
  };

  useEffect(() => {
    {
      invoiceNumber.value && getInvoiceDetailsByNumber();
    }
  }, [invoiceNumber]);

  const getInvoiceDetailsByNumber = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/VHD/GetALLInvoices?Invoice_number=${invoiceNumber.value}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setPaymentDetailsData({
            ...paymentDetailsData,
            ["invoice_id"]: res.result[0].invoice_id,
            ["vendor_id"]: res.result[0].vendor_id,
            ["po_id"]: res.result[0].po_id,
            ["po_Number"]: res.result[0].po_Number,
            ["payment_amount"]: res.result[0].grand_Total,
          });
        } else {
          toast.error(res.errorMessage, { autoClose: 5000 });
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };

  const addPaymentDetails = (e) => {
    e.preventDefault();
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(paymentDetailsData),
    };

    fetch(`${baseURL}/api/VHD/AddPaymentDetails`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          swal.showAlert("Success", `${res.message}`, "success");
          props.fetchPaymentsData();
          closeModel();
        } else {
          toast.error(res.errorMessage, { autoClose: 5000 });
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };

  const closeModel = () => {
    props.handleClose();
    props.setPaymentID(null);
  };

  return (
    <div>
      <AddEditFormComponent
        handleClose={closeModel}
        formName={"Payments"}
        formSubTitle={
          props.paymentID
            ? "Edit and update the payment details."
            : "Please enter the payment details."
        }
        sidebarItems={["Payments"]}
        childrens={
          <>
            {!lineItemsModule ? (
              <form onSubmit={addPaymentDetails}>
                <div className="add-po_headers-to-vhd">
                  <div className="add-po_headers-details">
                    <div className="row">
                      <div className="col my-2">
                        <FloatingLabel
                          label="Invoice Number"
                          className={`${
                            invoice_number
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Select
                            className="react-select-container-list model-select"
                            classNamePrefix="react-select-list"
                            name="invoice_number"
                            options={allInvoiceNumbersDropdown}
                            placeholder="Invoice Number"
                            value={invoiceNumber.value ? invoiceNumber : ""}
                            onInputChange={allInvoiceNumbersDropdown.label}
                            onChange={(data) =>
                              handleSelectChange(data, "invoice_number")
                            }
                            isSearchable={true}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="PO Number"
                          className={`${
                            po_Number
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            name="po_Number"
                            className="form-control form add-input"
                            placeholder="PO Number"
                            value={po_Number}
                            // onChange={(e) => handleInputData(e)}
                            required
                            readOnly
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Vendor ID"
                          className={`${
                            vendor_id
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            name="vendor_id"
                            className="form-control form add-input"
                            placeholder="Vendor ID"
                            value={vendor_id}
                            // onChange={(e) => handleInputData(e)}
                            required
                            readOnly
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className="col my-2">
                        <FloatingLabel
                          label="Vendor Ref.No"
                          className={`${
                            vendorRef_No
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            name="vendorRef_No"
                            className="form-control form add-input"
                            placeholder="Vendor Ref.No"
                            maxLength={10}
                            value={vendorRef_No}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div> */}
                      <div className="col my-2">
                        <FloatingLabel
                          label="Payment Date"
                          className={`${
                            paymentDate
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <DatePicker
                            selected={paymentDate}
                            className="form-control form add-input"
                            dateFormat="dd/MM/yyyy"
                            placeholder="Payment Date"
                            onChange={(date) =>
                              handleSelectChange(date, "payment_date")
                            }
                            minDate={new Date()}
                            required
                            // maxDate={new Date()}
                          />
                        </FloatingLabel>
                      </div>

                      <div className="col my-2">
                        <FloatingLabel
                          label="Invoice Value"
                          className={`${
                            payment_amount
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            name="payment_amount"
                            className="form-control form add-input"
                            placeholder="Invoice Value"
                            value={payment_amount}
                            // onChange={(e) => handleInputData(e)}
                            required
                            readOnly
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Paid Amount"
                          className={`${
                            paid_Amount
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            name="paid_Amount"
                            className="form-control form add-input"
                            placeholder="Paid Amount"
                            value={paid_Amount}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Pending Amount"
                          className={`${
                            pending_Amount
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            name="pending_Amount"
                            className="form-control form add-input"
                            placeholder="Pending Amount"
                            value={pending_Amount}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="UTR Number"
                          className={`${
                            utR_number
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            name="utR_number"
                            className="form-control form add-input"
                            placeholder="UTR Number"
                            value={utR_number}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col my-2">
                        <FloatingLabel
                          label="Narration /Inv/Po/Payment"
                          className={`${
                            narration
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <textarea
                            rows="3"
                            cols="20"
                            wrap="hard"
                            autoComplete="off"
                            name="narration"
                            className="form-control form-textarea add-user-input"
                            placeholder="Narration / Inv / Po / Payment"
                            value={narration}
                            onChange={(e) => handleInputData(e)}
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <div className="d-flex up-btn">
                          {/* <MiCustomBtn
                            btnName={"Upload File"}
                            btnColor={"green"}
                            iconName={"fa fa-upload"}
                            onClickEffect={uploadClick}
                          /> */}

                          <input
                            id="getFile"
                            type="file"
                            name="filetobase64"
                            accept="image/pdf"
                            className="btn-upload"
                            // ref={hiddenFileInput}
                            onChange={handleChange}
                            // onClickEffect={uploadClick}
                            // style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row dflex-j-between">
                    <div className="add-page-save-cancel">
                      <SBSaveUpdateBtn btnName="Save" />
                      <SbCancelBtn
                        btnName="Cancel"
                        onClickEffect={closeModel}
                      />
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              ""
            )}
          </>
        }
      />
    </div>
  );
}
