import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Image, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import '../../styles/ProductsViewPage.scss';
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

export default function AnnualContractAttachments() {

  let navigate = useNavigate();

  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  const images = [
    "https://tse3.mm.bing.net/th?id=OIP.ydV4POSX8yGEu-OXP7_yygHaFk&pid=Api&P=0&h=180",
    "https://tse4.mm.bing.net/th?id=OIP.rwWm3tTFskrMMiW56HJa1AHaGE&pid=Api&P=0&h=180",
    "https://tse1.mm.bing.net/th?id=OIP.LTMa4RpiGjS-VXSTINLSiQHaE7&pid=Api&P=0&h=180",
    "https://tse4.mm.bing.net/th?id=OIP.z7LD3zzUoJN_D-ZSSjXdEQHaEo&pid=Api&P=0&h=180",
    "https://tse4.mm.bing.net/th?id=OIP.fA6BMgRWmXiqhEdmKUnqygHaFf&pid=Api&P=0&h=180",
  ];

  return (

    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>

          <div className="page-content">

            <div className="clients-table">
              <Row className='search-row'>
                <Col md={3} className='cat-col'>
                  <div className='page-header-text-div'>
                    <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                    <h6 className='page-header-text' >MACHINE ATTACHMENTS</h6>
                  </div>
                </Col>
                <Col md={5} className='cat-col'>
                </Col>
              </Row>
            </div>

            <div className="product-details-model">
              <div className="row">
                <div className="col-md-5 col-sm-12">
                  <div className="product-detail-left-part">
                    <div className="product-big-img">
                      {isLoading ?
                        <div style={{ padding: "20px 0px" }}>
                          <Skeleton />
                        </div>
                        :
                        <Swiper
                          spaceBetween={10}
                          navigation={false}
                          thumbs={{ swiper: thumbsSwiper }}
                          modules={[FreeMode, Navigation, Thumbs]}
                          className="mySwiper2"
                        >
                          {images.length != 0 && images.map((data, i) => (
                            <SwiperSlide>
                              <Image
                                src={data}
                                alt="attachment-photo"
                                className="product-img"
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      }

                    </div>
                    {isLoading ?
                      <Skeleton />
                      :
                      <div className="carousel-sliding-part">
                        <Swiper
                          style={{
                            "--swiper-navigation-color": "#000000",
                            "--swiper-pagination-color": "#000000",
                            "--swiper-navigation-size": "13px",
                            "--swiper-navigation-sides-offset": "0px",
                          }}
                          onSwiper={setThumbsSwiper}
                          spaceBetween={5}
                          slidesPerView={4}
                          // slidesPerGroup={1}
                          loadSingleProduct
                          navigation={true}
                          freeMode={true}
                          watchSlidesProgress={true}
                          modules={[FreeMode, Navigation, Thumbs]}
                          className="mySwiper"
                        >
                          {images.length != 0 && images.map((data, i) => (
                            <SwiperSlide key={i} >
                              <div className="product-small-images-row">
                                <div className="product-small-image">
                                  <Image
                                    src={data}
                                    alt="attachment-photo"
                                    className="product-img"
                                  />
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>

                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


